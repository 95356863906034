<template>
  <div class="field">
    <div class="field-preview"><div :id="propField.id"></div></div>
    <div class="field-info">
      <div
        class="field-title d-flex justify-content-between align-items-center"
      >
        <span class="field-name">{{
          propField && propField.name ? propField.name : null
        }}</span>

        <div class="field-buttons d-flex">
          <button
            title="Edit"
            class="btn button-edit"
            v-if="propField && propField.coordinate"
            @click="editField"
          >
            <span class="button-icon">
              <span
                ><svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M2.5 14.3751V17.5001H5.625L14.8417 8.28339L11.7167 5.15839L2.5 14.3751ZM17.2583 5.86672C17.5833 5.54172 17.5833 5.01672 17.2583 4.69172L15.3083 2.74172C14.9833 2.41672 14.4583 2.41672 14.1333 2.74172L12.6083 4.26672L15.7333 7.39172L17.2583 5.86672Z"
                    fill=""
                  ></path></svg
              ></span>
            </span>
          </button>
          <button
            title="Remove"
            class="btn"
            v-if="propField && propField.coordinate"
            @click="removeField"
          >
            <span class="button-icon">
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    fill=""
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5z"
                  ></path></svg
              ></span>
            </span>
          </button>
        </div>
      </div>
      <div class="field-metadata d-flex flex-column">
        <span class="field-area" title="Area">{{ areaFormat }}</span>
        <span
          class="
            field-location
            d-flex
            justify-content-between
            align-items-center
          "
          title="Centre point"
        >
          <span>
            {{
              propField && propField.coordinate
                ? toStringHDMS(propField.coordinate)
                : null
            }}
          </span>
          <button
            class="btn"
            v-if="propField && propField.coordinate"
            @click="pan2Location(propField.coordinate)"
          >
            <span class="button-icon">
              <span
                ><svg
                  data-v-4c4c545a=""
                  width="100%"
                  height="100%"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    data-v-4c4c545a=""
                    d="M6 3.81817C4.79454 3.81817 3.81819 4.79454 3.81819 5.99997C3.81819 7.20541 4.79454 8.18181 6 8.18181C7.20546 8.18181 8.18181 7.20544 8.18181 6C8.18181 4.79456 7.20544 3.81817 6 3.81817ZM10.8764 5.45453C10.6254 3.17999 8.81999 1.37454 6.54544 1.12364V0H5.45453V1.12364C3.17999 1.37454 1.37454 3.17999 1.12364 5.45453H0V6.54544H1.12364C1.37454 8.81999 3.18001 10.6254 5.45455 10.8764V12H6.54544V10.8764C8.81999 10.6254 10.6254 8.81999 10.8764 6.54544H12V5.45453H10.8764ZM6 9.81817C3.89183 9.81817 2.18183 8.10817 2.18183 6C2.18183 3.89183 3.89183 2.18183 6 2.18183C8.10817 2.18183 9.81817 3.8918 9.81817 6C9.81817 8.1082 8.10817 9.81817 6 9.81817Z"
                    fill="currentColor"
                  ></path></svg
              ></span>
            </span>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { toStringHDMS } from "ol/coordinate";
import { fromLonLat, transform } from "ol/proj";
export default {
  props: {
    propMap: {
      type: Object,
    },
    propField: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {
    propMap(val) {},
  },
  async mounted() {
    if (this.propField && Object.keys(this.propField).length > 0) {
      this.initImagePreview(
        this.propField.coordinates ? this.propField.coordinates : []
      );
    }
  },
  methods: {
    editField() {
      this.$emit("editField", this.propField.id);
    },
    removeField() {
      this.$emit("removeField", this.propField.id);
    },
    pan2Location(coordinate) {
      let zoomLevel = this.propMap.getView().getZoom();
      this.propMap.getView().animate({
        center: coordinate,
        duration: 2000,
        zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
      });
    },
    toStringHDMS(coordinate) {
      return toStringHDMS(transform(coordinate, "EPSG:3857", "EPSG:4326"));
    },
    changPreview(coordinates) {
      var pointsElementSvg = document.getElementById(
        "polygon" + this.propField.id
      );
      var lng = coordinates.map(function (p) {
        return p[0];
      });
      var lat = coordinates.map(function (p) {
        return p[1];
      });
      var min_coords = {
        lat: Math.min.apply(null, lat),
        lng: Math.min.apply(null, lng),
      };
      var max_coords = {
        lat: Math.max.apply(null, lat),
        lng: Math.max.apply(null, lng),
      };

      let distanceXMinMax = max_coords.lng - min_coords.lng; //  Math.abs(max_coords.lng) - Math.abs(min_coords.lng);
      let distanceYMinMax = max_coords.lat - min_coords.lat; // Math.abs(max_coords.lat) - Math.abs(min_coords.lat);
      distanceXMinMax =
        distanceXMinMax < distanceXMinMax
          ? Math.abs(distanceXMinMax)
          : distanceXMinMax;
      distanceYMinMax =
        distanceYMinMax < 0 ? Math.abs(distanceYMinMax) : distanceYMinMax;
      let scaleYX = distanceYMinMax / distanceXMinMax; // ty le do dai map y/ map x
      if (scaleYX > 1) scaleYX = 1; // neu > 1 huy co da~n theo ty le
      let newCoors = [],
        locatations = [],
        ymax = 0;

      for (let i = 0; i < coordinates.length; i++) {
        let x = null,
          y = null;
        let dx = coordinates[i][0] - min_coords.lng; // Math.abs(coordinates[i][0]) - Math.abs(min_coords.lng);
        dx = dx < 0 ? Math.abs(dx) : dx;
        x = (dx / distanceXMinMax) * 64;
        let dy = coordinates[i][1] - min_coords.lat; // Math.abs(coordinates[i][1]) - Math.abs(min_coords.lat);
        dy = dy < 0 ? Math.abs(dy) : dy;
        if ((dy / distanceYMinMax) * 64 * scaleYX > ymax)
          ymax = (dy / distanceYMinMax) * 64 * scaleYX;
        y = 64 - (dy / distanceYMinMax) * 64 * scaleYX; // truc y do thi nguoc voi truc y man hinh

        newCoors.push([x, y]);
      }
      for (let i = 0; i < newCoors.length; i++) {
        newCoors[i][1] = newCoors[i][1] - Math.abs((64 - ymax) / 2);
        locatations.push(newCoors[i].join(","));
      }
      pointsElementSvg.setAttribute("points", locatations.join(" "));
    },
    async initImagePreview(coordinates) {
      // Tinh diem toa do man hinh
      //let points = [];
      // for (let i = 0; i < coordinates.length; i++) {
      //   var pixel = this.propMap.getPixelFromCoordinate(
      //     transform(coordinates[i], "EPSG:4326", "EPSG:3857")
      //   );
      //   points.push(pixel);
      // }
      var lng = coordinates.map(function (p) {
        return p[0];
      });
      var lat = coordinates.map(function (p) {
        return p[1];
      });
      var min_coords = {
        lat: Math.min.apply(null, lat),
        lng: Math.min.apply(null, lng),
      };
      var max_coords = {
        lat: Math.max.apply(null, lat),
        lng: Math.max.apply(null, lng),
      };

      let distanceXMinMax = max_coords.lng - min_coords.lng; //  Math.abs(max_coords.lng) - Math.abs(min_coords.lng);
      let distanceYMinMax = max_coords.lat - min_coords.lat; // Math.abs(max_coords.lat) - Math.abs(min_coords.lat);
      distanceXMinMax =
        distanceXMinMax < distanceXMinMax
          ? Math.abs(distanceXMinMax)
          : distanceXMinMax;
      distanceYMinMax =
        distanceYMinMax < 0 ? Math.abs(distanceYMinMax) : distanceYMinMax;
      let scaleYX = distanceYMinMax / distanceXMinMax; // ty le do dai map y/ map x
      if (scaleYX > 1) scaleYX = 1; // neu > 1 huy co da~n theo ty le
      let newCoors = [],
        locatations = [],
        ymax = 0;

      for (let i = 0; i < coordinates.length; i++) {
        let x = null,
          y = null;
        let dx = coordinates[i][0] - min_coords.lng; // Math.abs(coordinates[i][0]) - Math.abs(min_coords.lng);
        dx = dx < 0 ? Math.abs(dx) : dx;
        x = (dx / distanceXMinMax) * 64;
        let dy = coordinates[i][1] - min_coords.lat; // Math.abs(coordinates[i][1]) - Math.abs(min_coords.lat);
        dy = dy < 0 ? Math.abs(dy) : dy;
        if ((dy / distanceYMinMax) * 64 * scaleYX > ymax)
          ymax = (dy / distanceYMinMax) * 64 * scaleYX;
        y = 64 - (dy / distanceYMinMax) * 64 * scaleYX; // truc y do thi nguoc voi truc y man hinh

        newCoors.push([x, y]);
      }
      for (let i = 0; i < newCoors.length; i++) {
        newCoors[i][1] = newCoors[i][1] - Math.abs((64 - ymax) / 2);
        locatations.push(newCoors[i].join(","));
      }
      var svg = await this.createSVG(this.propField.id);
      var poly = this.drawPoly(locatations.join(" "), "", "#fff");
      svg.appendChild(poly);
    },
    createSVG(id) {
      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
      //svg.setAttribute("version", "1.1");
      svg.setAttribute("id", "svg" + id); //id for reffering your svg
      var canvas = document.getElementById(id); //id of your container element
      let width = canvas.getBoundingClientRect().width;
      let height = canvas.getBoundingClientRect().height;
      width = width ? width : "64";
      height = height ? height : "64";
      svg.setAttribute("height", height);
      svg.setAttribute("width", width);
      canvas.appendChild(svg);
      return svg;
    },
    drawPoly(points, fill, stroke) {
      var poly = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "polygon"
      );
      poly.setAttribute("id", "polygon" + this.propField.id);
      poly.setAttribute("points", points);
      poly.setAttribute("stroke", stroke);
      poly.setAttribute("fill", fill ? fill : "transparent");
      //svg.appendChild(poly); //where svg is referring to your svg element
      return poly;
    },
  },
  computed: {
    areaFormat() {
      let result = "";
      if (this.propField && this.propField.area) {
        if (parseFloat(this.propField.area) / 10000 < 0.5)
          result = this.propField.area + " m²";
        else
          result = (parseFloat(this.propField.area) / 10000).toFixed(2) + " ha";
      }
      return result ? result : this.propField.area + " m²";
    },
  },
};
</script>
<style lang="scss" scoped>
.field {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: white;
  letter-spacing: 0.4px;
  width: 100%;
  .field-preview {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    flex-shrink: 0;
    svg {
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
      width: 64px;
      height: 64px;
    }
  }
  .field-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    .field-title {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: white;
      letter-spacing: 0.25px;

      .field-buttons {
        button {
          color: #8296aa;
          border: 0;
          padding: 0;
          margin-left: 0.5rem;
          &:first-child {
            margin: 0;
          }
          span.button-icon {
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .field-metadata {
      margin-top: 0.125rem;
      width: 100%;
      .field-area {
        color: #8799ab;
      }
      .field-location {
        color: #8799ab;
        button {
          color: #8296aa;
          border: 0;
          padding: 0;
          span.button-icon {
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>