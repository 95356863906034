<template>
  <div>
    <div class="v-application--is-ltr row no-gutters">
      <div class="col-12 col-md-6">
        <div class="col-field input-field-background border-radius-8">
          <v-text-field
            label="Field name"
            hide-details="auto"
            v-model="name"
            maxlength="50"
          ></v-text-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="col-group input-field-background border-radius-8">
          <v-text-field
            label="Group name"
            hide-details="auto"
            v-model="group_name"
            maxlength="50"
          ></v-text-field>
        </div>
      </div>
    </div>
    <div class="row no-gutters add-season text-center">
      <button class="btn" @click="addNewRowSeason">
        <span class="button-icon">
          <span
            ><svg
              width="100%"
              height="100%"
              viewBox="0 0 14 14"
              fill=""
              xmlns="http://www.w3.org/2000/svg"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill=""></path></svg
          ></span>
        </span>
        <span class="button-text">&nbsp;Add Season</span>
      </button>
    </div>
    <RowSeason
      :propYears="propYears"
      :propSeason="s"
      v-for="s in rowsSeason"
      :key="s.id"
      @removeRowSeason="removeRowSeason"
      @dataChange="onDataChange"
      ref="season4Save"
    />
  </div>
</template>
<script>
import RowSeason from "./RowSeason";
export default {
  components: {
    RowSeason,
  },
  props: {
    propYears: {
      type: Array,
      default: () => [],
    },
    propField: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rowsSeason: [],
      name: null,
      group_name: null,
    };
  },

  created() {
    this.name = this.propField.name ? this.propField.name : null;
    this.group_name = this.propField.group_name
      ? this.propField.group_name
      : null;
    if (this.propField.rowsSeason && this.propField.rowsSeason.length > 0) {
      this.rowsSeason = Object.assign([], this.propField.rowsSeason);
    } else {
      let nowYear = new Date().getFullYear();
      for (let i = nowYear; i > nowYear - 5; --i) {
        this.rowsSeason.push({
          id:
            new Date().getTime() -
            Math.floor(Math.random() * 999999999) +
            Math.floor(Math.random() * 888888888),
          crop_name: null,
          maturity: null,
          sowing_date: null,
          harvesting_date: null,
          season: i,
        });
      }
    }
  },
  watch: {
    name: {
      handler(val, oldVal) {
        if (!this.dataChanged && val && oldVal === this.propField.name) {
          this.$emit("dataChange");
        }
      },
      immediate: true,
    },
    group_name: {
      handler(val, oldVal) {
        if (!this.dataChanged && val && oldVal === this.propField.group_name) {
          this.$emit("dataChange");
        }
      },
      immediate: true,
    },
  },
  methods: {
    addNewRowSeason() {
      this.rowsSeason.unshift({
        id:
          new Date().getTime() -
          Math.floor(Math.random() * 999999999) +
          Math.floor(Math.random() * 888888888),
        crop_name: null,
        maturity: null,
        sowing_date: null,
        harvesting_date: null,
        season: new Date().getFullYear(),
      });
    },
    removeRowSeason(item) {
      let index = this.rowsSeason.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.rowsSeason.splice(index, 1);
        this.$emit("dataChange");
        this.$emit("removeRowSeason", {
          id: this.propField.id,
          item: item,
        });
      }
    },
    onDataChange() {
      this.$emit("dataChange");
    },
  },
};
</script>
<style lang="scss" >
@import "@/assets/scss/_variables.scss";
.save-fields-dialog {
  .row {
    &.no-gutters {
      padding: 0;
      margin: 0;
      .col-md-6 {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        &:first-child {
          padding-right: 0.25rem;
        }
        &:last-child {
          padding-left: 0.25rem;
        }
        .input-field-background {
          background: $input-field-background;
        }
        .col-field {
          padding: 0.125rem;
          padding-left: 0.5rem;
        }
        .col-group {
          padding: 0.125rem;
          padding-left: 0.5rem;
        }
      }
      .v-input__control {
        label {
          color: #c1cfdb !important;
          font-size: 14px;
        }
        .v-text-field__slot {
          input {
            color: white !important;
            font-size: 14px;
          }
        }
        .v-select__slot {
          .v-select__selections {
            .v-select__selection {
              color: white;
              font-size: 14px;
            }
          }
        }
      }
      .v-icon {
        color: #c1cfdb !important;
        font-size: 18px;
      }
    }
    &.add-season {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      button {
        margin: 0 auto;
        font-size: 14px;
        width: 8.5rem;
        color: $accent-color;
        border: none;
        &:hover {
          color: $accent-color-hover;
        }
        span.button-icon {
          width: 14px;
          height: 14px;
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
        }
      }
    }
  }
}
</style>