var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fields"},[_c('div',{staticClass:"field-list",class:_vm.isSmallDevice ? 'small-device' : '',style:([
      !_vm.isSmallDevice
        ? { height: _vm.documentHeight + 'px', width: 304 + 'px' }
        : {
            height: _vm.documentHeight - 50 - 16 + 'px',
            width: 272 + 'px',
            right: 8 + 'px',
          } ])},[_c('FieldList',{ref:"fieldList",attrs:{"prop-map":_vm.propMap,"propField":_vm.propField,"prop-height":!_vm.isSmallDevice ? _vm.documentHeight : _vm.documentHeight - 50 - 16},on:{"editField":_vm.editField,"removeField":_vm.removeField}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }