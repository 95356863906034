<template>
  <DefaultLayout
    ><slot name="slot-content"></slot> <router-view></router-view
  ></DefaultLayout>
</template>
<script>
import DefaultLayout from "@/components/layouts/Default";
export default {
  components: {
    DefaultLayout,
  },
};
</script>
<style lang="scss" scoped>
</style>