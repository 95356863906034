<template>
  <div class="map-view">
    <div
      class="monitoring-map"
      id="openlayerMap"
      :style="[
        documentWidth >= 576
          ? { width: 'calc(100% - 304px)' }
          : { width: 'calc(100% - 56px)' },
      ]"
    ></div>
    <WidgetLayers
      v-show="propButtonsTool.layers.show"
      :propShow="propButtonsTool.layers.show2"
      :map="map"
      @close="closeWidgetLayers"
      ref="widgetLayers"
    />
  </div>
</template>
<script>
var sketch;
import WidgetLayers from "./openlayermap/WidgetLayers";
import Map from "ol/Map";
import View from "ol/View";
import Draw from "ol/interaction/Draw";
import { Vector as VectorSource, TileImage } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";

import { unByKey } from "ol/Observable.js";
import Overlay from "ol/Overlay.js";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon, MultiPoint } from "ol/geom.js";

import Feature from "ol/Feature";
import { Modify, Select } from "ol/interaction.js";
import { GeoJSON } from "ol/format.js";
import Collection from "ol/Collection";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
export default {
  components: {
    WidgetLayers,
  },
  props: {
    propMap: {
      type: Object,
    },
    propView: {
      type: Object,
    },
    propFields: {
      type: Array,
      default: () => [],
    },
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      view: Object,
      draw: Object,
      modify: Object,
      select: Object,
      id: null,
      fillColor: "#fab710",
      fill: new Fill({
        color: "rgba(255,255,255,0.4)",
      }),
      strokeColor: "#3399CC",
      stroke: new Stroke({
        color: "#3399CC",
        width: 1.25,
      }),
    };
  },
  mounted() {
    if (!this.propMap || Object.keys(this.propMap).length === 0) {
      const source = new VectorSource({
        wrapX: false,
      });

      this.layer = new VectorLayer({
        source: source,
        id: "drawLayer",
        style: new Style({
          fill: new Fill({
            color: this.hexToRgbA("#fab710", 0.5),
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 2,
          }),
        }),
      });
      this.layer.setZIndex(100);
      this.view = new View({
        center: fromLonLat([108.22779930115105, 16.062106484745996]),
        zoom: 10,
        minZoom: 1,
        maxZoom: 20,
      });
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({ doubleClickZoom: false }),
        layers: [
          // new TileLayer({
          //   title: "Satellite",
          //   id: "satellitemap",
          //   source: new TileImage({
          //     url: process.env.VUE_APP_BASEMAP_URL,
          //   }),
          //   show: true,
          // }),
          this.layer,
        ],
        target: "openlayerMap",
        view: this.view,
      });
      this.$emit("initMapSuccess", this.map);
    }
  },

  methods: {
    addLayer2Widget(layer) {
      if (this.$refs.widgetLayers)
        this.$refs.widgetLayers.addLayer2Widget(layer);
    },
    closeWidgetLayers() {
      this.propButtonsTool.layers.show2 = false;
      setTimeout(() => {
        this.propButtonsTool.layers.show = false;
      }, 1000);
    },
    setGeometry(data) {
      let feature = this.layer.getSource().getFeatureById(data.id);
      if (feature && Object.keys(feature).length > 0)
        feature.setGeometry(data.geometry);
    },
    drawFields(fields) {
      if (fields && fields.length > 0) {
        for (let i = 0; i < fields.length; i++) {
          if (fields[i].coordinates && fields[i].coordinates.length > 0) {
            let coors = [];
            for (let j = 0; j < fields[i].coordinates.length; j++) {
              coors.push(
                transform(fields[i].coordinates[j], "EPSG:4326", "EPSG:3857")
              );
            }
            let id =
              new Date().getTime() -
              Math.floor(Math.random() * 999999999) +
              Math.floor(Math.random() * 888888888);
            let feature = new Feature({
              geometry: new Polygon([coors]),
              id: id,
            });
            feature.id_ = id;
            let extent = feature.getGeometry().getExtent();

            this.view.fit(extent);
            this.layer.getSource().addFeature(feature);
          }
        }
      }
    },

    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
};
</script>
<style lang="scss">
.map-view {
  position: relative;
  height: 100vh;
}
.monitoring-map {
  position: absolute;
  height: 100%;
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: #ffcc33;
  }
}
</style>
