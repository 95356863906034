<template>
  <div
    id="popup-content"
    class="popup-content"
    v-if="propFeature && Object.keys(propFeature).length > 0"
  >
    <h5 class="popup-content__title">Thông tin điểm cháy</h5>
    <div class="popup-content__body">
      <table class="table">
        <tbody>
          <tr>
            <td>Vị điểm cháy:</td>
            <td>{{ propFeature.values_.description }}</td>
          </tr>
          <tr>
            <td>Toạ độ WGS84:</td>
            <td>
              {{ propFeature.values_.lon }}&nbsp;{{ propFeature.values_.lat }}
            </td>
          </tr>
          <tr>
            <td>VN2000:</td>
            <td>
              {{ propFeature.values_.vn2000_lng }}&nbsp;{{
                propFeature.values_.vn2000_lat
              }}
            </td>
          </tr>

          <tr>
            <td>Thời gian:</td>
            <td>
              {{ formatUnixDateTime2String(propFeature.values_.time_added) }}
            </td>
          </tr>
          <tr>
            <td>Tiểu khu/Lô/Khoảnh:</td>
            <td>
              {{
                propFeature.values_.khoanh ? propFeature.values_.khoanh : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Chủ rừng:</td>
            <td>
              {{
                propFeature.values_.so_chu_rung
                  ? propFeature.values_.so_chu_rung
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Diện tích:</td>
            <td>
              {{ area != null && area != undefined ? area + " ha" : "-" }}
            </td>
          </tr>
          <tr>
            <td>Ghi chú:</td>
            <td>
              {{ propFeature.values_.note ? propFeature.values_.note : "-" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import firesFunc from "@/utils/middleware/fires";
export default {
  props: {
    propFeature: {
      type: Object,
    },
  },
  data() {
    return {
      area: null,
    };
  },
  watch: {
    propFeature: {
      handler(val) {
        this.area = null;
        if (val && Object.keys(val).length > 0) {
          this.getArea();
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result.replace(":", "h").replace(" ", " ngày ");
        } catch (error) {}
      }
      return result;
    },
    async getArea() {
      try {
        let response = await firesFunc.getParcel({
          lat: this.propFeature.values_.lat,
          lon: this.propFeature.values_.lon,
        });
        if (response && response.status === 200)
          this.area =
            response.data && response.data.Area != null
              ? response.data.Area
              : null;
        else this.area = null;
      } catch (error) {
        this.area = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-content {
  .popup-content__title {
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    margin: 0;
    padding: 1rem;
    font-size: 14px;
    font-weight: 700;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .popup-content__body {
    margin: 0;
    padding: 0.75rem 1rem;
    h6 {
      font-size: 14px;
      i {
        cursor: pointer;
      }
    }
  }
  .popup-content__footer {
    border-top: 1px solid #ebebeb;
    padding: 0.75rem 1rem;
    button {
      text-align: center;
      &:hover,
      &:active {
        color: white;
      }
    }
  }
}
table {
  tr {
    td {
      font-size: 12px;
    }
  }
}
</style>