<template>
  <div
    class="select-wrap"
    @click="isOpenSelect = !isOpenSelect"
    :class="isOpenSelect ? 'select' : ''"
  >
    <div class="selected-container">
      <div class="title">Layers</div>
      <div class="selected-item">{{ selected.label }}</div>
      <div class="arrow">
        <span class="triangle">
          <svg
            v-if="!isOpenSelect"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            id="footer-sample-full"
            width=".75em"
            height=".75em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            class="iconify iconify--codicon"
          >
            <g fill="currentColor">
              <path
                d="M2 5.56L2.413 5h11.194l.393.54L8.373 11h-.827L2 5.56z"
              ></path>
            </g>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            id="footer-sample-full"
            width=".75em"
            height=".75em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            class="iconify iconify--codicon"
          >
            <g fill="currentColor">
              <path
                d="M14 10.44l-.413.56H2.393L2 10.46L7.627 5h.827L14 10.44z"
              ></path>
            </g>
          </svg>
        </span>
      </div>
    </div>
    <div class="selected-items" v-if="isOpenSelect">
      <ul class="list-unstyled">
        <li
          v-for="item in layers"
          :key="item.id"
          @click="selected = item"
          class="nav-item"
          :class="selected && selected.id === item.id ? 'selected' : ''"
        >
          {{ item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: null,
      layers: [
        {
          id: 1,
          label: "My Crops",
        },
        {
          id: 2,
          label: "Vegetation",
        },
        {
          id: 3,
          label: "Water Stress",
        },
        {
          id: 4,
          label: "Vegetation Rating",
        },
        {
          id: 5,
          label: "Crop Classification",
        },
      ],
      isOpenSelect: false,
    };
  },
  created() {
    this.selected = this.layers.find((x) => x.id === 2);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.select-wrap {
  border-radius: 8px;
  width: 200px;
  background: rgba(1, 2, 3, 0.8);
  position: relative;
  padding-left: 1rem;
  &:hover {
    cursor: pointer;
  }
  .selected-items {
    display: none;
  }
  &.select {
    background: #010203;
    .arrow {
      display: block;
      color: white;
      span {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
      }
    }
    .selected-items {
      display: block;
      position: absolute;
      width: 100%;
      background: #010203;
      top: 3.5rem;
      left: 0;
      border-radius: 0.5rem;
      ul {
        margin: 0;
        padding: 12px 0;
        li {
          display: block;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 4px 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: white;
          letter-spacing: 0.25px;
          text-transform: capitalize;
          &:hover {
            background-color: rgba(75, 92, 108, 0.4);
          }
          &.selected {
            color: #3592fd;
          }
        }
      }
    }
  }
  .title {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px;
    color: #8799ab;
    letter-spacing: 0.4px;
    position: relative;
  }
  .selected-item {
    width: 100%;
    height: 20 px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .arrow {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    color: white;
    span {
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
    }
  }
}
</style>