import axios from "axios";
import basicApi from "@/utils/apis/basic";
import { endpointApiUrl } from "@/utils/commons/environment";
export default {
  async getImages(body) {
    let headers = basicApi.images.get_all_images.headers;
    const object = axios({
      method: basicApi.images.get_all_images.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.images.get_all_images.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
};
