<template>
  <div class="analytic-content">
    <AnalyticMap
      :propImageDays="imageDays"
      :prop-widget="widget"
      @initMapSuccess="initMapSuccess"
      ref="analyticMap"
    />

    <div class="right-side">
      <RightSide
        :prop-map="map"
        :prop-field="field"
        ref="rightSide"
        @editField="editField"
        @removeField="removeField"
      />
    </div>
    <div
      class="left-side"
      :style="{ top: (documentHeight - 3 * 126) / 2 + 'px' }"
    >
      <LeftSide
        :prop-widget="widget"
        @zoomIn="onZoomIn"
        @zoomOut="onZoomOut"
        @go2SplitView="go2SplitView"
        @showHideLayersMap="showHideLayersMap"
      />
    </div>
    <div class="monitoring-dialog">
      <EditFieldDialog
        v-if="dialog.editField.show"
        :prop-show="dialog.editField.show"
        :prop-field="dialog.editField.value"
        @showSaveChangeQuestionDialog="dialog.saveChangeQuestion.show = true"
        @saveChangeField="saveChangeField"
        @removeField="removeField"
        @close="closeEditFieldDialog"
        ref="editFieldDialog"
      />
      <SaveChangeQuestionDialog
        v-if="dialog.saveChangeQuestion.show"
        :prop-show="dialog.saveChangeQuestion.show"
        @close="closeSaveChangeQuestion"
        @saveChangeField="saveChangeFieldAfterQuestion"
      />
      <DeleteFieldQuestionDialog
        v-if="dialog.deleteFieldQuestion.show"
        :prop-show="dialog.deleteFieldQuestion.show"
        @close="closeDeleteFieldQuestionDialog"
        @onDeleteField="onRemoveField"
      />
    </div>
  </div>
</template>
<script>
import AnalyticMap from "./AnalyticMap.vue";
import RightSide from "./RightSide.vue";
import LeftSide from "./LeftSide.vue";
import BottomSide from "./BottomSide.vue";
import SaveChangeQuestionDialog from "./dialog/SaveChangeQuestionDialog.vue";
import DeleteFieldQuestionDialog from "./dialog/DeleteFieldQuestionDialog.vue";
import EditFieldDialog from "./dialog/EditFieldDialog.vue";
import { getCenter } from "ol/extent";
import { fromLonLat, transform } from "ol/proj";
import { getArea, getLength } from "ol/sphere";

import XYZ from "ol/source/XYZ";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import {
  OSM,
  TileImage,
  Vector as VectorSource,
  ImageWMS,
  TileWMS,
  ImageArcGISRest,
  TileArcGISRest,
  ImageCanvas,
  TileDebug,
} from "ol/source.js";
import { getPointResolution } from "ol/proj";
import { toLonLat } from "ol/proj.js";
import {
  Style,
  Fill,
  Stroke,
  Circle as CircleStyle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";
import imagesFunc from "@/utils/middleware/images";
import firesFunc from "@/utils/middleware/fires";
export default {
  components: {
    RightSide,
    LeftSide,
    AnalyticMap,
    BottomSide,
    SaveChangeQuestionDialog,
    EditFieldDialog,
    DeleteFieldQuestionDialog,
  },
  data() {
    return {
      isShowRightSide: true,
      isSmallDevice: false,
      map: null,
      view: Object,
      field: null,
      imageDays: [],
      selectedImageDay: null,
      dialog: {
        editField: {
          show: false,
          value: null,
        },
        saveChangeQuestion: {
          show: false,
        },
        deleteFieldQuestion: {
          show: false,
          value: null,
        },
      },
      widget: {
        layers: {
          show: false,
          show2: false,
        },
      },
    };
  },
  async created() {
    let fields = [];
    let data = localStorage.getItem("fields");
    if (data && data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
      data = data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
      try {
        fields = JSON.parse(data);
      } catch (error) {}
    }
    let id = this.$route.params.id;
    if (fields && id) this.field = fields.find((x) => x.id + "" === id + "");
    if (!this.field) this.field = {};
    else {
      localStorage.setItem("field",id);
      setTimeout(() => {
        if (this.$refs.analyticMap)
          this.$refs.analyticMap.drawFields([this.field]);
      }, 100);
    }
    this.$store.dispatch("setSpinnerApp", {
      show: true,
    });
    await this.getImageDays(
      this.field.coordinates ? [this.field.coordinates] : []
    );
    this.$store.dispatch("setSpinnerApp", {
      show: false,
    });
  },
  mounted() {},
  watch: {
    configJson: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          if (this.map && Object.keys(this.map).length > 0) this.initLayers();
        }
      },
      immediate: true,
    },
  },
  methods: {
    showHideLayersMap() {
      //if (this.$refs.analyticMap) this.$refs.analyticMap.showHideLayersMap()
      if (this.widget.layers.show) this.closeWidgetLayers();
      else this.openWidgetLayers();
    },
    openWidgetLayers() {
      this.widget.layers.show2 = true;
      this.widget.layers.show = true;
    },
    closeWidgetLayers() {
      this.widget.layers.show2 = false;
      setTimeout(() => {
        this.widget.layers.show = false;
      }, 1000);
    },
    initMapSuccess(data) {
      this.map = data;
      if (this.map.getLayers().array_.length < 5) this.initLayers();
      this.addLayerFirePoints();
    },
    onZoomIn() {
      if (this.map.getView().getMaxZoom() > this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() + 1,
        });
      }
    },
    onZoomOut() {
      if (this.map.getView().getMinZoom() < this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() - 1,
        });
      }
    },
    go2SplitView() {
      this.$router.push({
        name: "SplitViewField",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    async getImageDays(coordinates) {
      try {
        let response = await imagesFunc.getImages({
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
        });
        if (response && response.status === 200)
          this.imageDays = response.data ? response.data : [];
      } catch (error) {
        console.log("error :>> ", error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$store.dispatch("setMessage", error.response.data.message);
        } else {
          //this.$store.dispatch("setMessage", "Can't get Images");
        }
      }
    },
    async saveChangeFieldAfterQuestion() {
      if (this.$refs.editFieldDialog) {
        await this.$refs.editFieldDialog.saveChangeField();
      }
      this.closeSaveChangeQuestion();
    },
    saveChangeField(data) {
      if (data.feature) {
        var ext = data.feature.getGeometry().getExtent();
        var center = getCenter(ext);
        let coordinates = [],
          coors = data.feature.getGeometry().getCoordinates();
        if (coors && coors.length > 0) {
          for (let i = 0; i < coors[0].length; i++) {
            coordinates.push(transform(coors[0][i], "EPSG:3857", "EPSG:4326"));
          }
        }
        this.field.area = (getArea(data.feature.getGeometry()) * 100) / 100;
        this.field.coordinate = center;
        this.field.coordinates = coordinates;

        if (this.$refs.analyticMap) {
          this.$refs.analyticMap.setGeometry({
            id: data.field.id,
            geometry: data.feature.getGeometry(),
            coordinates: coordinates,
          });
        }
        if (this.$refs.rightSide) {
          this.$refs.rightSide.changPreview({
            id: data.field.id,
            coordinates: coordinates,
          });
        }
      }
      this.field.name = data.field.name;
      let d = localStorage.getItem("fields");
      if (d && d.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
        d = d.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
        try {
          let fields = JSON.parse(d);
          let index = fields.findIndex((x) => x.id === this.field.id);
          fields[index] = this.field;
          localStorage.setItem("fields", JSON.stringify(fields));
        } catch (error) {}
      }
      if (this.$refs.analyticMap)
        this.$refs.analyticMap.updateField(this.field);

      this.dialog.editField.show = false;
      this.dialog.editField.value = null;
      this.isDisabledTool = false;
    },
    closeEditFieldDialog() {
      this.dialog.editField.show = false;
      this.dialog.editField.value = null;
      this.isDisabledTool = false;
    },
    editField(id) {
      this.dialog.editField.show = true;
      this.dialog.editField.value = this.field;
      this.isDisabledTool = true;
    },
    closeDeleteFieldQuestionDialog() {
      this.dialog.deleteFieldQuestion.value = null;
      this.dialog.deleteFieldQuestion.show = false;
    },
    removeField(id) {
      this.dialog.deleteFieldQuestion.value = id;
      this.dialog.deleteFieldQuestion.show = true;
    },
    onRemoveField(id) {
      let fields = [];
      let data = localStorage.getItem("fields");
      if (data && data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
        data = data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
        try {
          fields = JSON.parse(data);
          let index = fields.findIndex((x) => x.id === id);
          if (index >= 0) {
            fields.splice(index, 1);
            localStorage.setItem("fields", JSON.stringify(fields));
          }
        } catch (error) {}
      }
      this.closeDeleteFieldQuestionDialog();
      this.$router.push({
        name: "Monitoring",
      });
    },
    async initLayers() {
      if (this.configJson && Object.keys(this.configJson).length > 0) {
        if (this.configJson.layers && this.configJson.layers.length > 0) {
          for (let i = 0; i < this.configJson.layers.length; i++) {
            let layer = undefined;
            let type = Object(this.configJson.layers[i])["type"];
            switch (type) {
              case "TILE_LAYER":
                layer = await this.registerTileLayer(this.configJson.layers[i]);
                break;
              case "SHAPE_FILE":
                layer = await this.registerShapeFile(this.configJson.layers[i]);
                break;
              case "JSON_FILE":
                layer = await this.registerJsonFile(this.configJson.layers[i]);
                break;
              case "WMS_LAYER":
                layer = await this.registerWmsLayer(this.configJson.layers[i]);
                break;
              case "ARCGIS_MAPSERVICE":
                layer = await this.registerArcgisMapservice(
                  this.configJson.layers[i]
                );
                break;
            }
            if (layer) {
              layer.set("id", this.configJson.layers[i].id);
              layer.setZIndex(i + 1);
              this.map.addLayer(layer);
            }
          }
        }
      }
    },

    async addLayerFirePoints() {
      try {
        let responseSessions = await firesFunc.getSessions();
        if (responseSessions && responseSessions.status === 200) {
          if (
            responseSessions.data.status &&
            responseSessions.data.status.toLowerCase() === "ok"
          ) {
            let sessionIds = responseSessions.data.data.map((x) => x.id);
            let responseFirePoints = await firesFunc.getFirePoints(sessionIds);
            if (responseFirePoints && responseFirePoints.status === 200) {
              if (
                responseFirePoints.data.status &&
                responseFirePoints.data.status.toLowerCase() === "ok"
              ) {
                let layer = await this.registerJsonFile({
                  geojsonObject: responseFirePoints.data.data,
                  icon: "/img/icons/fire_16.png",
                  id: "firesLayer",
                });
                layer.setZIndex(this.map.getLayers().array_.length + 10);
                layer.set("id", "firesLayer");
                layer.setVisible(false);
                this.map.addLayer(layer);
                setTimeout(() => {
                  if (this.$refs.analyticMap)
                    this.$refs.analyticMap.addLayer2Widget({
                      id: "firesLayer",
                      title: "Fires / Hotspots",
                      show: false,
                      opacity: 100,
                    });
                }, 1000);
              }
            }
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    registerTileLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          if (layer.title === "OpenStreetMap") {
            resolve(
              new TileLayer({
                source: new OSM(),
                opacity: Object(layer).opacity,
                visible: Object(layer).show,
              })
            );
          } else {
            var obj = {
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileImage({
                url: Object(layer).source.url,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
              visible: Object(layer).show,
            };
            resolve(new TileLayer(obj));
          }
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerShapeFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let vector = new VectorLayer({
            source: vectorSource,
            style: [
              new Style({
                image: new Circle({
                  fill: new Fill({
                    color: "rgba(255,0,0,0.2)",
                  }),
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 1.25,
                  }),
                  radius: 5,
                }),
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
              }),
            ],
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerJsonFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let style = null;
          if (!layer.icon) {
            style = new Style({
              image: new CircleStyle({
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
                radius: 5,
              }),
              fill: new Fill({
                color: "rgba(255,0,0,0.2)",
              }),
              stroke: new Stroke({
                color: "#FF0000",
                width: 1.25,
              }),
            });
          } else {
            style = new Style({
              image: new Icon({
                size: [16, 16],
                offset: [0, 0],
                opacity: 1,
                scale: 1.0,
                src: layer.icon,
              }),
            });
          }
          let vector = new VectorLayer({
            source: vectorSource,
            style: [style],
            id: layer.id,
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerWmsLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          var obj = {
            title: Object(layer)["title"],
            id: Object(layer).id,
            source: new ImageWMS({
              url: Object(layer).source.url,
              params: Object(layer).source.params,
            }),
            show: Object(layer).show,
            opacity: Object(layer).opacity,
          };
          resolve(new XYZ(obj));
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerArcgisMapservice(layer) {
      try {
        return new Promise(function (resolve, reject) {
          resolve(
            new TileLayer({
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileArcGISRest({
                url: layer.source.url,
                params: layer.source.params,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
            })
          );
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    isCollapseMenu() {
      return this.$store.getters.getIsCollapseMenu;
    },
    configJson() {
      return this.$store.getters.getDataJson;
    },
  },
  beforeDestroy() {
    this.map = null;
    this.view = null;
  },
};
</script>
<style lang="scss" scoped>
.analytic-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.top-side {
  position: absolute;
  top: 0;
  left: 0;
}
.right-side {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
}
.bottom-side {
  position: absolute;
  bottom: 0.75rem;
  left: 30%;
}
.left-side {
  position: absolute;
  left: 0.5rem;
}
</style>