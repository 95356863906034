<template>
  <v-dialog
    v-model="show"
    min-width="320"
    max-width="480"
    :content-class="'alert-messages'"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <div v-html="message"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" @click="show = false">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    confirmText: {
      type: String,
      default: "Ok",
    },
    title: {
      type: String,
      default: "Message",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {},
  watch: {
    message: {
      handler(val) {
        this.show =
          val && val.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
            ? true
            : false;
      },
      immediate: true,
    },
    show(val) {
      if (!val) this.$store.dispatch("clearMessages");
    },
  },
  computed: {
    message() {
      return this.$store.getters.getMessages.join("<br>");
    },
  },
};
</script>
<style lang="scss">
.alert-messages {
  border-radius: 8px;
  .v-card__actions {
    border-top: 1px solid rgba(75, 92, 108, 0.4);
    button {
      color: white;
      border-radius: 0.5rem;
      background: rgba(75, 92, 108, 0.4);
      &:hover {
        background: rgba(75, 92, 108, 1);
      }
    }
  }
}
</style>