import Vue from "vue";
import VueI18n from "vue-i18n";

import vi from "./vi.json";
import en from "./en.json";

Vue.use(VueI18n);

const locale = "vi";

const messages = {
  vi,
  en
};

const i18n = new VueI18n({
  locale,
  messages
});

export default i18n;
