<template>
  <div class="splitview-content">
    <div class="row no-gutters" v-if="view && Object.keys(view).length > 0">
      <div class="col-12 col-lg-6">
        <div class="map-view">
          <SplitViewMap
            :propView="view"
            :prop-feature="feature"
            :propImageDays="imageDays"
            :prop-field="field"
            ref="splitViewOne"
            @pointerMove="pointerMove"
            @clearMeasureTooltip="clearMeasureTooltip"
            @initMapSuccess="maps.mapOne = $event"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="map-view">
          <SplitViewMap
            :propView="view"
            :prop-feature="feature"
            :propImageDays="imageDays"
            :prop-field="field"
            ref="splitViewTwo"
            @pointerMove="pointerMove"
            @clearMeasureTooltip="clearMeasureTooltip"
            @initMapSuccess="maps.mapTwo = $event"
          />
        </div>
      </div>
    </div>
    <!-- :style="{ top: (documentHeight - 126 - 48 - 48) / 2 + 'px' }" -->
    <div
      class="left-side"
      :style="{ top: (documentHeight - 3 * 126) / 2 + 'px' }"
    >
      <LeftSide
        :prop-buttons-tool="buttonsTool"
        @leftActionClick="leftActionClick"
        @zoomIn="onZoomIn"
        @zoomOut="onZoomOut"
        @go2AnalyticField="go2AnalyticField"
      />
    </div>
    <WidgetLayers
      v-show="buttonsTool.layers.show"
      :propShow="buttonsTool.layers.show2"
      @close="closeWidgetLayers"
      @visibleLayers="visibleLayers"
      @hideLayers="hideLayers"
      ref="widgetLayers"
    />
  </div>
</template>
<script>
import SplitViewMap from "./SplitViewMap.vue";
import LeftSide from "./LeftSide.vue";
import BottomSide from "./BottomSide.vue";
import WidgetLayers from "./WidgetLayers";
import { getCenter } from "ol/extent";
import { fromLonLat, transform } from "ol/proj";
import { getArea, getLength } from "ol/sphere";
import View from "ol/View";
import Feature from "ol/Feature";
import { Polygon } from "ol/geom";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import {
  OSM,
  TileImage,
  Vector as VectorSource,
  ImageWMS,
  TileWMS,
  ImageArcGISRest,
  TileArcGISRest,
  ImageCanvas,
  TileDebug,
} from "ol/source.js";
import {
  Style,
  Fill,
  Stroke,
  Circle as CircleStyle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";
import imagesFunc from "@/utils/middleware/images";
import firesFunc from "@/utils/middleware/fires";
export default {
  components: {
    LeftSide,
    SplitViewMap,
    BottomSide,
    WidgetLayers,
  },
  data() {
    return {
      isShowRightSide: true,
      isSmallDevice: false,
      map: null,
      view: Object,
      field: null,
      feature: null,

      imageDays: [],
      selectedImageDay: null,
      buttonsTool: {
        layers: {
          show: false,
          show2: false,
        },
        measure: false,
      },
      maps: {
        mapOne: Object,
        mapTwo: Object,
      },
    };
  },
  async created() {
    let fields = [];
    let data = localStorage.getItem("fields");
    if (data && data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
      data = data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
      try {
        fields = JSON.parse(data);
      } catch (error) {}
    }
    let extent = null;
    let id = this.$route.params.id;
    if (fields && id) this.field = fields.find((x) => x.id + "" === id + "");
    if (this.field && Object.keys(this.field).length > 0) {
      if (this.field.coordinates && this.field.coordinates.length > 0) {
        let coors = [];
        for (let i = 0; i < this.field.coordinates.length; i++) {
          coors.push(
            transform(this.field.coordinates[i], "EPSG:4326", "EPSG:3857")
          );
        }
        this.feature = new Feature({
          geometry: new Polygon([coors]),
          id: id,
        });
        this.feature.id_ = id;
        extent = this.feature.getGeometry().getExtent();
      }
    }
    if (this.feature && Object.keys(this.feature).length > 0) {
      this.view = new View({
        center: this.field.coordinate,
        minZoom: 1,
        maxZoom: 20,
      });
      this.view.fit(extent);
    } else {
      this.view = new View({
        center: fromLonLat([108.22779930115105, 16.062106484745996]),
        zoom: 5,
        minZoom: 1,
        maxZoom: 20,
      });
    }
    this.$store.dispatch("setSpinnerApp", {
      show: true,
    });
    await this.getImageDays(
      this.field.coordinates ? [this.field.coordinates] : []
    );
    this.$store.dispatch("setSpinnerApp", {
      show: false,
    });
  },
  mounted() {
    setTimeout(() => {
      this.addLayerFirePoints();
    }, 3000);
  },

  methods: {
    async addLayerFirePoints() {
      try {
        let responseSessions = await firesFunc.getSessions();
        if (responseSessions && responseSessions.status === 200) {
          if (
            responseSessions.data.status &&
            responseSessions.data.status.toLowerCase() === "ok"
          ) {
            let sessionIds = responseSessions.data.data.map((x) => x.id);
            let responseFirePoints = await firesFunc.getFirePoints(sessionIds);
            if (responseFirePoints && responseFirePoints.status === 200) {
              if (
                responseFirePoints.data.status &&
                responseFirePoints.data.status.toLowerCase() === "ok"
              ) {
                if (this.$refs.splitViewOne) {
                  let layer = await this.$refs.splitViewOne.registerJsonFile({
                    geojsonObject: responseFirePoints.data.data,
                    icon: "/img/icons/fire_16.png",
                    id: "firesLayer",
                  });
                  layer.setZIndex(
                    this.maps.mapOne.getLayers().array_.length + 10
                  );
                  layer.set("id", "firesLayer");
                  layer.setVisible(false);
                  this.maps.mapOne.addLayer(layer);
                }
                if (this.$refs.splitViewTwo) {
                  let layer = await this.$refs.splitViewTwo.registerJsonFile({
                    geojsonObject: responseFirePoints.data.data,
                    icon: "/img/icons/fire_16.png",
                    id: "firesLayer",
                  });
                  layer.setZIndex(
                    this.maps.mapTwo.getLayers().array_.length + 10
                  );
                  layer.set("id", "firesLayer");
                  layer.setVisible(false);
                  this.maps.mapTwo.addLayer(layer);
                }

                setTimeout(() => {
                  if (this.$refs.widgetLayers)
                    this.$refs.widgetLayers.addLayer2Widget({
                      id: "firesLayer",
                      title: "Fires / Hotspots",
                      show: false,
                      opacity: 100,
                    });
                }, 1000);
              }
            }
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    visibleLayers(ids) {
      if (this.$refs.splitViewOne) {
        this.$refs.splitViewOne.visibleLayers(ids);
      }
      if (this.$refs.splitViewTwo) {
        this.$refs.splitViewTwo.visibleLayers(ids);
      }
    },
    hideLayers(ids) {
      if (this.$refs.splitViewOne) {
        this.$refs.splitViewOne.hideLayers(ids);
      }
      if (this.$refs.splitViewTwo) {
        this.$refs.splitViewTwo.hideLayers(ids);
      }
    },
    clearMeasureTooltip() {
      if (this.$refs.splitViewOne) {
        this.$refs.splitViewOne.clearMeasureTooltip();
      }
      if (this.$refs.splitViewTwo) {
        this.$refs.splitViewTwo.clearMeasureTooltip();
      }
    },
    pointerMove(data) {
      if (this.$refs.splitViewOne) {
        if (this.$refs.splitViewOne.mapId != data.id) {
          this.$refs.splitViewOne.pointerMove(data);
        }
      }
      if (this.$refs.splitViewTwo) {
        if (this.$refs.splitViewTwo.mapId != data.id) {
          this.$refs.splitViewTwo.pointerMove(data);
        }
      }
    },
    onZoomIn() {
      if (this.view.getMaxZoom() > this.view.getZoom()) {
        this.view.animate({
          duration: 500,
          zoom: this.view.getZoom() + 1,
        });
      }
    },
    onZoomOut() {
      if (this.view.getMinZoom() < this.view.getZoom()) {
        this.view.animate({
          duration: 500,
          zoom: this.view.getZoom() - 1,
        });
      }
    },
    go2AnalyticField() {
      this.$router.push({
        name: "AnalyticField",
        params: {
          id: this.$route.params.id,
        },
      });
    },

    async getImageDays(coordinates) {
      try {
        let response = await imagesFunc.getImages({
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
        });
        if (response && response.status === 200)
          this.imageDays = response.data ? response.data : [];
      } catch (error) {
        console.log("error :>> ", error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$store.dispatch("setMessage", error.response.data.message);
        } else {
          //this.$store.dispatch("setMessage", "Can't get Images");
        }
      }
    },
    showHideLayersMap() {
      if (this.buttonsTool.layers.show) this.closeWidgetLayers();
      else this.openWidgetLayers();
    },
    openWidgetLayers() {
      this.buttonsTool.layers.show2 = true;
      this.buttonsTool.layers.show = true;
    },
    closeWidgetLayers() {
      this.buttonsTool.layers.show2 = false;
      setTimeout(() => {
        this.buttonsTool.layers.show = false;
      }, 1000);
    },
    leftActionClick(val) {
      switch (val.code) {
        case "LAYERS_MAP":
          this.showHideLayersMap();
          break;
        case "ZOOM_IN":
          this.onZoomIn();
          break;
        case "ZOOM_OUT":
          this.onZoomOut();
          break;
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    isCollapseMenu() {
      return this.$store.getters.getIsCollapseMenu;
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.splitview-content {
  width: 100%;
  height: 100vh;
  .map-view {
    position: relative;
    width: 100%;
    height: 100vh;
  }
}
.top-side {
  position: absolute;
  top: 0;
  left: 0;
}
.right-side {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
}
.bottom-side {
  position: absolute;
  bottom: 0.75rem;
  left: 30%;
}
.left-side {
  position: absolute;
  left: 0.5rem;
}
</style>