<template>
  <div class="container-fluid">
    <login-form></login-form>
  </div>
</template>
<script>
import LoginForm from "@/components/pages/login/Index";
export default {
  components: {
    LoginForm,
  },
  created() {},
  methods: {},
  mounted() {
    if (this.loggedIn)
      this.$router.push({
        path: "/",
      });
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>