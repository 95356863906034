<template>
  <div class="monitoring-content">
    <OpenlayerMap
      :prop-fields="fields"
      :prop-buttons-tool="buttonsTool"
      @initMapSuccess="initMapSuccess"
      ref="openlayerMap"
    />
    <div class="top-side">
      <SearchBox @onSearch="onSearch" @pan2Location="onPan2Location" />
    </div>
    <div class="right-side">
      <RightSide
        :propMap="map"
        :prop-fields="fields"
        :propFieldEditIds="fieldEditIds"
        @saveFields="showDialogSaveFields"
        @removeField="removeField"
        @filesChanged="filesChanged"
        @cancelDrawing="cancelDrawing"
        @editField="onEditField"
        ref="rightSide"
      />
    </div>
    <div
      class="left-side"
      :style="{ top: (documentHeight - 3 * 126) / 2 + 'px' }"
    >
      <LeftSide
        :prop-buttons-tool="buttonsTool"
        @leftActionClick="leftActionClick"
      />
    </div>
    <div class="monitoring-dialog">
      <EditFieldDialog
        v-if="dialog.editField.show"
        :prop-show="dialog.editField.show"
        :prop-field="dialog.editField.value"
        @showSaveChangeQuestionDialog="dialog.saveChangeQuestion.show = true"
        @saveChangeField="saveChangeField"
        @removeField="removeField"
        @close="closeEditFieldDialog"
        ref="editFieldDialog"
      />
      <SaveChangeQuestionDialog
        v-if="dialog.saveChangeQuestion.show"
        :prop-show="dialog.saveChangeQuestion.show"
        @close="closeSaveChangeQuestion"
        @saveChangeField="saveChangeFieldAfterQuestion"
      />
      <SaveFieldsDialog
        v-if="dialog.saveFields.show"
        :prop-show="dialog.saveFields.show"
        :prop-fields="fields"
        @showSaveFieldsQuestionDialog="dialog.saveFieldsQuestion.show = true"
        @saveChangeFields="saveChangeFields"
        @onClose="closeSaveFieldsDialog"
      />
      <SaveFieldsQuestionDialog
        v-if="dialog.saveFieldsQuestion.show"
        :prop-show="dialog.saveFieldsQuestion.show"
        @saveFields="onSaveFields"
        @removeFields="removeFields"
        @close="dialog.saveFieldsQuestion.show = false"
      />
    </div>
  </div>
</template>
<script>
import OpenlayerMap from "./OpenlayerMap.vue";
import RightSide from "./RightSide.vue";
import LeftSide from "./LeftSide.vue";
import BottomSide from "./BottomSide.vue";
import SearchBox from "./SearchBox.vue";
import SaveFieldsQuestionDialog from "./dialog/SaveFieldsQuestionDialog.vue";
import SaveChangeQuestionDialog from "./dialog/SaveChangeQuestionDialog.vue";
import EditFieldDialog from "./dialog/EditFieldDialog.vue";
import SaveFieldsDialog from "./dialog/SaveFieldsDialog.vue";

import KML from "ol/format/KML.js";
import { getCenter } from "ol/extent";
import { transform } from "ol/proj";
import { getArea } from "ol/sphere";
import Feature from "ol/Feature";
import { Polygon } from "ol/geom.js";
import XYZ from "ol/source/XYZ";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import {
  OSM,
  TileImage,
  Vector as VectorSource,
  ImageWMS,
  TileWMS,
  ImageArcGISRest,
  TileArcGISRest,
  ImageCanvas,
  TileDebug,
} from "ol/source.js";
import {
  Style,
  Fill,
  Stroke,
  Circle as CircleStyle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";
import firesFunc from "@/utils/middleware/fires";
export default {
  components: {
    RightSide,
    LeftSide,
    OpenlayerMap,
    BottomSide,
    SearchBox,
    SaveFieldsQuestionDialog,
    SaveChangeQuestionDialog,
    EditFieldDialog,
    SaveFieldsDialog,
  },
  data() {
    return {
      isShowRightSide: true,
      isSmallDevice: false,
      map: null,
      view: Object,
      dialog: {
        saveFieldsQuestion: {
          show: false,
        },
        editField: {
          show: false,
          value: null,
        },
        saveFields: {
          show: false,
        },
        saveChangeQuestion: {
          show: false,
        },
      },
      fields: [],
      fieldsInStorage: [],
      fieldEditIds: [],
      buttonsTool: {
        layers: {
          show: false,
          show2: false,
        },
        measure: false,
      },
    };
  },
  created() {
    let data = localStorage.getItem("fields");
    if (data && data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
      data = data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
      try {
        this.fieldsInStorage = JSON.parse(data);
      } catch (error) {}
    }
    this.fields = this.$store.getters.getFieldsUpload;

    setTimeout(() => {
      this.$store.dispatch("setFieldsUpload", []);
    }, 1000);
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.openlayerMap)
        this.$refs.openlayerMap.drawFields(this.fields);
    }, 100);
  },
  watch: {
    configJson: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          if (this.map && Object.keys(this.map).length > 0) this.initLayers();
        }
      },
      immediate: true,
    },
  },
  methods: {
    showHideLayersMap() {
      //if (this.$refs.analyticMap) this.$refs.analyticMap.showHideLayersMap()
      if (this.buttonsTool.layers.show) this.closeWidgetLayers();
      else this.openWidgetLayers();
    },
    openWidgetLayers() {
      this.buttonsTool.layers.show2 = true;
      this.buttonsTool.layers.show = true;
    },
    closeWidgetLayers() {
      this.buttonsTool.layers.show2 = false;
      setTimeout(() => {
        this.buttonsTool.layers.show = false;
      }, 1000);
    },

    onPan2Location(coordinate) {
      let zoomLevel = this.map.getView().getZoom();
      this.map.getView().animate({
        center: transform(coordinate, "EPSG:4326", "EPSG:3857"),
        duration: 2000,
        zoom: zoomLevel < 16 ? 16 : zoomLevel,
      });
    },
    onSearch(textSearch) {
      if (this.map && Object.keys(this.map).length > 0) {
        let array = textSearch.split(",");
        if (array && array.length > 1) {
          let lat = null,
            lng = null;
          try {
            lat = parseFloat(
              array[0].trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
            );
          } catch (error) {
            this.$store.dispatch(
              "setMessage",
              "Please enter correct coordinates"
            );
          }
          try {
            lng = parseFloat(
              array[1].trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
            );
          } catch (error) {
            this.$store.dispatch(
              "setMessage",
              "Please enter correct coordinates"
            );
          }
          let zoomLevel = this.map.getView().getZoom();
          this.map.getView().animate({
            center: transform([lng, lat], "EPSG:4326", "EPSG:3857"),
            duration: 2000,
            zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
          });
        } else {
          this.$store.dispatch(
            "setMessage",
            "Please enter correct coordinates"
          );
        }
      }
    },
    initMapSuccess(data) {
      this.map = data;
      if (this.map.getLayers().array_.length < 5) this.initLayers();
      this.addLayerFirePoints();
    },

    leftActionClick(val) {
      switch (val.code) {
        case "LAYERS_MAP":
          this.showHideLayersMap();
          break;
        case "ZOOM_IN":
          this.onZoomIn();
          break;
        case "ZOOM_OUT":
          this.onZoomOut();
          break;
      }
    },
    onZoomIn() {
      if (this.map.getView().getMaxZoom() > this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() + 1,
        });
      }
    },
    onZoomOut() {
      if (this.map.getView().getMinZoom() < this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() - 1,
        });
      }
    },
    removeFields() {
      this.$router.push({ name: "Monitoring" });
      this.fields = [];
    },
    showDialogSaveFields() {
      this.dialog.saveFields.show = true;
      this.isDisabledTool = false;
    },
    onSaveFields() {
      let fieldsStorage = [];
      let data = localStorage.getItem("fields");
      if (data && data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
        data = data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
        try {
          fieldsStorage = JSON.parse(data);
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
      if (!fieldsStorage) fieldsStorage = [];
      localStorage.setItem(
        "fields",
        JSON.stringify([...fieldsStorage, ...this.fields])
      );

      if (this.fields && this.fields.length > 0)
        localStorage.setItem("field", this.fields[this.fields.length - 1].id);
      setTimeout(() => {
        this.$router.push({
          name: "Monitoring",
        });
      }, 50);
    },
    cancelDrawing() {
      this.dialog.saveFieldsQuestion.show = true;
    },
    closeSaveFieldsDialog() {
      this.dialog.saveFields.show = false;
    },
    closeEditFieldDialog() {
      this.dialog.editField.show = false;
      this.dialog.editField.value = null;
      this.isDisabledTool = false;
      this.fieldEditIds = [];
    },
    onEditField(id) {
      this.dialog.editField.show = true;
      this.dialog.editField.value = this.fields.find((x) => x.id === id);
      this.isDisabledTool = true;
      this.fieldEditIds = [id];
    },
    removeField(id) {
      let index = this.fields.findIndex((x) => x.id === id);
      if (index >= 0) {
        this.fields.splice(index, 1);
        if (this.$refs.openlayerMap) {
          this.$refs.openlayerMap.removeFeature(id);
        }
      }
    },
    closeSaveChangeQuestion() {
      this.dialog.saveChangeQuestion.show = false;
      this.closeEditFieldDialog();
    },
    async saveChangeFieldAfterQuestion() {
      if (this.$refs.editFieldDialog) {
        await this.$refs.editFieldDialog.saveChangeField();
      }
      this.closeSaveChangeQuestion();
    },

    saveChangeField(data) {
      let index = this.fields.findIndex((x) => x.id === data.field.id);
      if (index >= 0) {
        this.fields[index].rowsSeason = data.rowsSeason;
        if (data.feature) {
          var ext = data.feature.getGeometry().getExtent();
          var center = getCenter(ext);
          let coordinates = [],
            coors = data.feature.getGeometry().getCoordinates();
          if (coors && coors.length > 0) {
            for (let i = 0; i < coors[0].length; i++) {
              coordinates.push(
                transform(coors[0][i], "EPSG:3857", "EPSG:4326")
              );
            }
          }
          this.fields[index].area =
            (getArea(data.feature.getGeometry()) * 100) / 100;
          this.fields[index].coordinate = center;
          this.fields[index].coordinates = coordinates;

          if (this.$refs.openlayerMap) {
            this.$refs.openlayerMap.setGeometry({
              id: data.field.id,
              geometry: data.feature.getGeometry(),
              coordinates: coordinates,
            });
          }
          if (this.$refs.rightSide) {
            this.$refs.rightSide.changPreview({
              id: data.field.id,
              coordinates: coordinates,
            });
          }
        }
      }
      let index2 = this.fieldEditIds.findIndex((x) => x === data.field.id);
      if (index2 >= 0) this.fieldEditIds.splice(index2, 1);
      this.dialog.editField.show = false;
      this.dialog.editField.value = null;
      this.isDisabledTool = false;
    },
    saveChangeFields(data) {
      if (data && Object.keys(data).length) {
        if (data.fields) this.fields = data.fields;
        if (data.features && data.features.length > 0) {
          for (let i = 0; i < data.features.length; i++) {
            let index = this.fields.findIndex(
              (x) => x.id === data.features[i].id_
            );
            if (index >= 0) {
              var ext = data.features[i].getGeometry().getExtent();
              var center = getCenter(ext);
              let coordinates = [],
                coors = data.features[i].getGeometry().getCoordinates();
              if (coors && coors.length > 0) {
                for (let i = 0; i < coors[0].length; i++) {
                  coordinates.push(
                    transform(coors[0][i], "EPSG:3857", "EPSG:4326")
                  );
                }
              }
              this.fields[index].area =
                (getArea(data.features[i].getGeometry()) * 100) / 100;
              this.fields[index].coordinate = center;
              this.fields[index].coordinates = coordinates;

              if (this.$refs.openlayerMap) {
                this.$refs.openlayerMap.setGeometry({
                  id: this.fields[index].id,
                  geometry: data.features[i].getGeometry(),
                  coordinates: coordinates,
                });
              }
              if (this.$refs.rightSide) {
                this.$refs.rightSide.changPreview({
                  id: this.fields[index].id,
                  coordinates: coordinates,
                });
              }
            }
          }
        }
        this.onSaveFields();
      }
    },

    async filesChanged(files) {
      if (!files.length) return;
      else {
        for (let i = 0; i < files.length; i++) {
          this.$store.dispatch("setSpinnerApp", true);
          let extension = files[i].name.split(".").pop();
          let data;
          switch (extension.toUpperCase()) {
            case "KMZ":
              data = await this.readKMZFile(files[i]);
              if (typeof data === "object") {
                if (data && data.features) {
                  await this.assignFieldsFromKmz(data.features);
                }
              }
              break;
            case "KML":
              data = await this.readKMLFile(files[i]);
              if (typeof data === "object") {
                if (data && Object.keys(data).length > 0) {
                  await this.assignFieldsFromKml(data);
                }
              }
              break;
            case "GEOJSON":
              data = await this.readGeojsonFile(files[i]);
              if (typeof data === "object") {
                if (data && data.length > 0) {
                  await this.assignFieldsFromGeojson(data);
                }
              }
              break;
            case "ZIP":
              data = await this.readZipShapeFile(files[i]);
              if (typeof data === "object") {
                if (data && data.features) {
                  await this.assignFieldsFromShapeFile(data.features);
                }
              }
              break;
          }
          if (typeof data === "string") {
            this.msg.push(data);
          }
          if (this.$refs.openlayerMap)
            this.$refs.openlayerMap.drawFields(this.fields);
          this.$store.dispatch("setSpinnerApp", false);
        }
      }
    },

    assignFieldsFromShapeFile(features) {
      let names = [];
      for (let i = 0; i < features.length; i++) {
        let coordinates =
          features[i].geometry &&
          features[i].geometry.coordinates &&
          features[i].geometry.coordinates.length > 0
            ? features[i].geometry.coordinates[0]
            : [];
        let coors = [];
        for (let i = 0; i < coordinates.length; i++) {
          coors.push(transform(coordinates[i], "EPSG:4326", "EPSG:3857"));
        }
        if (coors.length > 0) {
          let id =
            new Date().getTime() -
            Math.floor(Math.random() * 999999999) +
            Math.floor(Math.random() * 888888888);
          let feature = new Feature({
            geometry: new Polygon([coors]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          var ext = feature.getGeometry().getExtent();
          var center = getCenter(ext);

          let name =
            features[i].properties && features[i].properties.name
              ? features[i].properties.name
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ") +
                (" " + (i + 1))
              : "Field " + " " + (this.fieldsInStorage.length + i + 1);

          names.push(name);
          name =
            names.filter((x) => x === name) > 1
              ? name + " (" + names.filter((x) => x === name).length + ")"
              : name;
          this.fields.push({
            id: id,
            name: name,
            group_name: null,
            coordinate: center,
            coordinates: coordinates,
            area: (getArea(feature.getGeometry()) * 100) / 100,
            rowsSeason: [],
          });
        }
      }
    },
    assignFieldsFromGeojson(features) {
      let names = [];
      for (let i = 0; i < features.length; i++) {
        let ext = features[i].getGeometry().getExtent();
        var center = getCenter(ext),
          coordinates = [];
        let coors = features[i].getGeometry().getCoordinates();
        if (coors && coors.length > 0) {
          for (let j = 0; j < coors[0].length; j++) {
            coordinates.push(
              //transform(
              [coors[0][j][0], coors[0][j][1]]
              //   "EPSG:3857",
              //   "EPSG:4326"
              // )
            );
          }
        }

        let name =
          features[i].values_ && features[i].values_.name
            ? features[i].values_.name
                .trim()
                .replace(/\s\s+/g, " ")
                .replace(/  +/g, " ") +
              (" " + (i + 1))
            : "Field " + " " + (this.fieldsInStorage.length + i + 1);
        names.push(name);
        name =
          names.filter((x) => x === name) > 1
            ? name + " (" + names.filter((x) => x === name).length + ")"
            : name;
        let id =
          new Date().getTime() -
          Math.floor(Math.random() * 999999999) +
          Math.floor(Math.random() * 888888888);
        let feature = new Feature(
          features[i].getGeometry().clone().transform("EPSG:4326", "EPSG:3857")
        );
        this.fields.push({
          id: id,
          name: name,
          group_name: null,
          coordinate: transform(center, "EPSG:4326", "EPSG:3857"),
          coordinates: coordinates,
          area: (getArea(feature.getGeometry()) * 100) / 100,
          rowsSeason: [],
        });
      }
    },
    assignFieldsFromKml(feature) {
      let names = [];
      let ext = feature.getGeometry().getExtent();
      var center = getCenter(ext),
        coordinates = [];

      let name =
        feature.values_ && feature.values_.name
          ? feature.values_.name
              .trim()
              .replace(/\s\s+/g, " ")
              .replace(/  +/g, " ") +
            (" " + (i + 1))
          : "Field " + " " + (this.fieldsInStorage.length + i + 1);

      names.push(name);
      name =
        names.filter((x) => x === name) > 1
          ? name + " (" + names.filter((x) => x === name).length + ")"
          : name;
      let id =
        new Date().getTime() -
        Math.floor(Math.random() * 999999999) +
        Math.floor(Math.random() * 888888888);
      let featureConvert = new Feature(
        feature.getGeometry().clone().transform("EPSG:4326", "EPSG:3857")
      );
      let coors = featureConvert.getGeometry().getCoordinates();
      if (coors && coors.length > 0) {
        for (let j = 0; j < coors[0].length; j++) {
          coordinates.push(
            transform(
              [coors[0][j][0], coors[0][j][1]],
              "EPSG:3857",
              "EPSG:4326"
            )
          );
        }
      }
      this.fields.push({
        id: id,
        name: name,
        group_name: null,
        coordinate: transform(center, "EPSG:4326", "EPSG:3857"),
        coordinates: coordinates,
        area: (getArea(featureConvert.getGeometry()) * 100) / 100,
        rowsSeason: [],
      });
    },
    assignFieldsFromKmz(features) {
      let names = [];
      for (let i = 0; i < features.length; i++) {
        let coordinates =
          features[i].geometry &&
          features[i].geometry.coordinates &&
          features[i].geometry.coordinates.length > 0
            ? features[i].geometry.coordinates[0]
            : [];
        let coors = [];
        for (let i = 0; i < coordinates.length; i++) {
          coors.push(transform(coordinates[i], "EPSG:4326", "EPSG:3857"));
        }
        if (coors.length > 0) {
          let id =
            new Date().getTime() -
            Math.floor(Math.random() * 999999999) +
            Math.floor(Math.random() * 888888888);
          let feature = new Feature({
            geometry: new Polygon([coors]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          var ext = feature.getGeometry().getExtent();
          var center = getCenter(ext);

          let name =
            features[i].properties && features[i].properties.name
              ? features[i].properties.name
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ") +
                (" " + (i + 1))
              : "Field " + " " + (this.fieldsInStorage.length + i + 1);

          names.push(name);
          name =
            names.filter((x) => x === name) > 1
              ? name + " (" + names.filter((x) => x === name).length + ")"
              : name;
          this.fields.push({
            id: id,
            name: name,
            group_name: null,
            coordinate: center,
            coordinates: coordinates,
            area: (getArea(feature.getGeometry()) * 100) / 100,
            rowsSeason: [],
          });
        }
      }
    },
    readGeojsonFile(file) {
      return new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = async function () {
            let features = new GeoJSON().readFeatures(reader.result, {
              featureProjection: "EPSG:4326",
            });

            resolve(features);
          };
          reader.readAsText(file);
        } catch (error) {
          resolve(file.name + " cannot be read");
        }
      });
    },
    async readKMLFile(file) {
      return new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = async function () {
            let format = new KML({});
            let feature = format.readFeature(reader.result, {
              featureProjection: "EPSG:4326",
            });
            resolve(feature);
          };
          reader.readAsText(file);
        } catch (error) {
          resolve(file.name + " cannot be read");
        }
      });
    },
    async readKMZFile(kmzFile) {
      return new Promise(async (resolve, reject) => {
        try {
          var JSZip = require("jszip");
          const tj = require("@tmcw/togeojson");
          let getDom = (xml) =>
            new DOMParser().parseFromString(xml, "text/xml");
          let getExtension = (fileName) => fileName.split(".").pop();

          let getKmlDom = (kmzFile) => {
            var zip = new JSZip();
            return zip.loadAsync(kmzFile).then((zip) => {
              let kmlDom = null;
              zip.forEach((relPath, file) => {
                if (getExtension(relPath) === "kml" && kmlDom === null) {
                  kmlDom = file.async("string").then(getDom);
                }
              });
              return kmlDom || resolve("No kml file found");
            });
          };
          let geoJson = await getKmlDom(kmzFile).then((kmlDom) => {
            let geoJsonObject = tj.kml(kmlDom);
            return geoJsonObject;
          });
          resolve(geoJson);
        } catch (error) {
          resolve("No kml file found");
        }
      });
    },
    readZipShapeFile(file) {
      var shp = require("shpjs");
      return new Promise(function (resolve, reject) {
        try {
          var reader = new FileReader();
          reader.onload = function () {
            shp(this.result).then(function (geojson) {
              if (geojson) {
                resolve(geojson);
              } else resolve(file.name + " cannot be read");
            });
          };
          reader.readAsArrayBuffer(file);
        } catch (error) {
          resolve(file.name + " cannot be read");
        }
      });
    },
    async initLayers() {
      if (this.configJson && Object.keys(this.configJson).length > 0) {
        if (this.configJson.layers && this.configJson.layers.length > 0) {
          for (let i = 0; i < this.configJson.layers.length; i++) {
            let layer = undefined;
            let type = Object(this.configJson.layers[i])["type"];
            switch (type) {
              case "TILE_LAYER":
                layer = await this.registerTileLayer(this.configJson.layers[i]);
                break;
              case "SHAPE_FILE":
                layer = await this.registerShapeFile(this.configJson.layers[i]);
                break;
              case "JSON_FILE":
                layer = await this.registerJsonFile(this.configJson.layers[i]);
                break;
              case "WMS_LAYER":
                layer = await this.registerWmsLayer(this.configJson.layers[i]);
                break;
              case "ARCGIS_MAPSERVICE":
                layer = await this.registerArcgisMapservice(
                  this.configJson.layers[i]
                );
                break;
            }
            if (layer) {
              layer.set("id", this.configJson.layers[i].id);
              layer.setZIndex(i + 1);
              this.map.addLayer(layer);
            }
          }
        }
      }
    },
    async addLayerFirePoints() {
      try {
        let responseSessions = await firesFunc.getSessions();
        if (responseSessions && responseSessions.status === 200) {
          if (
            responseSessions.data.status &&
            responseSessions.data.status.toLowerCase() === "ok"
          ) {
            let sessionIds = responseSessions.data.data.map((x) => x.id);
            let responseFirePoints = await firesFunc.getFirePoints(sessionIds);
            if (responseFirePoints && responseFirePoints.status === 200) {
              if (
                responseFirePoints.data.status &&
                responseFirePoints.data.status.toLowerCase() === "ok"
              ) {
                let layer = await this.registerJsonFile({
                  geojsonObject: responseFirePoints.data.data,
                  icon: "/img/icons/fire_16.png",
                  id: "firesLayer",
                });
                layer.setZIndex(this.map.getLayers().array_.length + 10);
                layer.set("id", "firesLayer");
                layer.setVisible(true);
                this.map.addLayer(layer);
                setTimeout(() => {
                  if (this.$refs.openlayerMap)
                    this.$refs.openlayerMap.addLayer2Widget({
                      id: "firesLayer",
                      title: "Fires / Hotspots",
                      show: true,
                      opacity: 100,
                    });
                }, 1000);
              }
            }
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    registerTileLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          if (layer.title === "OpenStreetMap") {
            resolve(
              new TileLayer({
                source: new OSM(),
                opacity: Object(layer).opacity,
                visible: Object(layer).show,
              })
            );
          } else {
            var obj = {
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileImage({
                url: Object(layer).source.url,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
              visible: Object(layer).show,
            };
            resolve(new TileLayer(obj));
          }
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerShapeFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let vector = new VectorLayer({
            source: vectorSource,
            style: [
              new Style({
                image: new CircleStyle({
                  fill: new Fill({
                    color: "rgba(255,0,0,0.2)",
                  }),
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 1.25,
                  }),
                  radius: 5,
                }),
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
              }),
            ],
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerJsonFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let style = null;
          if (!layer.icon) {
            style = new Style({
              image: new CircleStyle({
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
                radius: 5,
              }),
              fill: new Fill({
                color: "rgba(255,0,0,0.2)",
              }),
              stroke: new Stroke({
                color: "#FF0000",
                width: 1.25,
              }),
            });
          } else {
            style = new Style({
              image: new Icon({
                size: [16, 16],
                offset: [0, 0],
                opacity: 1,
                scale: 1.0,
                src: layer.icon,
              }),
            });
          }
          let vector = new VectorLayer({
            source: vectorSource,
            style: [style],
            id: layer.id,
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerWmsLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          var obj = {
            title: Object(layer)["title"],
            id: Object(layer).id,
            source: new ImageWMS({
              url: Object(layer).source.url,
              params: Object(layer).source.params,
            }),
            show: Object(layer).show,
            opacity: Object(layer).opacity,
          };
          resolve(new XYZ(obj));
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerArcgisMapservice(layer) {
      try {
        return new Promise(function (resolve, reject) {
          resolve(
            new TileLayer({
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileArcGISRest({
                url: layer.source.url,
                params: layer.source.params,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
            })
          );
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    isCollapseMenu() {
      return this.$store.getters.getIsCollapseMenu;
    },
    configJson() {
      return this.$store.getters.getDataJson;
    },
  },
};
</script>
<style lang="scss" scoped>
.monitoring-contentp {
  position: relative;
  width: 100%;
  height: 100%;
}
.top-side {
  position: absolute;
  top: 0;
  left: 0;
}
.right-side {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
}
.bottom-side {
  position: absolute;
  bottom: 0;
  left: 0;
}
.left-side {
  position: absolute;
  left: 0;
}
</style>