<template>
  <div class="search-box search-wrapper">
    <div class="search-logo">
      <img src="/img/logo.png" height="32" />
    </div>
    <div class="search-input">
      <input
        id="pac-input"
        type="text"
        class="form-control"
        v-model="textSearch"
        maxlength="255"
      />
    </div>
    <div class="search-controls">
      <button
        class="btn btn-clear"
        @click="textSearch = null"
        v-if="textSearch && textSearch.length > 0"
      >
        <span class="button-icon">
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              id="footer-sample-full"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
              class="iconify iconify--la"
            >
              <path
                d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781L7.22 26.22L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z"
                fill="currentColor"
              ></path></svg
          ></span>
        </span>
      </button>
      <button class="btn btn-search" @click="onSearch">
        <span class="button-icon">
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="100%"
              height="100%"
              viewBox="0 0 18 18"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <defs>
                <path
                  id="iocga"
                  d="M369.987 2642.463a4.482 4.482 0 0 1-4.492-4.485 4.482 4.482 0 0 1 4.492-4.484 4.482 4.482 0 0 1 4.493 4.484 4.482 4.482 0 0 1-4.493 4.485zm5.978 0h-.787l-.28-.269a6.476 6.476 0 0 0 1.476-5.322c-.468-2.77-2.782-4.983-5.574-5.322a6.485 6.485 0 0 0-7.25 7.246c.34 2.79 2.553 5.102 5.325 5.57a6.485 6.485 0 0 0 5.325-1.474l.27.279v.787l4.238 4.236a1.054 1.054 0 0 0 1.485 0 1.052 1.052 0 0 0 0-1.485z"
                ></path>
              </defs>
              <g>
                <g transform="translate(-363 -2631)">
                  <use xlink:href="#iocga"></use>
                </g>
              </g></svg
          ></span>
        </span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      textSearch: null,
    };
  },

  mounted() {
    setTimeout(() => {
      this.initInput();
    }, 2000);
  },
  methods: {
    go2Home() {
      if (this.$route.path != "main-map") {
        this.$router.push({
          path: "main-map",
        });
      }
    },
    onSearch() {
      if (
        !this.textSearch ||
        !this.textSearch.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
      ) {
        this.$store.dispatch("setMessage", "Please enter coordinates");
      } else {
        this.$emit(
          "onSearch",
          this.textSearch.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        );
      }
    },

    initInput() {
      var vm = this;
      var input = document.getElementById("pac-input");
      var autocomplete = new google.maps.places.Autocomplete(input);
      // Set initial restrict to the greater list of countries. fixed Viet Nam
      autocomplete.setComponentRestrictions({
        country: "vn",
      });

      // Specify only the data fields that are needed.
      autocomplete.setFields([
        "address_components",
        "formatted_address",
        "geometry",
        "icon",
        "name",
      ]);

      autocomplete.addListener("place_changed", function () {
        var place = autocomplete.getPlace();

        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          vm.$store.dispatch("setMessage", "No address for " + place.name);
          return;
        }
        let address = "";
        if (place.address_components) {
          address = place.formatted_address;
          if (address.trim())
            address = place.address_components
              .map((e) => e.long_name)
              .join(" ");
        }
        vm.txtSearch = address;
        vm.$emit("pan2Location", [
          place.geometry.location.lng(),
          place.geometry.location.lat(),
        ]);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  display: flex;
  width: 400px;
  height: 48px;
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  .search-logo {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    width: 2rem; //96px;
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .search-input {
    width: 100%;
    input {
      font-size: 14px;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(1, 2, 3, 0.8);
      padding: 0;
      padding-left: 48px; //108px;
      padding-right: 64px;
      border: 0;
      border-radius: 8px;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      &:active {
        background: #010203;
        outline: 0;
        box-shadow: none;
      }
      &:focus {
        background: #010203;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .search-controls {
    z-index: 1;
    height: 100%;
    display: flex;
    button {
      background: transparent;
      color: white;
      margin: auto 0;
      height: 100%;
      border: none;
      &.btn-clear {
        padding: 0;
      }
      span.button-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
