
const SET_SPINNER_APP = "SET_SPINNER_APP";
const SET_HEIGHT_DOCUMENT = "SET_HEIGHT_DOCUMENT";
const SET_WIDTH_DOCUMENT = "SET_WIDTH_DOCUMENT";
const SET_LANG = "SET_LANG";
const SET_IS_RELOAD = "SET_IS_RELOAD"
const SET_SETTING = "SET_SETTING"
const SET_ISCALLAPSE_MENU = "SET_ISCALLAPSE_MENU"
const SET_MESSAGE = "SET_MESSAGE"
const CLEAR_MESSAGES = "CLEAR_MESSAGES"
const SET_DATA_JSON = "SET_DATA_JSON";

const SET_USER = "SET_USER";
const CLEAR_USER = "CLEAR_USER";
import app from './../../main'
export default {
    state: {
        document:{
            width: 0,
            height: 0,
        },
        spinner: {
            show: false,
        },
        lang: localStorage.getItem("language") ? localStorage.getItem("language")  : "vi",
        isReload:false,
        setting:{},
        isCollapse:true,
        messages:[],
        dataJson:{},
        user:{},
        loggedIn: false,
    },
    getters: {
        getSpinnerApp: state => {
            return state.spinner;
        },
        getDocumentHeight: state => {
            return state.document.height;
        },
        getDocumentWidth: state => {
            return state.document.width;
        },
        getLang:state=>{
            return state.lang
        },
        getIsReload: state =>{
            return state.isReload
        },
        getSetting:state => {
            return state.setting
        },
        getStorages:state=>{
            if(state.setting)
                return state.setting.storages ?  state.setting.storages : []
            else return []
        },
        getDefaultStorage:state=>{
            return state.setting.defaultStorage
        },
        getIsCollapseMenu:state=>{
            return state.isCollapse
        },
        getMessages:state=>{            
            return state.messages
        },
        getDataJson: state => {
            return state.dataJson;
        },
        getUserName(state){
          return state.user && state.user.username ? state.user.username :null
        },
        getUser(state){
          return state.user ? state.user : {}
        },
        loggedIn(state){
          return state.loggedIn;
        },
    },
    mutations: {
        [SET_HEIGHT_DOCUMENT](state, payload) {
            state.document.height = payload;
        },
        [SET_WIDTH_DOCUMENT](state, payload) {
            state.document.width = payload;
        },
        [SET_SPINNER_APP](state, payload) {
            state.spinner.show = payload.show;           
        },
        [SET_LANG] (state, payload) {
            state.lang = payload;
            app.$i18n.locale = payload;
            switch (payload) {
                case "vi":
                    localize("vi", vi);
                    break;
                case "en":
                    localize("en", en);
                    break;
                default:
                    localize("vi", vi);
                    break;
            }
        },
        [SET_IS_RELOAD](state, payload) {
            state.isReload = payload;
        },
        [SET_SETTING](state, payload) {
            state.setting = payload;
        },
        [SET_ISCALLAPSE_MENU](state, payload) {
            state.isCollapse = payload;
        },
        [SET_MESSAGE](state, payload) {
            state.messages.push(payload)
        },
        [CLEAR_MESSAGES](state) {
            state.messages = [];
        },
        [SET_DATA_JSON](state, payload) {
            state.dataJson = payload;
        },
        [SET_USER](state, payload) {
            state.loggedIn = true;
            state.user = payload;
        },
        [CLEAR_USER](state) {
            localStorage.removeItem("data");
            state.loggedIn = false;
            state.user = {};
        },
    },
    actions: {
        setSpinnerApp({ commit }, payload) {
            commit(SET_SPINNER_APP, payload);
        },
        setDocumentHeight({ commit }, payload) {
            commit(SET_HEIGHT_DOCUMENT, payload);
        },
        setDocumentWidth({ commit }, payload) {
            commit(SET_WIDTH_DOCUMENT, payload);
        },
        setLang({commit}, payload) {
            commit(SET_LANG, payload)
        },
        setIsReload({ commit }, payload){
            commit(SET_IS_RELOAD, payload);
        },
        setSetting({ commit }, payload){
            commit(SET_SETTING, payload);
        },
        setIsCollapseMenu({ commit }, payload){
            commit(SET_ISCALLAPSE_MENU, payload);
        },
        setMessage({ commit }, payload){
            commit(SET_MESSAGE, payload);
        },
        clearMessages({ commit }){
            commit(CLEAR_MESSAGES);
        },
        setDataJson({ commit }, payload) {
            commit(SET_DATA_JSON, payload);
        },
        setUser({ commit }, payload) {
            commit(SET_USER,payload);
        },
        clearUser({ commit }) {
            commit(CLEAR_USER);
        },
    }
}