<template>
  <div class="map-view">
    <div
      class="monitoring-map"
      id="openlayerMap"
      :style="[
        documentWidth >= 576
          ? { width: 'calc(100% - 304px)' }
          : { width: 'calc(100% - 56px)' },
      ]"
    ></div>
    <WidgetLayers
      v-show="propButtonsTool.layers.show"
      :propShow="propButtonsTool.layers.show2"
      :map="map"
      @close="closeWidgetLayers"
      ref="widgetLayers"
    />
  </div>
</template>
<script>
var sketch;
import WidgetLayers from "./openlayermap/WidgetLayers";
import Map from "ol/Map";
import View from "ol/View";
import Draw from "ol/interaction/Draw";
import { Vector as VectorSource, TileImage } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";

import { unByKey } from "ol/Observable.js";
import Overlay from "ol/Overlay.js";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon, MultiPoint } from "ol/geom.js";

import Feature from "ol/Feature";
import { Modify, Select } from "ol/interaction.js";
import { GeoJSON } from "ol/format.js";
import Collection from "ol/Collection";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
export default {
  components: {
    WidgetLayers,
  },
  props: {
    propMap: {
      type: Object,
    },
    propView: {
      type: Object,
    },
    propIsDisabledTool: {
      type: Boolean,
      default: false,
    },
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      view: Object,
      draw: Object,
      modify: Object,
      select: Object,
      fillColor: "#fab710",
      fill: new Fill({
        color: "rgba(255,255,255,0.4)",
      }),
      strokeColor: "#3399CC",
      stroke: new Stroke({
        color: "#3399CC",
        width: 1.25,
      }),
    };
  },
  mounted() {
    if (!this.propMap || Object.keys(this.propMap).length === 0) {
      const source = new VectorSource({
        wrapX: false,
      });

      this.layer = new VectorLayer({
        source: source,
        id: "drawLayer",
        style: new Style({
          fill: new Fill({
            color: this.hexToRgbA(this.fillColor, 0.5),
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 2,
          }),
        }),
      });

      this.layer.setZIndex(100);
      let mapPosition = this.$store.getters.getMapPosition;
      this.view = new View({
        center:
          mapPosition && mapPosition.center
            ? mapPosition.center
            : fromLonLat([108.22779930115105, 16.062106484745996]),
        zoom: mapPosition && mapPosition.zoom ? mapPosition.zoom : 10,
        minZoom: 1,
        maxZoom: 20,
      });
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({ doubleClickZoom: false }),
        layers: [
          // new TileLayer({
          //   title: "Satellite",
          //   id: "satellitemap",
          //   source: new TileImage({
          //     url: process.env.VUE_APP_BASEMAP_URL,
          //   }),
          //   show: true,
          // }),
          this.layer,
        ],
        target: "openlayerMap",
        view: this.view,
      });
      this.$emit("initMapSuccess", this.map);
      setTimeout(() => {
        this.$store.dispatch("setMapPosition", {
          center: null,
          zoom: null,
        });
      }, 500);
    }
    this.drawField();
  },
  watch: {
    propIsDisabledTool(val) {
      if (val) {
        this.clearModify();
      } else {
        if (this.map && Object.keys(this.map).length > 0) this.drawField();
      }
    },
  },
  methods: {
    addLayer2Widget(layer) {
      if (this.$refs.widgetLayers)
        this.$refs.widgetLayers.addLayer2Widget(layer);
    },
    closeWidgetLayers() {
      this.propButtonsTool.layers.show2 = false;
      setTimeout(() => {
        this.propButtonsTool.layers.show = false;
      }, 1000);
    },
    setGeometry(data) {
      let feature = this.layer.getSource().getFeatureById(data.id);
      if (feature && Object.keys(feature).length > 0)
        feature.setGeometry(data.geometry);
    },
    onOffTool(val) {
      if (val) {
      } else {
      }
    },
    drawField() {
      if (Object.keys(this.draw).length > 0) {
        this.map.removeInteraction(this.draw);
      }
      this.clearModify();
      let id =
        new Date().getTime() -
        Math.floor(Math.random() * 999999999) +
        Math.floor(Math.random() * 888888888);
      var vm = this;
      this.draw = new Draw({
        source: vm.layer.getSource(),
        type: "Polygon",
      });
      this.map.addInteraction(this.draw);

      let listener;
      this.draw.on("drawstart", function (evt) {
        sketch = evt.feature;
        listener = sketch.getGeometry().on("change", function (evt) {});
      });

      this.draw.on("drawend", function (event) {
        event.feature.set("id", id);
        event.feature.id = id;
        event.feature.id_ = id;

        vm.$emit("drawEnd", event.feature);
        unByKey(listener); // unset sketch
        sketch = null;
        vm.map.removeInteraction(vm.draw);
        setTimeout(() => {
          vm.modifyFeature(id);
        }, 20);
      });
    },
    clearModify() {
      if (Object.keys(this.modify).length > 0) {
        this.map.removeInteraction(this.modify);
        this.modify = Object;
      }
      if (Object.keys(this.select).length > 0) {
        this.map.removeInteraction(this.select);
        this.select = Object;
      }
    },
    editFeature(id) {
      this.clearModify();
      this.modifyFeature(id);
    },
    modifyFeature(id) {
      var vm = this;
      if (!this.select || Object.keys(this.select).length == 0) {
        let feature = this.layer.getSource().getFeatureById(id);
        this.select = new Select({
          features: feature
            ? new Collection([feature])
            : this.layer.getSource().getFeatures(),

          style: [
            new Style({
              image: new Circle({
                radius: 4,
                fill: new Fill({
                  color: vm.hexToRgbA("#fff", 1),
                }),
                stroke: new Stroke({
                  color: vm.hexToRgbA("#FFBF23", 1),
                  width: 4,
                }),
              }),
              geometry: function (feature) {
                var coordinates = feature.getGeometry().getCoordinates()[0];
                return new MultiPoint(coordinates);
              },
            }),
            new Style({
              fill: new Fill({
                color: vm.hexToRgbA(vm.fillColor, 0.5),
              }),
              stroke: new Stroke({
                color: "#FFBF23",
                width: 2,
                lineDash: [5, 10],
              }),
            }),
          ],
        });
        this.map.addInteraction(this.select);
      }
      if (!this.modify || Object.keys(this.modify).length == 0) {
        vm.modify = new Modify({
          features: vm.select.getFeatures(),
        });
        vm.map.addInteraction(vm.modify);
        this.modify.on("modifystart", function (evt) {});

        this.modify.on("modifyend", function (event) {
          vm.$emit("modifyEnd", event.features.array_);
        });
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    removeFeature(id) {
      let feature = this.layer.getSource().getFeatureById(id);
      if (feature && Object.keys(feature).length > 0)
        this.layer.getSource().removeFeature(feature);
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
  beforeDestroy() {
    if (Object.keys(this.modify).length > 0) {
      this.map.removeInteraction(this.modify);
      this.modify = Object;
    }
    if (Object.keys(this.select).length > 0) {
      this.map.removeInteraction(this.select);
      this.select = Object;
    }
  },
};
</script>
<style lang="scss">
.map-view {
  position: relative;
  height: 100vh;
}
.monitoring-map {
  position: absolute;
  height: 100%;
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: #ffcc33;
  }
}
</style>
