<template>
  <div class="scenes"></div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.scenes {
  background: #111418;
  height: 50px;
  width: 100%;
}
</style>