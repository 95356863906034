<template>
  <div class="monitoring-analytic-field-page" slot="main-content">
    <MonitoringAnalyticFieldPage />
  </div>
</template>
<script>
import MonitoringAnalyticFieldPage from "@/components/pages/monitoring/detail/AnalyticField";
export default {
  components: {
    MonitoringAnalyticFieldPage,
  },
};
</script>