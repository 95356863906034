<template>
  <div class="outer">
    <div class="middle fadeInDown">
      <div class="card card-login mx-auto">
        <div class="card-header mx-auto text-center">
          <span>
            <img src="/img/logo.png" alt="Logo" />
          </span>
        </div>
        <div class="card-body">
          <div class="notification text-danger" v-if="message">
            {{ message }}
          </div>
          <form v-on:keyup.enter="handleLogin">
            <div class="form-group">
              <div class="form-group__username">
                <label class="w-100">{{ $t("label_username") }}</label>
                <input
                  type="text"
                  v-model="username"
                  name="username"
                  class="form-control"
                  autocomplete="off"
                  autofocus
                  :title="
                    errors && errors.username && errors.username.message
                      ? errors.username.message
                      : null
                  "
                  :class="
                    errors && errors.username && errors.username.message
                      ? 'p-invalid'
                      : ''
                  "
                />
              </div>
            </div>
            <div class="form-group">
              <div class="form-group__password">
                <label class="w-100"> {{ $t("label_password") }} </label>
                <input
                  type="password"
                  v-model="password"
                  name="password"
                  class="form-control"
                  autocomplete="off"
                  :title="
                    errors && errors.password && errors.password.message
                      ? errors.password.message
                      : null
                  "
                  :class="
                    errors && errors.password && errors.password.message
                      ? 'p-invalid'
                      : ''
                  "
                />
              </div>
            </div>
            <div class="form-group">
              <button
                :disabled="loading"
                type="button"
                name="btn"
                class="btn w-100 btn-login"
                @click="handleLogin"
              >
                <i class="pi pi-spin pi-spinner" v-if="loading"></i>&nbsp;
                {{ $t("button_text_login") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userName, passWord } from "@/utils/commons/environment";
export default {
  components: {},
  data() {
    return {
      username: null,
      password: null,
      message: null,
      rememberMe: false,
      loading: false,
      errors: {
        username: {
          message: null,
        },
        password: {
          message: null,
        },
      },
    };
  },
  async created() {},
  methods: {
    async checkLogin(username, password) {
      let users = process.env.VUE_APP_USERS;
      return new Promise(async (resolve, reject) => {
        if (users) {
          let array = users.split(",");
          if (!array || array.length === 0) {
            resolve(false);
          } else {
            resolve(
              array.findIndex((x) => x === username + ":" + password) >= 0
            );
          }
        } else resolve(false);
      });
    },
    handleLogin: async function () {
      this.message = null;
      if (
        !this.username ||
        !this.username.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
      ) {
        this.errors.username.message = this.$t("message_username_is_required");
      } else {
        this.errors.username.message = null;
      }
      if (
        !this.password ||
        !this.password.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
      ) {
        this.errors.password.message = this.$t("message_password_is_required");
      } else {
        this.errors.password.message = null;
      }
      if (
        [this.errors.username.message, this.errors.password.message].filter(
          (x) => x
        ).length > 0
      ) {
        this.message = [
          this.errors.username.message,
          this.errors.password.message,
        ]
          .filter((x) => x)
          .join(", ");
        return;
      }

      this.loading = true;

      try {
        if (
          (this.username === "sysadmin" && this.password === "Vega1234@#$") ||
          (this.username === "demo" && this.password === "demo@123")
        ) {
          this.$store.dispatch("setUser", {
            username: this.username,
          });
          await localStorage.setItem(
            "data",
            JSON.stringify({
              time: new Date().getTime(),
              logged: true,
            })
          );
          let path = "";
          const urlParams = new URLSearchParams(window.location.search);
          path = urlParams.get("next");
          if (path) {
            try {
              path = await atob(path);
            } catch (error) {
              path = "/";
            }
            let resolved = this.$router.resolve(path);
            if (resolved.route.name || resolved.route.name != "404") {
              if (path != "/login") {
                this.$router.push({
                  path: path,
                });
              } else {
                this.$router.push({
                  path: "/",
                });
              }
            } else {
              this.$router.push({
                path: "/",
              });
            }
          } else {
            this.$router.push({
              path: "/",
            });
          }
        } else {
          this.message = this.$t(
            "messsage_username_or_password_is_not_correct"
          );
        }
      } catch (error) {
        this.message = this.$t("messsage_username_or_password_is_not_correct");
      }
      this.loading = false;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
$dark: #343a40;
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1080;
  background: rgba(0, 0, 0, 0.75);
  background-image: url(/img/background.jpg);
  background-repeat: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  input,
  button,
  span,
  p {
    font-size: 14px;
  }
}

.middle {
  display: table-cell;
  vertical-align: middle;
}
.card {
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
    0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);
  border-radius: 0.5rem;
}
.card-login {
  padding: 1rem;
  max-width: 26rem;
  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 14px;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0.3rem 0.45rem;
    border-radius: 50%;
    background: #dde1ec;
    &:hover {
      cursor: pointer;
    }
  }
}

.card-header {
  color: #fff;
  font-size: 20px;
  border-bottom: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding: 0;
  .title {
    font-size: 18px;
    color: #3e64ff;
    margin-top: 0.75rem;
  }
  img {
    width: 200px;
    height: 200px;
    &:hover {
      cursor: pointer;
    }
  }
}
.card-footer {
  background-color: transparent;
}
.card-body {
  padding: 0;
  padding-top: 0rem;
  .notification {
    border: 1px solid rgba(255, 0, 0, 0.15);
    background-color: rgba(255, 0, 0, 0.1);
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .form-group {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .form-group__username {
    position: relative;
    label {
      margin-bottom: 0.375rem;
      color: #3f4254;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .form-group__password {
    position: relative;
    label {
      margin-bottom: 0.375rem;
      color: #3c4858;
    }
  }
  .form-row {
    margin-bottom: 1rem;
    .form-check-input {
      margin-top: 0.125rem;
    }
    .forget-pass {
      color: $background-color;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        color: #007bff;
      }
    }
  }
  .btn-login {
    border-radius: 0.25rem;
    font-size: 14px;
    color: white;
    background: #3e64ff;
  }
}
input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}
form {
  color: $dark;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.remember-me-label {
  &:hover {
    cursor: pointer;
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
<style lang="scss">
.card-login {
  .p-checkbox-box {
    border: 1px solid #ced4da !important;
  }
}
</style>
