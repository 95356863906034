import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import common from "./modules/common"
import field from "./modules/field"
import map from "./modules/map"


export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,field,map
  }
})
