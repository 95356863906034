var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monitoring-content"},[_c('OpenlayerMap',{ref:"openlayerMap",attrs:{"propIsDisabledTool":_vm.isDisabledTool,"prop-buttons-tool":_vm.buttonsTool},on:{"initMapSuccess":_vm.initMapSuccess,"drawEnd":_vm.drawEnd,"modifyEnd":_vm.modifyEnd}}),_c('div',{staticClass:"top-side"},[_c('SearchBox',{on:{"onSearch":_vm.onSearch,"pan2Location":_vm.onPan2Location}})],1),_c('div',{staticClass:"bottom-side",style:([
      _vm.isShowRightSide && !_vm.isSmallDevice
        ? {
            left:
              (_vm.documentWidth - 304 - (_vm.isCollapseMenu ? 56 : 224) - 180) / 2 +
              'px',
          }
        : { left: 0 } ])},[(
        _vm.fields.length > 0 &&
        _vm.fields.filter(function (x) { return x.area > 10000 * 10000; }).length === 0
      )?_c('BottomSide',{attrs:{"propIsDisabledTool":_vm.isDisabledTool},on:{"onOffTool":_vm.onOffTool}}):_vm._e()],1),_c('div',{staticClass:"right-side"},[_c('RightSide',{ref:"rightSide",attrs:{"prop-map":_vm.map,"prop-fields":_vm.fields,"propFieldEditIds":_vm.fieldEditIds},on:{"saveFields":_vm.showDialogSaveFields,"cancelDrawing":_vm.cancelDrawing,"removeField":_vm.removeField,"editField":_vm.onEditField}})],1),_c('div',{staticClass:"left-side",style:({ top: (_vm.documentHeight - 3 * 126) / 2 + 'px' })},[_c('LeftSide',{attrs:{"prop-buttons-tool":_vm.buttonsTool},on:{"leftActionClick":_vm.leftActionClick}})],1),_c('div',{staticClass:"monitoring-dialog"},[(_vm.dialog.editField.show)?_c('EditFieldDialog',{ref:"editFieldDialog",attrs:{"prop-show":_vm.dialog.editField.show,"prop-field":_vm.dialog.editField.value},on:{"showSaveChangeQuestionDialog":function($event){_vm.dialog.saveChangeQuestion.show = true},"saveChangeField":_vm.saveChangeField,"removeField":_vm.removeField,"close":_vm.closeEditFieldDialog}}):_vm._e(),(_vm.dialog.saveChangeQuestion.show)?_c('SaveChangeQuestionDialog',{attrs:{"prop-show":_vm.dialog.saveChangeQuestion.show},on:{"close":_vm.closeSaveChangeQuestion,"saveChangeField":_vm.saveChangeFieldAfterQuestion}}):_vm._e(),(_vm.dialog.saveFields.show)?_c('SaveFieldsDialog',{attrs:{"prop-show":_vm.dialog.saveFields.show,"prop-fields":_vm.fields},on:{"showSaveFieldsQuestionDialog":function($event){_vm.dialog.saveFieldsQuestion.show = true},"saveChangeFields":_vm.saveChangeFields,"onClose":_vm.closeSaveFieldsDialog}}):_vm._e(),(_vm.dialog.saveFieldsQuestion.show)?_c('SaveFieldsQuestionDialog',{attrs:{"prop-show":_vm.dialog.saveFieldsQuestion.show},on:{"saveFields":_vm.onSaveFields,"removeFields":_vm.removeFields,"close":function($event){_vm.dialog.saveFieldsQuestion.show = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }