<template>
  <div class="monitoring-upload-fields-page" slot="main-content">
    <MonitoringUploadFieldsPage />
  </div>
</template>
<script>
import MonitoringUploadFieldsPage from "@/components/pages/monitoring/upload/UploadFields";
export default {
  components: {
    MonitoringUploadFieldsPage,
  },
};
</script>