<template>
  <div class="field">
    <div class="field-preview"><div :id="propField.id"></div></div>
    <div class="field-info">
      <div class="field-title">
        <span class="field-name">{{
          propField && propField.name ? propField.name : null
        }}</span>
      </div>
      <div class="field-metadata d-flex flex-column">
        <span class="field-area" title="Area">{{ areaFormat }}</span>
        <span
          class="
            field-location
            d-flex
            justify-content-between
            align-items-center
          "
          title="Centre point"
        >
          <span>
            {{
              propField && propField.coordinate
                ? toStringHDMS(propField.coordinate)
                : null
            }}
          </span>
          <button
            class="btn"
            v-if="propField && propField.coordinate"
            @click="pan2Location(propField.coordinate)"
          >
            <span class="button-icon">
              <span
                ><svg
                  data-v-4c4c545a=""
                  width="100%"
                  height="100%"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    data-v-4c4c545a=""
                    d="M6 3.81817C4.79454 3.81817 3.81819 4.79454 3.81819 5.99997C3.81819 7.20541 4.79454 8.18181 6 8.18181C7.20546 8.18181 8.18181 7.20544 8.18181 6C8.18181 4.79456 7.20544 3.81817 6 3.81817ZM10.8764 5.45453C10.6254 3.17999 8.81999 1.37454 6.54544 1.12364V0H5.45453V1.12364C3.17999 1.37454 1.37454 3.17999 1.12364 5.45453H0V6.54544H1.12364C1.37454 8.81999 3.18001 10.6254 5.45455 10.8764V12H6.54544V10.8764C8.81999 10.6254 10.6254 8.81999 10.8764 6.54544H12V5.45453H10.8764ZM6 9.81817C3.89183 9.81817 2.18183 8.10817 2.18183 6C2.18183 3.89183 3.89183 2.18183 6 2.18183C8.10817 2.18183 9.81817 3.8918 9.81817 6C9.81817 8.1082 8.10817 9.81817 6 9.81817Z"
                    fill="currentColor"
                  ></path></svg
              ></span>
            </span>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { toStringHDMS } from "ol/coordinate";
import { fromLonLat, transform } from "ol/proj";
export default {
  props: {
    propMap: {
      type: Object,
    },
    propField: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {
    propMap(val) {},
  },
  async mounted() {
    if (this.propField && Object.keys(this.propField).length > 0) {
      this.initImagePreview(
        this.propField.coordinates ? this.propField.coordinates : []
      );
    }
  },
  methods: {
    pan2Location(coordinate) {
      let zoomLevel = this.propMap.getView().getZoom();
      this.propMap.getView().animate({
        center: coordinate,
        duration: 2000,
        zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
      });
    },
    toStringHDMS(coordinate) {
      return toStringHDMS(transform(coordinate, "EPSG:3857", "EPSG:4326"));
    },
    async initImagePreview(coordinates) {
      // Tinh diem toa do man hinh
      //let points = [];
      // for (let i = 0; i < coordinates.length; i++) {
      //   var pixel = this.propMap.getPixelFromCoordinate(
      //     transform(coordinates[i], "EPSG:4326", "EPSG:3857")
      //   );
      //   points.push(pixel);
      // }
      var lng = coordinates.map(function (p) {
        return p[0];
      });
      var lat = coordinates.map(function (p) {
        return p[1];
      });
      var min_coords = {
        lat: Math.min.apply(null, lat),
        lng: Math.min.apply(null, lng),
      };
      var max_coords = {
        lat: Math.max.apply(null, lat),
        lng: Math.max.apply(null, lng),
      };

      let distanceXMinMax = max_coords.lng - min_coords.lng; //  Math.abs(max_coords.lng) - Math.abs(min_coords.lng);
      let distanceYMinMax = max_coords.lat - min_coords.lat; // Math.abs(max_coords.lat) - Math.abs(min_coords.lat);
      distanceXMinMax =
        distanceXMinMax < distanceXMinMax
          ? Math.abs(distanceXMinMax)
          : distanceXMinMax;
      distanceYMinMax =
        distanceYMinMax < 0 ? Math.abs(distanceYMinMax) : distanceYMinMax;
      let scaleYX = distanceYMinMax / distanceXMinMax; // ty le do dai map y/ map x
      if (scaleYX > 1) scaleYX = 1; // neu > 1 huy co da~n theo ty le
      let newCoors = [],
        locatations = [],
        ymax = 0;

      for (let i = 0; i < coordinates.length; i++) {
        let x = null,
          y = null;
        let dx = coordinates[i][0] - min_coords.lng; // Math.abs(coordinates[i][0]) - Math.abs(min_coords.lng);
        dx = dx < 0 ? Math.abs(dx) : dx;
        x = (dx / distanceXMinMax) * 64;
        let dy = coordinates[i][1] - min_coords.lat; // Math.abs(coordinates[i][1]) - Math.abs(min_coords.lat);
        dy = dy < 0 ? Math.abs(dy) : dy;
        if ((dy / distanceYMinMax) * 64 * scaleYX > ymax)
          ymax = (dy / distanceYMinMax) * 64 * scaleYX;
        y = 64 - (dy / distanceYMinMax) * 64 * scaleYX; // truc y do thi nguoc voi truc y man hinh

        newCoors.push([x, y]);
      }
      for (let i = 0; i < newCoors.length; i++) {
        newCoors[i][1] = newCoors[i][1] - Math.abs((64 - ymax) / 2);
        locatations.push(newCoors[i].join(","));
      }
      var svg = await this.createSVG(this.propField.id);
      var poly = this.drawPoly(locatations.join(" "), "", "#fff");
      svg.appendChild(poly);
    },
    createSVG(id) {
      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
      //svg.setAttribute("version", "1.1");
      svg.setAttribute("id", "svg" + id); //id for reffering your svg
      var canvas = document.getElementById(id); //id of your container element
      let width = canvas.getBoundingClientRect().width;
      let height = canvas.getBoundingClientRect().height;
      width = width ? width : "64";
      height = height ? height : "64";
      svg.setAttribute("height", height);
      svg.setAttribute("width", width);
      canvas.appendChild(svg);
      return svg;
    },
    drawPoly(points, fill, stroke) {
      var poly = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "polygon"
      );
      poly.setAttribute("id", "polygon" + this.propField.id);
      poly.setAttribute("points", points);
      poly.setAttribute("stroke", this.propField.color);
      poly.setAttribute("fill", this.propField.color);
      //svg.appendChild(poly); //where svg is referring to your svg element
      return poly;
    },
  },
  computed: {
    areaFormat() {
      let result = "";
      if (this.propField && this.propField.area) {
        if (parseFloat(this.propField.area) / 10000 < 0.5)
          result = this.propField.area + " m²";
        else
          result = (parseFloat(this.propField.area) / 10000).toFixed(2) + " ha";
      }
      return result ? result : this.propField.area + " m²";
    },
  },
};
</script>
<style lang="scss" scoped>
.field {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: white;
  letter-spacing: 0.4px;
  width: 100%;
  .field-preview {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    flex-shrink: 0;
    svg {
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
      width: 64px;
      height: 64px;
    }
  }
  .field-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    .field-title {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: white;
      letter-spacing: 0.25px;
    }
    .field-metadata {
      margin-top: 0.125rem;
      width: 100%;
      .field-area {
        color: #8799ab;
      }
      .field-location {
        color: #8799ab;
        button {
          color: #8296aa;
          border: 0;
          padding: 0;
          span.button-icon {
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>