<template>
  <div
    :style="[
      documentWidth >= 576
        ? { width: 'calc(100% - 304px)' }
        : { width: 'calc(100% - 56px)' },
    ]"
    class="map-view"
  >
    <div class="analytic-map" id="analyticMap"></div>

    <WidgetLayers
      v-show="propWidget.layers.show"
      :propShow="propWidget.layers.show2"
      :map="map"
      @close="closeWidgetLayers"
      ref="widgetLayers"
    />
    <div
      class="analytic-legend"
      v-if="propImageDays && propImageDays.length > 0"
    >
      <Legend
        :propBand="band"
        :prop-contrast="contrast"
        :prop-legends="
          dataND && dataND.classification && dataND.classification.value
        "
        :prop-area="featureArea"
        @selectBandChange="bandChanged"
        @contrastChange="contrastChanged"
        @onDownload="onDownloadImageTiff"
      />
    </div>
    <div
      class="analytic-times-scroll"
      v-if="propImageDays && propImageDays.length > 0"
    >
      <TimesScroll
        :propImageDays="propImageDays"
        @selectIdChange="selectIdChange"
      />
    </div>
  </div>
</template>
<script>
var sketch;
import { Splitpanes, Pane } from "splitpanes";
import Legend from "./analyticmap/Legend.vue";
import TimesScroll from "./analyticmap/TimesScroll.vue";
import WidgetLayers from "./analyticmap/WidgetLayers";

import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import { OSM, Vector as VectorSource, TileImage, ImageCanvas } from "ol/source";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { toLonLat, fromLonLat, transform } from "ol/proj";
import { Polygon } from "ol/geom.js";
import Overlay from "ol/Overlay";

import Feature from "ol/Feature";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import imageFunc from "@/utils/middleware/image";
import { change_alias, string_all_trim } from "@/utils/commons/common";
import legendsCommon from "@/utils/commons/legends";
export default {
  components: { Legend, TimesScroll, WidgetLayers, Splitpanes, Pane },
  props: {
    propMap: {
      type: Object,
    },
    propView: {
      type: Object,
    },
    propImageDays: {
      type: Array,
      default: () => [],
    },
    propWidget: {
      type: Object,
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      view: Object,
      layerND: Object,
      layerRGB: Object,
      fillColor: "#fab710",
      fill: new Fill({
        color: "rgba(255,255,255,0.4)",
      }),
      strokeColor: "#3399CC",
      stroke: new Stroke({
        color: "#3399CC",
        width: 1.25,
      }),
      measureTooltipElement: null,
      measureTooltip: null,
      contrast: {
        positive: false,
        negative: false,
      },
      band: "NDVI",
      oldBand: "NDVI",
      imageDayId: null,
      imageDayIdOld: null,
      dataND: null,
      coordinates: [],
      blob: null,
      geotiff: null,
      geotiffImage: null,
      featureArea: 0,
      field: null,
      hidePane2: false,

      legends: legendsCommon.ndvi,
    };
  },
  created() {},
  mounted() {
    if (!this.propMap || Object.keys(this.propMap).length === 0) {
      const source = new VectorSource({
        wrapX: false,
      });
      var vm = this;
      this.layer = new VectorLayer({
        source: source,
        id: "drawLayer",
        style: new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 2,
          }),
        }),
      });
      this.layer.setZIndex(100);

      this.layerND = new TileLayer({
        title: "Layer ND",
        id: "layernd",
        source: new XYZ({
          url: null,
        }),
        show: false,
        visible: false,
      });
      this.layerND.setZIndex(101);
      this.layerND.setVisible(false);
      this.layerRGB = new TileLayer({
        title: "LayerRGB",
        id: "layerRGB",
        source: new XYZ({
          url: null,
        }),
        show: false,
        visible: false,
      });
      this.layerRGB.setZIndex(102);
      this.layerRGB.setVisible(false);

      this.view = new View({
        center: fromLonLat([108.22779930115105, 16.062106484745996]),
        zoom: 10,
        minZoom: 1,
        maxZoom: 20,
      });
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({ doubleClickZoom: false }),
        layers: [this.layer, this.layerND, this.layerRGB],
        target: "analyticMap",
        view: this.view,
      });
      this.map.on("pointermove", pointerMove);
      function pointerMove(evt) {
        if (evt.dragging) {
          return;
        }
        var pixel = vm.map.getEventPixel(evt.originalEvent);
        var hit = false;
        try {
          hit = vm.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
            if (layer.get("id") === "drawLayer") return true;
            else return false;
          });
        } catch (error) {}
        vm.map.getTargetElement().style.cursor = hit ? "pointer" : "";
        if (hit) {
          vm.pointerMove({
            evt: evt,
          });
        } else {
          vm.clearMeasureTooltip();
        }
      }
      this.createMeasureTooltip();
      this.$emit("initMapSuccess", this.map);
    }
    setTimeout(() => {
      this.map.updateSize();
    }, 150);
  },
  watch: {
    imageDayId(newVal, oldVal) {},
  },
  methods: {
    addLayer2Widget(layer) {
      if (this.$refs.widgetLayers)
        this.$refs.widgetLayers.addLayer2Widget(layer);
    },
    onresize(e) {},
    onresized(e) {},
    closeWidgetLayers() {
      this.propWidget.layers.show2 = false;
      setTimeout(() => {
        this.propWidget.layers.show = false;
      }, 1000);
    },
    onDownloadImageTiff() {
      if (this.blob && this.blob.size > 0) {
        this.$store.dispatch("setSpinnerApp", { show: true });
        const link = document.createElement("a");
        let url = URL.createObjectURL(this.blob);
        link.href = url;
        link.setAttribute(
          "download",
          this.field &&
            Object.keys(this.field).length > 0 &&
            this.field.name &&
            this.field.name.replace(/\s+/g, " ").trim()
            ? string_all_trim(
                change_alias(this.field.name.replace(/\s+/g, " ").trim())
              ) + ".tif"
            : "download.tif"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.$store.dispatch("setSpinnerApp", { show: false });
      } else {
        this.$store.dispatch("setMessage", "No image to dowwnload");
      }
    },
    contrastChanged() {},
    async bandChanged(val) {
      if (val === this.oldBand && this.band === "NATURE") {
        this.band = val;
        if (this.layerRGB && Object.keys(this.layerRGB).length > 0) {
          this.layerRGB.setVisible(false);
        }
        if (this.layerND && Object.keys(this.layerND).length > 0) {
          this.layerND.setVisible(true);
        }
      } else {
        this.band = val;
        await this.getInfo();
      }
    },
    async selectIdChange(id) {
      this.imageDayId = id;
      await this.getInfo();
      this.imageDayIdOld = id;
    },
    showData() {
      if (this.dataND && Object.keys(this.dataND).length > 0) {
        if (
          this.dataND.nd_map &&
          this.dataND.nd_map.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        ) {
          if (this.layerND && Object.keys(this.layerND).length > 0) {
            this.layerND
              .getSource()
              .setUrl(
                this.dataND.nd_map
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              );
          }
        } else if (
          this.dataND.map &&
          this.dataND.map.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        ) {
          if (this.layerND && Object.keys(this.layerND).length > 0) {
            this.layerND
              .getSource()
              .setUrl(
                this.dataND.map
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              );
          } else {
            this.layerND.getSource().setUrl(null);
          }
        }

        if (
          this.dataND.rbg_map &&
          this.dataND.rbg_map.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        ) {
          if (this.layerRGB && Object.keys(this.layerRGB).length > 0) {
            this.layerRGB
              .getSource()
              .setUrl(
                this.dataND.rbg_map
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              );
          }
        }
        setTimeout(() => {
          this.layerND.getSource().refresh();
          this.layerRGB.getSource().refresh();
        }, 100);
      }
    },
    async getInfo() {
      if (
        ["NATURE"].includes(this.band) &&
        this.imageDayId === this.imageDayIdOld
      ) {
        if (this.layerRGB && Object.keys(this.layerRGB).length > 0) {
          this.layerRGB.setVisible(true);
        }
        if (this.layerND && Object.keys(this.layerND).length > 0) {
          this.layerND.setVisible(false);
        }
      } else {
        if (this.band != "NATURE") this.oldBand = this.band;
        this.dataND = null;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        if (this.layerND && Object.keys(this.layerND).length > 0) {
          this.layerND.setVisible(false);
        }
        if (this.layerRGB && Object.keys(this.layerRGB).length > 0) {
          this.layerRGB.setVisible(false);
        }
        this.blob = null;
        this.geotiff = null;
        this.geotiffImage = null;
        switch (this.band === "NATURE" ? this.oldBand : this.band) {
          case "NDVI":
            this.legends = legendsCommon.ndvi;
            await this.getNDVI([this.coordinates]);
            break;
          case "NDMI":
            this.legends = legendsCommon.ndvi;
            await this.getNDMI([this.coordinates]);
            break;
          case "NDWI":
            this.legends = legendsCommon.ndvi;
            await this.getNDWI([this.coordinates]);
            break;
          case "MNDWI":
            this.legends = legendsCommon.ndvi;
            await this.getMNDWI([this.coordinates]);
            break;
          case "NDBI":
            this.legends = legendsCommon.ndvi;
            await this.getNDBI([this.coordinates]);
            break;
          case "SAVI":
            this.legends = legendsCommon.ndvi;
            await this.getSAVI([this.coordinates]);
            break;
          case "FDI":
            this.legends = legendsCommon.ndvi;
            await this.getFDI([this.coordinates]);
            break;
          case "WILD FIRES":
            this.legends = legendsCommon.ndvi;
            await this.getVisual([this.coordinates]);
            break;
        }
        if (this.dataND && Object.keys(this.dataND).length > 0) {
          await this.showData();
          this.initLegends();
          if (this.layerRGB && Object.keys(this.layerRGB).length > 0) {
            this.layerRGB.setVisible(this.band === "NATURE");
          }
          if (this.layerND && Object.keys(this.layerND).length > 0) {
            this.layerND.setVisible(this.band != "NATURE");
          }
        }
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async getNDVI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDVI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getNDMI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDMI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDMI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getNDWI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDWI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getMNDWI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getMNDWI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getNDBI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDBI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getSAVI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getSAVI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getFDI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getFDI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getVisual(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getVisual({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            min: 0,
            max: 3000,
            bands: ["B12", "B8", "B5"],
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getDataFromImage(point) {
      return new Promise(async (resolve, reject) => {
        try {
          const bbox = this.geotiffImage.getBoundingBox();
          const pixelWidth = this.geotiffImage.getWidth();
          const pixelHeight = this.geotiffImage.getHeight();
          const bboxWidth = bbox[2] - bbox[0];
          const bboxHeight = bbox[3] - bbox[1];

          const widthPct = (point[0] - bbox[0]) / bboxWidth;
          const heightPct = (point[1] - bbox[1]) / bboxHeight;
          const xPx = Math.floor(pixelWidth * widthPct);
          const yPx = Math.floor(pixelHeight * (1 - heightPct));
          const window = [xPx, yPx, xPx + 1, yPx + 1];
          const data = await this.geotiffImage.readRasters({ window });
          resolve(data);
        } catch (error) {
          resolve(null);
        }
      });
    },
    async pointerMove(data) {
      var vm = this;
      if (vm.band != "WILD FIRES" && vm.band != "NATURE") {
        if (!vm.measureTooltipElement) this.createMeasureTooltip();
        vm.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";

        let d = await this.getDataFromImage(
          transform(data.evt.coordinate, "EPSG:3857", "EPSG:4326")
        );
        if (d && d.length > 0 && d[0].length > 0) {
          // var quotient = Math.floor(parseFloat(d[0][0]));
          // var remainder = parseFloat(d[0][0]) - quotient;
          // remainder = (remainder * 1000 + "").substring(0, 2);
          if (parseFloat(d[0][0]) < 0) {
            let val = (parseFloat(d[0][0]) + "").substring(0, 5);
            if (val === "-0.0" || val === "-0.00") val = "0.00";
            vm.measureTooltipElement.innerHTML = val;
            //    vm.measureTooltipElement.innerHTML = (
            //   parseFloat(d[0][0]) + ""
            // ).substring(0, 5);
          } else {
            if (vm.measureTooltipElement)
              vm.measureTooltipElement.innerHTML = (
                parseFloat(d[0][0]) + ""
              ).substring(0, 4);
          }
        } else {
          if (vm.measureTooltipElement)
            vm.measureTooltipElement.innerHTML = "NaN";
        }
        vm.measureTooltip.setPosition(data.evt.coordinate);
      } else {
        if (this.measureTooltipElement) {
          vm.clearMeasureTooltip();
        }
      }
    },

    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
      }
      this.measureTooltipElement = document.createElement("div");
      this.measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [1, -16],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
        id: this.mapId,
      });
      this.map.addOverlay(this.measureTooltip);
    },
    clearMeasureTooltip() {
      this.measureTooltipElement = null;
      if (this.measureTooltip) this.map.removeOverlay(this.measureTooltip);
      if (this.layerPointMove) {
        this.layerPointMove.getSource().clear();
      }
    },
    drawFields(fields) {
      if (fields && fields.length > 0) {
        for (let i = 0; i < fields.length; i++) {
          if (fields[i].coordinates && fields[i].coordinates.length > 0) {
            let coors = [];
            this.coordinates = fields[i].coordinates;
            for (let j = 0; j < fields[i].coordinates.length; j++) {
              coors.push(
                transform(fields[i].coordinates[j], "EPSG:4326", "EPSG:3857")
              );
            }
            let id =
              new Date().getTime() -
              Math.floor(Math.random() * 999999999) +
              Math.floor(Math.random() * 888888888);
            let feature = new Feature({
              geometry: new Polygon([coors]),
              id: fields[i].id,
            });
            feature.id_ = fields[i].id;
            feature.setId(fields[i].id);
            let extent = feature.getGeometry().getExtent();

            this.view.fit(extent);
            this.layer.getSource().addFeature(feature);
            this.featureArea = fields[i].area;
          }
        }
      }
    },
    updateField(field) {
      if (field && Object.keys(field).length > 0) {
        let points = [];
        for (let i = 0; i < field.coordinates.length; i++) {
          points.push(
            transform(field.coordinates[i], "EPSG:4326", "EPSG:3857")
          );
        }
        let feature = this.layer.getSource().getFeatureById(field.id);
        if (feature) feature.setGeometry(new Polygon([points]));
        else {
          feature = new Feature({
            geometry: new Polygon([coors]),
            id: field.id,
          });
          feature.id_ = field.id;
          feature.setId(field.id);
        }
        let extent = feature.getGeometry().getExtent();
        setTimeout(() => {
          this.view.fit(extent);
        }, 100);
        this.layer.getSource().addFeature(feature);
        this.featureArea = field.area;
        this.coordinates = field.coordinates;
        this.getInfo();
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    removeFeature(id) {
      let feature = this.layer.getSource().getFeatureById(id);
      if (feature && Object.keys(feature).length > 0)
        this.layer.getSource().removeFeature(feature);
    },
    async initLegends() {
      for (let i = 0; i < this.legends.length; i++) {
        this.legends[i].area = 0;
        this.legends[i].pixels = 0;
        this.legends[i].percent = 0;
      }
      if (this.geotiffImage) {
        const pixelWidth = this.geotiffImage.getWidth();
        const pixelHeight = this.geotiffImage.getHeight();
        return;
        for (let i = 0; i < pixelWidth; i++) {
          for (let j = 0; j < pixelHeight; j++) {
            const window = [i, j, i + 1, j + 1];
            // const data = await this.geotiffImage.readRasters({ window });
            // for (let k = 0; k < this.legends.length; k++) {
            //   if (
            //     parseFloat(data[0][0]) >= this.legends[k].min &&
            //     parseFloat(data[0][0]) < this.legends[k].max
            //   ) {
            //     this.legends[k].pixels = this.legends[k].pixels + 1;
            //     //break;
            //   }
            // }
          }
        }
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss">
.map-view {
  position: relative;
  height: 100vh;
}
.analytic-map {
  position: absolute;
  height: 100%;
  width: 100%;
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 1;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: rgba(0, 0, 0, 5);
    color: #c1cfdb;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: rgba(0, 0, 0, 5);
  }
}
.analytic-legend {
  position: absolute;
  right: 0.75rem;
  bottom: 3.875rem;
  background: rgba(17, 20, 24, 0.8);
  min-width: 200px;
  border-radius: 0.5rem;
}
.analytic-times-scroll {
  background: #111418;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
<style lang="scss">
.map-view {
  .splitpanes__pane {
    position: relative;
    width: 100%;
  }
}
</style>
