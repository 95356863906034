<template>
  <div class="d-flex" v-if="show">
    <v-dialog
      v-model="show"
      persistent
      no-click-animation
      :content-class="'save-fields-dialog'"
      @click:outside="outsideClick"
    >
      <div class="d-flex w-100">
        <div class="left">
          <SaveFieldsMap
            :prop-fields="propFields"
            @updateFeatures="updateFeatures"
          />
        </div>
        <div class="right">
          <div class="right-header">
            <div class="d-flex justify-content-between align-items-center">
              <v-btn text color="error" class="button-delete">Delete All</v-btn>
              <span class="title">Saves Fields</span>
              <v-btn icon class="button-close" color="#8296aa" @click="onClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div
            class="right-content"
            :style="{
              maxHeight: documentHeight - 300 + 'px',
              minHeight: documentHeight - 500 + 'px',
            }"
          >
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel v-for="(item, i) in propFields" :key="i">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                  {{ item.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Field
                    :prop-field="item"
                    :prop-years="years"
                    @dataChange="isDataChanged = true"
                    ref="field4Save"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="dialog-actions text-right">
            <v-btn depressed class="button-cancel" @click="cancelSave"
              >Cancel</v-btn
            >
            <v-btn depressed class="button-save" @click="saveChangeFields"
              >Save</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import Field from "./savefieldsdialog/Field.vue";
import SaveFieldsMap from "./savefieldsdialog/SaveFieldsMap.vue";

export default {
  components: {
    Field,
    SaveFieldsMap,
  },
  props: {
    propFields: {
      type: Array,
      default: () => [],
    },
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      years: [],
      panel: [],
      isDataChanged: false,
      fields: [],
      features: [],
    };
  },
  created() {
    this.$store.dispatch("setSpinnerApp", { show: true });
    this.show = this.propShow;
    let nowYear = new Date().getFullYear();
    this.fields = Object.assign([], this.propFields);
    for (let i = 0; i < this.fields.length; i++) {
      this.panel.push(i);
      if (
        !this.fields[i].rowsSeason ||
        this.fields[i].rowsSeason.length === 0
      ) {
        this.fields[i].rowsSeason = [];
        for (let j = nowYear; j > nowYear - 5; --j) {
          this.fields[i].rowsSeason.push({
            id:
              new Date().getTime() -
              Math.floor(Math.random() * 999999999) +
              Math.floor(Math.random() * 888888888),
            crop_name: null,
            maturity: null,
            sowing_date: null,
            harvesting_date: null,
            season: j,
          });
        }
      }
    }
    for (let i = nowYear + 5; i > nowYear - 50; --i) {
      this.years.push({
        id: i,
        value: i,
        label: i,
        text: i,
      });
    }
    this.$store.dispatch("setSpinnerApp", { show: false });
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    removeRowSeason(data) {
      let index = this.fields.findIndex((x) => x.id === data.id);
      if (index >= 0) {
        let indexSeason = this.fields[index].rowsSeason.findIndex(
          (x) => x.id === data.item.id
        );
        if (indexSeason >= 0) {
          this.fields[index].rowsSeason.splice(indexSeason, 1);
        }
      }
    },
    outsideClick() {
      if (this.isDataChanged) this.$emit("showSaveFieldsQuestionDialog");
      else this.onClose();
    },
    onClose() {
      this.show = false;
      this.$emit("onClose");
    },
    saveChangeFields() {
      if (this.$refs.field4Save) {
        for (let i = 0; i < this.$refs.field4Save.length; i++) {
          let index = this.fields.findIndex(
            (x) => x.id === this.$refs.field4Save[i].propField.id
          );
          if (index >= 0) {
            if (this.$refs.field4Save[i].$refs.season4Save) {
              for (
                let j = 0;
                j < this.$refs.field4Save[i].$refs.season4Save.length;
                j++
              ) {
                if (this.$refs.field4Save[i].$refs.season4Save[j].entity) {
                  let index2 = this.fields[index].rowsSeason.findIndex(
                    (x) =>
                      x.id ===
                      this.$refs.field4Save[i].$refs.season4Save[i].entity.id
                  );
                  if (index2 >= 0) {
                    for (const [key, value] of Object.entries(
                      this.$refs.field4Save[i].$refs.season4Save[i].entity
                    )) {
                      this.fields[index].rowsSeason[index2][key] = value;
                    }
                  }
                }
              }
            }
            this.fields[index].name = this.$refs.field4Save[i].name
              ? this.$refs.field4Save[i].name
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              : null;
            this.fields[index].group_name = this.$refs.field4Save[i].group_name
              ? this.$refs.field4Save[i].group_name
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              : null;
          }
        }
        this.$emit("saveChangeFields", {
          fields: this.fields,
          features: this.features,
        });
        this.onClose();
      }
    },
    cancelSave() {
      if (this.isDataChanged) this.$emit("showSaveFieldsQuestionDialog");
      else this.onClose();
    },
    updateFeatures(data) {
      this.features = data;
      this.isDataChanged = true;
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.save-fields-dialog {
  background: #212930 !important;
  border-radius: 0.5rem;
  color: white;
  width: 1140px !important;
  .left {
    width: 30%;
    position: relative;
  }
  .right {
    width: 70%;
    padding: 0;
    .right-header {
      padding: 0.5rem;
      .title {
        color: white;
        font-size: 16px;
        font-weight: 400;
      }
      .button-delete {
        color: $error-primary;
      }
      .button-close {
        color: #8296aa;
      }
    }
    .right-content {
      padding: 0.5rem;
      overflow-y: auto;
      .v-expansion-panel {
        background-color: #4f5d73;
      }
    }

    .dialog-actions {
      padding: 1rem 0.5rem;
      button {
        color: white;
        text-align: center;
        font-weight: 500;
        width: 15rem;
        border-radius: 0.5rem;

        &.button-save {
          background: #3592fd;
          &:hover {
            background: #0477fa;
          }
        }
        &.button-cancel {
          margin-right: 0.5rem;
          background: rgba(75, 92, 108, 0.4);
          &:hover {
            background: rgba(75, 92, 108, 1);
          }
        }
      }
    }
  }
}
</style>