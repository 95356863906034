<template>
  <div class="d-flex" v-if="show">
    <v-dialog
      v-model="show"
      persistent
      no-click-animation
      :content-class="'pan-2-location-dialog'"
      @click:outside="onClose"
    >
      <v-card>
        <v-card-title class="headline"
          >Pan to location
          <v-btn icon class="button-close" color="#8296aa" @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="selected">
            <v-radio
              v-for="n in radioGroups"
              :key="n.id"
              :label="n.label"
              :value="n.id"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text v-if="selected === 2">
          <v-container class="p-0">
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="col-field input-field-background border-radius-8">
                  <v-text-field
                    label="Latitude"
                    hide-details="auto"
                    v-model="coordinate.lat"
                    maxlength="50"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="col-group input-field-background border-radius-8">
                  <v-text-field
                    label="Longiture"
                    hide-details="auto"
                    v-model="coordinate.lng"
                    maxlength="50"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button class="btn" @click="onAccept">
            <span class="button-text">Accept</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      selected: 1,
      coordinate: {
        lat: null,
        lng: null,
      },
      radioGroups: [
        {
          id: 1,
          label: "Your location",
        },
        {
          id: 2,
          label: "Other location",
        },
      ],
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;
      this.coordinate.lat = null;
      this.coordinate.lng = null;
    },
    onAccept() {
      if (this.selected === 2) {
        try {
          let lat = parseFloat(this.coordinate.lat);
          let lng = parseFloat(this.coordinate.lng);

          if (
            lat != null &&
            lat != undefined &&
            !isNaN(lat) &&
            lng != null &&
            lng != undefined &&
            !isNaN(lng)
          ) {
          } else {
            this.$store.dispatch(
              "setMessage",
              "Coordinate is not correct format. Please try again!"
            );
            return;
          }
        } catch (error) {
          this.$store.dispatch(
            "setMessage",
            "Coordinate is not correct format. Please try again!"
          );
          return;
        }
      }
      this.$emit("accept", {
        selected: this.selected,
        coordinate: this.coordinate,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.pan-2-location-dialog {
  background: #212930 !important;
  width: 28rem !important;
  position: static;
  box-shadow: 0 12px 24px rgb(0, 0, 0 / 40%);

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .v-card {
    background: #212930 !important;
    box-shadow: none;
    .v-card__title {
      color: white;
      text-align: center;
      word-break: normal;
      .button-close {
        position: absolute;
        top: 0.5rem;
        right: 0;

        &:hover {
          color: white;
        }
      }
    }
    .v-card__text {
      color: white;
      padding-top: 12px !important;
      position: relative;
      .v-input--radio-group {
        .v-label {
          color: white;
        }
      }
      .row {
        &.no-gutters {
          padding: 0;
          margin: 0;
          .col-md-6 {
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            &:first-child {
              padding-right: 0.25rem;
            }
            &:last-child {
              padding-left: 0.25rem;
            }
            .input-field-background {
              background: $input-field-background;
            }
            .col-field {
              padding: 0.125rem;
              padding-left: 0.5rem;
            }
            .col-group {
              padding: 0.125rem;
              padding-left: 0.5rem;
            }
          }
          .v-input__control {
            label {
              color: #c1cfdb !important;
              font-size: 14px;
            }
            .v-text-field__slot {
              input {
                color: white !important;
                font-size: 14px;
              }
            }
            .v-select__slot {
              .v-select__selections {
                .v-select__selection {
                  color: white;
                  font-size: 14px;
                }
              }
            }
          }
          .v-icon {
            color: #c1cfdb !important;
            font-size: 18px;
          }
        }
      }
    }
    .v-card__actions {
      button {
        width: 8rem;
        height: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: 1px solid #3592fd;
        background: #3592fd;
        &:hover {
          background: #0477fa;
        }
      }
    }
  }
}
</style>