import Vue from 'vue'
import App from './App.vue'

import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from "./lang/lang";

import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);

import 'animate.css'

import "ol/ol.css";

import 'splitpanes/dist/splitpanes.css'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)

import vuetify from '@/plugins/vuetify' // path to vuetify export

Vue.config.productionTip = false

const app = new Vue({
  i18n,
  router,
  store,vuetify,
  render: h => h(App)
}).$mount('#app')

window.Event = app
export default app