<template>
  <div>
    <div class="tools-map">
      <div
        class="tool-item"
        :class="propButtonsTool.layers.show ? 'active' : ''"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('LAYERS_MAP')"
            >
              <span class="button-icon layers-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    id="footer-sample-full"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                    class="iconify iconify--bx"
                  >
                    <path
                      d="M21.484 7.125l-9.022-5a1.003 1.003 0 0 0-.968 0l-8.978 4.96a1 1 0 0 0-.003 1.748l9.022 5.04a.995.995 0 0 0 .973.001l8.978-5a1 1 0 0 0-.002-1.749z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M12 15.856l-8.515-4.73l-.971 1.748l9 5a1 1 0 0 0 .971 0l9-5l-.971-1.748L12 15.856z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M12 19.856l-8.515-4.73l-.971 1.748l9 5a1 1 0 0 0 .971 0l9-5l-.971-1.748L12 19.856z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>{{ $t("tooltip_layers") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="tools-map">
      <div class="tool-item">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('ZOOM_IN')"
            >
              <span class="button-icon">
                <span>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 14 14"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill=""></path>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>{{ $t("tooltip_zoom_in") }}</span>
        </v-tooltip>
      </div>
      <div class="tool-item">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('ZOOM_OUT')"
            >
              <span class="button-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    height="100%"
                    viewBox="0 0 14 2"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <defs><path id="A2" d="M43 447H29v-2h14z"></path></defs>
                    <use xlink:href="#A2" transform="translate(-29 -445)"></use>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>{{ $t("tooltip_zoom_out") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="tools-map">
      <div class="tool-item" :class="propButtonsTool.measure ? 'active' : ''">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="btn"
                    @click="activeTool('MEASURE')"
                  >
                    <span class="button-icon measure-icon">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 20 20"
                          fit=""
                          height="100%"
                          width="100%"
                          preserveAspectRatio="xMidYMid meet"
                          focusable="false"
                        >
                          <defs>
                            <path
                              id="A"
                              d="M43.496 505.323H28.498v-6.656h1.666v3.328h1.667v-3.328h1.666v3.328h1.666v-3.328h1.667v3.328h1.666v-3.328h1.667v3.328h1.666v-3.328h1.667zm0-8.323h-15a1.67 1.67 0 0 0-1.666 1.667v6.666c0 .917.75 1.667 1.667 1.667h15a1.67 1.67 0 0 0 1.666-1.667v-6.666A1.67 1.67 0 0 0 43.497 497z"
                            ></path>
                          </defs>
                          <use
                            xlink:href="#A"
                            transform="rotate(315 -570.897 287.385)"
                          ></use>
                        </svg>
                      </span>
                    </span>
                  </button>
                </template>
                <v-list dark>
                  <v-list-item @click="activeTool('MEASURE_DISTANCE')">
                    <v-list-item-title>Distance</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="activeTool('MEASURE_AREA')">
                    <v-list-item-title>Area</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Measure</span>
        </v-tooltip>
      </div>
      <div class="tool-item">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('CURRENT_LOCATION')"
            >
              <span class="button-icon location-icon">
                <span>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      d="M6 3.81817C4.79454 3.81817 3.81819 4.79454 3.81819 5.99997C3.81819 7.20541 4.79454 8.18181 6 8.18181C7.20546 8.18181 8.18181 7.20544 8.18181 6C8.18181 4.79456 7.20544 3.81817 6 3.81817ZM10.8764 5.45453C10.6254 3.17999 8.81999 1.37454 6.54544 1.12364V0H5.45453V1.12364C3.17999 1.37454 1.37454 3.17999 1.12364 5.45453H0V6.54544H1.12364C1.37454 8.81999 3.18001 10.6254 5.45455 10.8764V12H6.54544V10.8764C8.81999 10.6254 10.6254 8.81999 10.8764 6.54544H12V5.45453H10.8764ZM6 9.81817C3.89183 9.81817 2.18183 8.10817 2.18183 6C2.18183 3.89183 3.89183 2.18183 6 2.18183C8.10817 2.18183 9.81817 3.8918 9.81817 6C9.81817 8.1082 8.10817 9.81817 6 9.81817Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>Pan to location</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    activeTool(code) {
      this.$emit("leftActionClick", {
        code: code,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
div {
  .tools-map {
    padding: 0.25rem;
    &:first-child {
      margin: 0;
    }
    .tool-item {
      display: block;
      text-align: center;
      height: 2.5rem;
      width: 2.5rem;
      border: none;
      margin-bottom: 0.025rem;
      &:last-child {
        margin-bottom: 0;
        button {
          border-bottom-right-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }
      &:first-child {
        button {
          border-top-right-radius: 0.5rem;
          border-top-left-radius: 0.5rem;
        }
      }
      &.active {
        button {
          color: #005ce0;
        }
      }
      button {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 1;
        padding: 0;
        color: white;
        background: #111418;
        border-radius: 0;
        border: 0;
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 14px;
          height: 14px;
          &.measure-icon,
          &.layers-icon,
          &.split-icon,
          &.location-icon {
            width: 20px;
            height: 20px;
          }
        }
        &:hover {
          background: #212930;
        }
      }
    }
  }
}
</style>