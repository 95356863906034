<template>
  <div class="map-top-panel">
    <LayerFilter />
  </div>
</template>
<script>
import LayerFilter from "./maptoppanel/LayerFilter.vue";
export default {
  components: {
    LayerFilter,
  },
};
</script>
<style lang="scss" scoped>
.map-top-panel {
  display: flex;
  height: 48px;
  position: fixed;
  top: 0.75rem;
  left: 26.5rem;
}
</style>