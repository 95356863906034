<template>
  <div class="monitoring-content">
    <OpenlayerMap
      :prop-fields="fields"
      :prop-measure-tool="measureTool"
      :prop-buttons-tool="buttonsTool"
      @initMapSuccess="initMapSuccess"
      @measureEnd="measureEnd"
      @mapMoveend="mapMoveend"
      ref="openlayerMap"
    />
    <div class="top-side">
      <SearchBox @onSearch="onSearch" @pan2Location="onPan2Location" />
      <MapTopPanel />
    </div>
    <div class="right-side">
      <RightSide
        :prop-fields="fields"
        :prop-map="map"
        @openDialogAddField="openDialogAddField"
      />
    </div>
    <div
      class="left-side"
      :style="{ top: (documentHeight - 3 * 126) / 2 + 'px' }"
    >
      <LeftSide
        :prop-buttons-tool="buttonsTool"
        @leftActionClick="leftActionClick"
      />
    </div>

    <div
      v-if="measureTool.value && !measureTool.active"
      class="measure-result"
      :style="[
        isShowRightSide && !isSmallDevice
          ? {
              left:
                (documentWidth - 304 - (isCollapseMenu ? 56 : 224) - 240) / 2 +
                'px',
            }
          : { left: (documentWidth - 240 - 56) / 2 },
      ]"
    >
      <div class="measure-popup">
        <div
          class="result-title d-flex justify-content-between align-items-center"
        >
          Measure distance

          <v-btn
            icon
            class="button-close"
            color="#8296aa"
            @click="onCloseMeasureResult"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="result-body">
          <div class="text" v-if="measureTool.type === 'LineString'">
            Total distance:&nbsp;<span v-html="measureTool.value"></span>
          </div>
          <div class="text" v-if="measureTool.type === 'Polygon'">
            Total area:&nbsp;<span v-html="measureTool.value"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="monitoring-dialog">
      <AddFieldDialg
        :prop-show="dialog.addField.show"
        :prop-field-total="fields.length"
        @close="dialog.addField.show = false"
        @drawField="onDrawField"
      />
      <Pan2LocationDialg
        :prop-show="dialog.pan2Location.show"
        @close="dialog.pan2Location.show = false"
        @accept="onAcceptCoordinate"
      />
    </div>
  </div>
</template>
<script>
import OpenlayerMap from "./OpenlayerMap.vue";
import RightSide from "./RightSide.vue";
import LeftSide from "./LeftSide.vue";
import BottomSide from "./BottomSide.vue";
import SearchBox from "./SearchBox.vue";
import MapTopPanel from "./MapTopPanel.vue";
import AddFieldDialg from "./dialog/AddFieldDialog.vue";
import Pan2LocationDialg from "./dialog/Pan2Location.vue";
import { toLonLat, fromLonLat, transform } from "ol/proj";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import {
  OSM,
  TileImage,
  Vector as VectorSource,
  ImageWMS,
  TileWMS,
  ImageArcGISRest,
  TileArcGISRest,
  ImageCanvas,
  TileDebug,
} from "ol/source.js";
import {
  Style,
  Fill,
  Stroke,
  Circle as CircleStyle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";
import XYZ from "ol/source/XYZ";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import windyFunc from "@/utils/middleware/windy";
import firesFunc from "@/utils/middleware/fires";
export default {
  components: {
    RightSide,
    LeftSide,
    OpenlayerMap,
    BottomSide,
    SearchBox,
    MapTopPanel,
    AddFieldDialg,
    Pan2LocationDialg,
  },
  data() {
    return {
      isShowRightSide: true,
      isSmallDevice: false,
      measureTool: {
        active: false,
        type: null,
        value: null,
      },
      map: null,
      view: Object,
      surfaceTemperatureLayer: Object,
      fields: [],
      dialog: {
        addField: {
          show: false,
        },
        pan2Location: {
          show: false,
        },
      },
      buttonsTool: {
        layers: {
          show: false,
          show2: false,
        },
        measure: false,
      },
      mapPosition: {
        lat: null,
        lng: null,
        zoom: null,
        temperatureLoading: false,
        xTiles: [],
        yTiles: [],
        xyTiles: [],
      },
      intervalReloadTemperature: null,
    };
  },
  created() {
    let data = localStorage.getItem("fields");
    if (data && data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")) {
      data = data.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ");
      try {
        this.fields = JSON.parse(data);
      } catch (error) {}
    }
    if (!this.fields) this.fields = [];
    for (let i = 0; i < this.fields.length; i++) {
      let color = this.getRandomColor();
      this.fields[i].color = color;
    }
  },
  mounted() {
    setTimeout(() => {
      let fieldId = localStorage.getItem("field");
      if (fieldId) {
        let index = this.fields.findIndex((x) => x.id + "" === fieldId + "");
        if (index >= 0) {
          var myElement = document.getElementById("fieldlist-content");
          myElement.scrollTop = index * 85;
        }
      }
    }, 200);
  },
  watch: {
    configJson: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          if (this.map && Object.keys(this.map).length > 0) this.initLayers();
        }
      },
      immediate: true,
    },
    "mapPosition.lat": {
      handler(newVal, oldVal) {
        if (oldVal && newVal != oldVal) {
          if (this.mapPosition.temperatureLoading === false)
            this.generateSurfaceTemperatureLayer();
        }
      },
      immediate: true,
    },
    "mapPosition.lng": {
      handler(newVal, oldVal) {
        if (oldVal && newVal != oldVal) {
          if (this.mapPosition.temperatureLoading === false)
            this.generateSurfaceTemperatureLayer();
        }
      },
      immediate: true,
    },
    "mapPosition.zoom": {
      handler(newVal, oldVal) {
        if (oldVal && newVal != oldVal) {
          if (this.mapPosition.temperatureLoading === false)
            this.generateSurfaceTemperatureLayer();
        }
      },
      immediate: true,
    },
  },
  methods: {
    showHideLayersMap() {
      if (this.buttonsTool.layers.show) this.closeWidgetLayers();
      else this.openWidgetLayers();
    },
    openWidgetLayers() {
      this.buttonsTool.layers.show2 = true;
      this.buttonsTool.layers.show = true;
    },
    closeWidgetLayers() {
      this.buttonsTool.layers.show2 = false;
      setTimeout(() => {
        this.buttonsTool.layers.show = false;
      }, 1000);
    },
    mapMoveend(map) {
      let center = map.getView().getCenter();
      this.mapPosition.lat = center[1];
      this.mapPosition.lng = center[0];
    },
    onPan2Location(coordinate) {
      let zoomLevel = this.map.getView().getZoom();
      this.map.getView().animate({
        center: transform(coordinate, "EPSG:4326", "EPSG:3857"),
        duration: 2000,
        zoom: zoomLevel < 16 ? 16 : zoomLevel,
      });
    },
    onSearch(textSearch) {
      if (this.map && Object.keys(this.map).length > 0) {
        let array = textSearch.split(",");
        if (array && array.length > 1) {
          let lat = null,
            lng = null;
          try {
            lat = parseFloat(
              array[0].trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
            );
          } catch (error) {
            this.$store.dispatch(
              "setMessage",
              "Please enter correct coordinates"
            );
          }
          try {
            lng = parseFloat(
              array[1].trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
            );
          } catch (error) {
            this.$store.dispatch(
              "setMessage",
              "Please enter correct coordinates"
            );
          }
          let zoomLevel = this.map.getView().getZoom();
          this.map.getView().animate({
            center: transform([lng, lat], "EPSG:4326", "EPSG:3857"),
            duration: 2000,
            zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
          });
        } else {
          this.$store.dispatch(
            "setMessage",
            "Please enter correct coordinates"
          );
        }
      }
    },
    initMapSuccess(data) {
      this.map = data.map;
      let center = this.map.getView().getCenter();
      this.mapPosition.lat = center[1];
      this.mapPosition.lng = center[0];
      this.mapPosition.zoom = this.map.getView().getZoom();
      this.surfaceTemperatureLayer = data.temperatureLayer;

      if (this.map.getLayers().array_.length < 5) this.initLayers();

      this.addAdministrativeUnits();
      this.generateSurfaceTemperatureLayer();

      this.intervalReloadTemperature = setInterval(function () {
        this.generateSurfaceTemperatureLayer(false);
      }, 600000);
      this.addLayerFirePoints();
    },
    leftActionClick(val) {
      switch (val.code) {
        case "LAYERS_MAP":
          this.showHideLayersMap();
          break;
        case "ZOOM_IN":
          this.onZoomIn();
          break;
        case "ZOOM_OUT":
          this.onZoomOut();
          break;
        case "MEASURE":
          if (this.$refs.openlayerMap)
            this.$refs.openlayerMap.clearMeasureTool();
          this.buttonsTool.measure = true;
          this.measureTool.active = true;
          this.measureTool.value = null;
          this.measureTool.type = "LineString";
          break;
        case "MEASURE_DISTANCE":
          this.buttonsTool.measure = true;
          this.measureTool.active = true;
          this.measureTool.value = null;
          this.measureTool.type = "LineString";
          break;
        case "MEASURE_AREA":
          this.buttonsTool.measure = true;
          this.measureTool.active = true;
          this.measureTool.value = null;
          this.measureTool.type = "Polygon";
          break;
        case "CURRENT_LOCATION":
          this.dialog.pan2Location.show = true;
          break;
      }
    },
    measureEnd(feature) {
      // this.measureTool.type = null;
      this.measureTool.active = false;
      this.buttonsTool.measure = false;
    },
    onCloseMeasureResult() {
      this.measureTool.active = false;
      this.measureTool.value = null;
      this.measureTool.type = null;
      if (this.$refs.openlayerMap) this.$refs.openlayerMap.clearMeasureTool();
    },
    onZoomIn() {
      if (this.map.getView().getMaxZoom() > this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() + 1,
        });
      }
    },
    onZoomOut() {
      if (this.map.getView().getMinZoom() < this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() - 1,
        });
      }
    },
    getCurrentLocation() {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      var vm = this;
      function success(pos) {
        var crd = pos.coords;
        let zoomLevel = vm.map.getView().getZoom();
        vm.map.getView().animate({
          center: fromLonLat([crd.longitude, crd.latitude]),
          duration: 1000,
          zoom: zoomLevel < 16 ? 16 : zoomLevel < 20 ? zoomLevel + 1 : 20,
        });
      }

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    onAcceptCoordinate(data) {
      if (data.selected === 1) this.getCurrentLocation();
      else if (data.selected === 2) {
        let zoomLevel = this.map.getView().getZoom();
        this.map.getView().animate({
          center: fromLonLat([
            parseFloat(data.coordinate.lng),
            parseFloat(data.coordinate.lat),
          ]),
          duration: 2000,
          zoom: zoomLevel < 16 ? 16 : zoomLevel < 20 ? zoomLevel + 1 : 20,
        });
      }
      this.dialog.pan2Location.show = false;
    },
    openDialogAddField() {
      this.dialog.addField.show = true;
    },
    onDrawField() {
      if (this.map && Object.keys(this.map).length > 0) {
        this.$store.dispatch("setMapPosition", {
          center: this.map.getView().getCenter(),
          zoom: this.map.getView().getZoom(),
        });
      }
      this.$router.push({
        name: "DrawField",
      });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    async initLayers() {
      if (this.configJson && Object.keys(this.configJson).length > 0) {
        if (this.configJson.layers && this.configJson.layers.length > 0) {
          for (let i = 0; i < this.configJson.layers.length; i++) {
            let layer = undefined;
            let type = Object(this.configJson.layers[i])["type"];
            switch (type) {
              case "TILE_LAYER":
                layer = await this.registerTileLayer(this.configJson.layers[i]);
                break;
              case "SHAPE_FILE":
                layer = await this.registerShapeFile(this.configJson.layers[i]);
                break;
              case "JSON_FILE":
                layer = await this.registerJsonFile(this.configJson.layers[i]);
                break;
              case "WMS_LAYER":
                layer = await this.registerWmsLayer(this.configJson.layers[i]);
                break;
              case "ARCGIS_MAPSERVICE":
                layer = await this.registerArcgisMapservice(
                  this.configJson.layers[i]
                );
                break;
            }
            if (layer) {
              layer.set("id", this.configJson.layers[i].id);
              layer.setZIndex(i + 1);
              this.map.addLayer(layer);
            }
          }
        }
      }
      // this.addTileLayerFiresNasa();
    },
    async addTileLayerFiresNasa() {
      let layer = new TileLayer({
        title: "Fires / Hotspots",
        id: "Fires / Hotspots",
        source: new XYZ({
          url: "https://firms.modaps.eosdis.nasa.gov/mapserver/wmts/fires/24hrs/{z}/{y}/{x}",
          tileSizes: [
            [256, 256],
            [256, 256],
            [256, 256],
            [256, 256],
            [256, 256],
          ],
        }),
        show: true,
        opacity: 1,
        visible: true,
      });
      var defaultTileURLFunction = layer.getSource().getTileUrlFunction();

      layer
        .getSource()
        .setTileUrlFunction(function (tileCoord, pixelRatio, projection) {
          tileCoord = [
            tileCoord[0] - 1,
            Math.floor(tileCoord[1] / 2),
            Math.ceil(tileCoord[2] / 2),
          ];
          var tileURL = defaultTileURLFunction(
            tileCoord,
            pixelRatio,
            projection
          );
          return tileURL;
        });

      layer.setZIndex(1000);
      this.map.addLayer(layer);
    },
    async addLayerFirePoints() {
      try {
        let responseSessions = await firesFunc.getSessions();
        if (responseSessions && responseSessions.status === 200) {
          if (
            responseSessions.data.status &&
            responseSessions.data.status.toLowerCase() === "ok"
          ) {
            let sessionIds = responseSessions.data.data.map((x) => x.id);
            let responseFirePoints = await firesFunc.getFirePoints(sessionIds);
            if (responseFirePoints && responseFirePoints.status === 200) {
              if (
                responseFirePoints.data.status &&
                responseFirePoints.data.status.toLowerCase() === "ok"
              ) {
                let layer = await this.registerJsonFile({
                  geojsonObject: responseFirePoints.data.data,
                  icon: "/img/icons/fire_16.png",
                  id: "firesLayer",
                });
                layer.setZIndex(this.map.getLayers().array_.length + 10);
                layer.set("id", "firesLayer");
                this.map.addLayer(layer);
                setTimeout(() => {
                  if (this.$refs.openlayerMap)
                    this.$refs.openlayerMap.addLayer2Widget({
                      id: "firesLayer",
                      title: "Fires / Hotspots",
                      show: true,
                      opacity: 100,
                    });
                }, 1000);
              }
            }
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async addAdministrativeUnits() {
      var vm = this;
      JSZipUtils.getBinaryContent(
        "data/vietnamborder.zip",
        function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          var shp = require("shpjs");
          shp(data).then(async function (geojson) {
            if (geojson) {
              //if (geojson && geojson.length > 0) {
              //for (let i = 0; i < geojson.length; i++) {
              //if (!geojson[i]) continue;
              let id = geojson.fileName;
              var vectorSource = new VectorSource({
                features: new GeoJSON().readFeatures(geojson, {
                  featureProjection: "EPSG:3857",
                }),
              });
              let style = null;
              style = new Style({
                image: new CircleStyle({
                  fill: new Fill({
                    color: "transparent",
                  }),
                  stroke: new Stroke({
                    color: "rgba(255,255,255,0.8)", //"#FF0000",
                    width: 1.25,
                  }),
                  radius: 5,
                }),
                fill: new Fill({
                  color: "transparent",
                }),
                stroke: new Stroke({
                  color: "rgba(255,255,255,0.8)", //"#FF0000",
                  width: 1.25,
                }),
              });

              let layer = new VectorLayer({
                source: vectorSource,
                style: [style],
                id: id,
              });
              layer.setZIndex(vm.map.getLayers().array_.length + 2);
              layer.set("id", id);
              vm.map.addLayer(layer);
              //setTimeout(() => {
              if (vm.$refs.openlayerMap)
                vm.$refs.openlayerMap.addLayer2Widget({
                  id: id,
                  title: "Đơn vị hành chính ",
                  show: true,
                  opacity: 100,
                });
              //}, 1000);
              //}
              //}
            } else console.log(" cannot be read");
          });
        }
      );
    },
    registerTileLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          if (layer.title === "OpenStreetMap") {
            resolve(
              new TileLayer({
                source: new OSM(),
                opacity: Object(layer).opacity,
                visible: Object(layer).show,
              })
            );
          } else {
            var obj = {
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new XYZ({
                url: Object(layer).source.url,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
              visible: Object(layer).show,
            };

            resolve(new TileLayer(obj));
          }
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerShapeFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let vector = new VectorLayer({
            source: vectorSource,
            style: [
              new Style({
                image: new CircleStyle({
                  fill: new Fill({
                    color: "rgba(255,0,0,0.2)",
                  }),
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 1.25,
                  }),
                  radius: 5,
                }),
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
              }),
            ],
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerJsonFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let style = null;
          if (!layer.icon) {
            style = new Style({
              image: new CircleStyle({
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
                radius: 5,
              }),
              fill: new Fill({
                color: "rgba(255,0,0,0.2)",
              }),
              stroke: new Stroke({
                color: "#FF0000",
                width: 1.25,
              }),
            });
          } else {
            style = new Style({
              image: new Icon({
                size: [16, 16],
                offset: [0, 0],
                opacity: 1,
                scale: 1.0,
                src: layer.icon,
              }),
            });
          }
          let vector = new VectorLayer({
            source: vectorSource,
            style: [style],
            id: layer.id,
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerWmsLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          var obj = {
            title: Object(layer)["title"],
            id: Object(layer).id,
            source: new ImageWMS({
              url: Object(layer).source.url,
              params: Object(layer).source.params,
            }),
            show: Object(layer).show,
            opacity: Object(layer).opacity,
          };
          resolve(new ImageLayer(obj));
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerArcgisMapservice(layer) {
      try {
        return new Promise(function (resolve, reject) {
          resolve(
            new TileLayer({
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileArcGISRest({
                url: layer.source.url,
                params: layer.source.params,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
            })
          );
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    getTileURL(lat, lon, zoom) {
      var xtile = parseInt(Math.floor(((lon + 180) / 360) * (1 << zoom)));
      var ytile = parseInt(
        Math.floor(
          ((1 -
            Math.log(Math.tan(lat.toRad()) + 1 / Math.cos(lat.toRad())) /
              Math.PI) /
            2) *
            (1 << zoom)
        )
      );
      return { xtile: xtile, ytile: ytile };
    },
    // ignore su dung cho interval
    async generateSurfaceTemperatureLayer(ignore = true) {
      try {
        if (
          this.surfaceTemperatureLayer &&
          Object.keys(this.surfaceTemperatureLayer).length > 0
        ) {
          if (this.mapPosition.temperatureLoading) return;
          this.mapPosition.temperatureLoading = true;
          this.surfaceTemperatureLayer.setVisible(false);

          if (typeof Number.prototype.toRad === "undefined") {
            Number.prototype.toRad = function () {
              return (this * Math.PI) / 180;
            };
          }
          if (this.map && Object.keys(this.map).length > 0) {
            let extent = this.map.getView().calculateExtent(this.map.getSize());
            let zoom = this.map.getView().getZoom();
            if (this.mapPosition.zoom != zoom || !ignore) {
              this.mapPosition.xTiles = [];
              this.mapPosition.yTiles = [];
              this.mapPosition.xyTiles = [];
              this.mapPosition.zoom = zoom;
              this.surfaceTemperatureLayer.getSource().clear();
            }
            let minCoordinate = transform(
              [extent[0], extent[1]],
              "EPSG:3857",
              "EPSG:4326"
            );
            let minTileCoordinates = this.getTileURL(
              minCoordinate[1],
              minCoordinate[0],
              Math.floor(zoom)
            );

            let maxCoordinate = transform(
              [extent[2], extent[3]],
              "EPSG:3857",
              "EPSG:4326"
            );
            let maxTileCoordinates = this.getTileURL(
              maxCoordinate[1],
              maxCoordinate[0],
              Math.floor(zoom)
            );
            for (
              let i = minTileCoordinates.xtile;
              i <= maxTileCoordinates.xtile;
              i++
            ) {
              for (
                let j = maxTileCoordinates.ytile;
                j <= minTileCoordinates.ytile;
                j++
              ) {
                if (this.mapPosition.xyTiles.includes(i + "/" + j)) continue;

                this.mapPosition.xyTiles.push(i + "/" + j);
                if (!this.mapPosition.xTiles.includes(i))
                  this.mapPosition.xTiles.push(i);

                if (!this.mapPosition.yTiles.includes(j))
                  this.mapPosition.yTiles.push(j);
                let responseLabels, responseForecast;
                try {
                  responseLabels = await windyFunc.getDataLabels(
                    i,
                    j,
                    Math.floor(zoom)
                  );
                } catch (error1) {}
                try {
                  responseForecast = await windyFunc.getDataForecast(
                    i,
                    j,
                    Math.floor(zoom)
                  );
                } catch (error2) {}
                let dataLabels = [],
                  dataForecast = {};
                if (responseLabels && responseLabels.status === 200) {
                  dataLabels = responseLabels.data ? responseLabels.data : [];
                }
                if (responseForecast && responseForecast.status === 200) {
                  dataForecast = responseForecast.data
                    ? responseForecast.data
                    : {};
                }

                if (dataLabels && dataLabels.length > 0) {
                  for (let k = 0; k < dataLabels.length; k++) {
                    let lat = dataLabels[k][4];
                    let lng = dataLabels[k][3];
                    if (dataForecast && Object.keys(dataForecast).length > 0) {
                      let data =
                        dataForecast[lat.toFixed(2) + "/" + lng.toFixed(2)];
                      if (data && Object.keys(data).length > 0) {
                        await this.renderWeather({
                          start: dataForecast.start,
                          step: dataForecast.step,
                          forecast: data,
                          label: dataLabels[k][1],
                          lat: lat,
                          lng: lng,
                          id: dataLabels[k][0],
                        });
                      }
                    }
                  }
                }
              }
            }
          }
          this.surfaceTemperatureLayer.setVisible(true);
          this.mapPosition.temperatureLoading = false;
        }
      } catch (error) {}
    },
    async renderWeather(data) {
      try {
        var r = new Date().getTime() - data.start,
          o = Math.round(r / (data.step * 3600000));
        if (o != null && o != undefined && o != NaN) {
          if (o < data.forecast.length) {
            await this.addFeatureTemp2Layer({
              temperature: Math.ceil(this.tempConverter(data.forecast[o])),
              label: data.label,
              lat: data.lat,
              lng: data.lng,
              id: data.id,
            });
          }
        }
      } catch (error) {}
    },
    tempConverter(e) {
      return e - 273.15;
    },
    async addFeatureTemp2Layer(data) {
      let featureLabel = this.surfaceTemperatureLayer
        .getSource()
        .getFeatureById(data.id);
      if (!featureLabel) {
        featureLabel = new Feature({
          geometry: new Point(
            transform([data.lng, data.lat], "EPSG:4326", "EPSG:3857")
          ),
          id: data.id,
          name: data.label,
        });
        featureLabel.setId(data.id);
        featureLabel.setStyle(
          new Style({
            text: new Text({
              textAlign: "center",
              textBaseline: "middle",
              font: "12px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
              fill: new Fill({ color: this.hexToRgbA("#ebedef", 1) }),
              stroke: new Stroke({
                color: this.hexToRgbA("#000", 0.5),
                width: 1,
              }),
              text: data.label,
              offsetX: 0,
              offsetY: -12,
            }),
          })
        );
        this.surfaceTemperatureLayer.getSource().addFeature(featureLabel);
      }

      let featureTemp = this.surfaceTemperatureLayer
        .getSource()
        .getFeatureById(data.id + "temp");
      if (!featureTemp) {
        featureTemp = new Feature({
          geometry: new Point(
            transform([data.lng, data.lat], "EPSG:4326", "EPSG:3857")
          ),
          id: data.id + "temp",
          name: data.label,
        });
        featureTemp.setId(data.id + "temp");
        featureTemp.setStyle(
          new Style({
            text: new Text({
              textAlign: "center",
              textBaseline: "middle",
              font: "10px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
              fill: new Fill({ color: this.hexToRgbA("#ebedef", 1) }),
              stroke: new Stroke({
                color: this.hexToRgbA("#000", 0.5),
                width: 1,
              }),
              text: data.temperature + " °C",
              offsetX: 0,
              offsetY: 0,
            }),
          })
        );
        this.surfaceTemperatureLayer.getSource().addFeature(featureTemp);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    isCollapseMenu() {
      return this.$store.getters.getIsCollapseMenu;
    },
    configJson() {
      return this.$store.getters.getDataJson;
    },
  },
  beforeDestroy() {
    if (this.intervalReloadTemperature)
      clearInterval(this.intervalReloadTemperature);
  },
};
</script>
<style lang="scss" scoped>
.monitoring-contentp {
  position: relative;
  width: 100%;
  height: 100%;
}
.top-side {
  position: absolute;
  top: 0;
  left: 0;
}
.right-side {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
}
.bottom-side {
  position: absolute;
  bottom: 0;
  left: 0;
}
.left-side {
  position: absolute;
  left: 0.5rem;
}
.measure-result {
  background: rgba($color: #ffffff, $alpha: 0.8);
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-width: 240px;
  position: absolute;
  left: 200px;
  bottom: 62px;
  .measure-popup {
    position: relative;
    .result-title {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
