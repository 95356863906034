<template>
  <div>
    <div class="splitview-map" :id="mapId" :style="{ width: '100%' }"></div>
    <div
      class="splitview-legend"
      v-if="propImageDays && propImageDays.length > 0"
    >
      <Legend
        :propBand="band"
        :prop-contrast="contrast"
        :prop-legends="
          dataND && dataND.classification && dataND.classification.value
        "
        :prop-area="propField.area"
        @selectBandChange="bandChanged"
        @contrastChange="contrastChanged"
        @onDownload="onDownloadImageTiff"
      />
    </div>
    <div
      class="splitview-times-scroll"
      v-if="propImageDays && propImageDays.length > 0"
    >
      <TimesScroll
        :propImageDays="propImageDays"
        @selectIdChange="selectIdChange"
      />
    </div>
  </div>
</template>
<script>
var sketch;
import Legend from "./splitviewmap/Legend.vue";
import TimesScroll from "./splitviewmap/TimesScroll.vue";
import Map from "ol/Map";
import View from "ol/View";

import XYZ from "ol/source/XYZ";
import { Vector as VectorSource, TileImage, OSM } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { toLonLat, fromLonLat, transform } from "ol/proj";
import Overlay from "ol/Overlay";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import { unByKey } from "ol/Observable";
import Feature from "ol/Feature";
import { Point } from "ol/geom.js";
import imageFunc from "@/utils/middleware/image";
import { change_alias, string_all_trim } from "@/utils/commons/common";
import firesFunc from "@/utils/middleware/fires";
import GeoJSON from "ol/format/GeoJSON";
export default {
  components: {
    Legend,
    TimesScroll,
  },
  props: {
    propMap: {
      type: Object,
    },
    propView: {
      type: Object,
    },
    propFeature: {
      type: Object,
    },
    propImageDays: {
      type: Array,
      default: () => [],
    },
    propField: {
      type: Object,
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      layerPointMove: Object,
      layerND: Object,
      layerRBG: Object,
      view: Object,
      mapId:
        "splitViewMap" +
        (new Date().getTime() -
          Math.floor(Math.random() * 999999999) +
          Math.floor(Math.random() * 888888888)),
      measureTooltipElement: null,
      measureTooltip: null,
      contrast: {
        positive: false,
        negative: false,
      },
      band: "NDVI",
      oldBand: "NDVI",
      imageDayId: null,
      imageDayIdOld: null,
      dataND: null,
      blob: null,
      geotiff: null,
      geotiffImage: null,
    };
  },
  created() {},
  mounted() {
    if (!this.propMap || Object.keys(this.propMap).length === 0) {
      const source = new VectorSource({
        wrapX: false,
      });
      var vm = this;
      this.layer = new VectorLayer({
        source: source,
        id: "drawLayer",
        style: new Style({
          fill: new Fill({
            color: "transparent", //this.hexToRgbA("#fab710", 0.5),
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 2,
          }),
        }),
      });
      this.layer.setZIndex(100);
      this.layerND = new TileLayer({
        title: "Layer ND",
        id: "layernd" + this.mapId,
        source: new XYZ({
          url: null,
        }),
        show: false,
        visible: false,
      });
      this.layerND.setZIndex(101);
      this.layerND.setVisible(false);
      this.layerRBG = new TileLayer({
        title: "LayerRBG",
        id: "layerrbg" + this.mapId,
        source: new XYZ({
          url: null,
        }),
        show: false,
        visible: false,
      });

      this.layerRBG.setZIndex(102);
      this.layerRBG.setVisible(false);
      const sourcePointMove = new VectorSource({
        wrapX: false,
      });
      this.layerPointMove = new VectorLayer({
        source: sourcePointMove,
        id: "drawPointMoveLayer",
        style: new Style({
          image: new Circle({
            fill: new Fill({
              color: this.hexToRgbA("#fff", 0.2),
            }),
            stroke: new Stroke({
              color: this.hexToRgbA("#000", 0.7),
              width: 2,
            }),
            radius: 4,
          }),
        }),
      });
      this.layerPointMove.setZIndex(103);
      if (!this.propView || Object.keys(this.propView).length === 0) {
        this.view = new View({
          center: fromLonLat([108.22779930115105, 16.062106484745996]),
          zoom: 10,
          minZoom: 1,
          maxZoom: 20,
        });
      } else {
        this.view = this.propView;
      }

      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({ doubleClickZoom: false }),
        layers: [this.layer, this.layerND, this.layerRBG, this.layerPointMove],
        target: this.mapId,
        view: this.view,
      });

      if (this.propFeature && Object.keys(this.propFeature).length > 0) {
        this.layer.getSource().addFeature(this.propFeature);
        let ext = this.propFeature.getGeometry().getExtent();

        this.view.fit(ext, this.map.getSize());
      }
      this.map.on("pointermove", pointerMove);
      function pointerMove(evt) {
        if (evt.dragging) {
          return;
        }
        var pixel = vm.map.getEventPixel(evt.originalEvent);
        var hit = false;
        try {
          hit = vm.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
            if (layer.get("id") === "drawLayer") return true;
            else return false;
          });
        } catch (error) {}
        vm.map.getTargetElement().style.cursor = hit ? "pointer" : "";
        if (hit) {
          vm.$emit("pointerMove", {
            id: vm.mapId,
            evt: evt,
          });
          vm.pointerMove({
            id: vm.mapId,
            evt: evt,
          });
        } else {
          vm.$emit("clearMeasureTooltip");
        }
      }
      this.createMeasureTooltip();
      this.$emit("initMapSuccess", this.map);
    }
  },
  watch: {
    propIsDisabledTool(val) {
      if (val) {
        this.clearModify();
      } else {
        if (this.map && Object.keys(this.map).length > 0) this.drawField();
      }
    },
    configJson: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          setTimeout(() => {
            this.initLayers();
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    onDownloadImageTiff() {
      if (this.blob && this.blob.size > 0) {
        this.$store.dispatch("setSpinnerApp", { show: true });
        const link = document.createElement("a");
        let url = URL.createObjectURL(this.blob);
        link.href = url;
        link.setAttribute(
          "download",
          this.propField &&
            Object.keys(this.propField).length > 0 &&
            this.propField.name &&
            this.propField.name.replace(/\s+/g, " ").trim()
            ? string_all_trim(
                change_alias(this.propField.name.replace(/\s+/g, " ").trim())
              ) + ".tif"
            : "download.tif"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.$store.dispatch("setSpinnerApp", { show: false });
      } else {
        this.$store.dispatch("setMessage", "No image to dowwnload");
      }
    },
    visibleLayers(ids) {
      if (this.map && Object.keys(this.map).length > 0) {
        let layers = this.map.getLayers().array_;
        if (layers && layers.length > 0) {
          for (let i = 0; i < layers.length; i++) {
            if (ids.includes(layers[i].get("id"))) layers[i].setVisible(true);
          }
        }
      }
    },
    hideLayers(ids) {
      if (this.map && Object.keys(this.map).length > 0) {
        let layers = this.map.getLayers().array_;
        if (layers && layers.length > 0) {
          for (let i = 0; i < layers.length; i++) {
            if (ids.includes(layers[i].get("id"))) layers[i].setVisible(false);
          }
        }
      }
    },
    contrastChanged() {},
    async bandChanged(val) {
      if (val === this.oldBand && this.band === "NATURE") {
        this.band = val;
        if (this.layerRBG && Object.keys(this.layerRBG).length > 0) {
          this.layerRBG.setVisible(false);
        }
        if (this.layerND && Object.keys(this.layerND).length > 0) {
          this.layerND.setVisible(true);
        }
      } else {
        this.band = val;
        await this.getInfo();
      }
    },
    async selectIdChange(id) {
      this.imageDayId = id;
      await this.getInfo();
    },
    showData() {
      if (this.dataND && Object.keys(this.dataND).length > 0) {
        if (
          this.dataND.nd_map &&
          this.dataND.nd_map.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        ) {
          if (this.layerND && Object.keys(this.layerND).length > 0) {
            this.layerND
              .getSource()
              .setUrl(
                this.dataND.nd_map
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              );
            this.layerND.setVisible(true);
          }
        } else if (
          this.dataND.map &&
          this.dataND.map.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        ) {
          if (this.layerND && Object.keys(this.layerND).length > 0) {
            this.layerND
              .getSource()
              .setUrl(
                this.dataND.map
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              );
          } else {
            this.layerND.getSource().setUrl(null);
          }
        }
        if (
          this.dataND.rbg_map &&
          this.dataND.rbg_map.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        ) {
          if (this.layerRBG && Object.keys(this.layerRBG).length > 0) {
            this.layerRBG
              .getSource()
              .setUrl(
                this.dataND.rbg_map
                  .trim()
                  .replace(/\s\s+/g, " ")
                  .replace(/  +/g, " ")
              );
          }
        }

        setTimeout(() => {
          this.layerND.getSource().refresh();
          this.layerRBG.getSource().refresh();
        }, 100);
      }
    },
    async getInfo() {
      if (
        ["NATURE"].includes(this.band) &&
        this.imageDayId === this.imageDayIdOld
      ) {
        if (this.layerRBG && Object.keys(this.layerRBG).length > 0) {
          this.layerRBG.setVisible(true);
        }
        if (this.layerND && Object.keys(this.layerND).length > 0) {
          this.layerND.setVisible(false);
        }
      } else {
        if (this.band != "NATURE") this.oldBand = this.band;
        this.dataND = null;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        if (this.layerND && Object.keys(this.layerND).length > 0) {
          this.layerND.setVisible(false);
        }
        if (this.layerRBG && Object.keys(this.layerRBG).length > 0) {
          this.layerRBG.setVisible(false);
        }
        this.blob = null;
        this.geotiff = null;
        this.geotiffImage = null;
        switch (this.band === "NATURE" ? this.oldBand : this.band) {
          case "NDVI":
            await this.getNDVI([this.propField.coordinates]);
            break;
          case "NDMI":
            await this.getNDMI([this.propField.coordinates]);
            break;
          case "NDWI":
            await this.getNDWI([this.propField.coordinates]);
            break;
          case "MNDWI":
            await this.getMNDWI([this.propField.coordinates]);
            break;
          case "NDBI":
            await this.getNDBI([this.propField.coordinates]);
            break;
          case "SAVI":
            await this.getSAVI([this.propField.coordinates]);
            break;
          case "FDI":
            this.legends = legendsCommon.ndvi;
            await this.getFDI([this.coordinates]);
            break;
          case "WILD FIRES":
            await this.getVisual([this.propField.coordinates]);
            break;
        }
        if (this.dataND && Object.keys(this.dataND).length > 0) {
          await this.showData();
          if (this.layerRBG && Object.keys(this.layerRBG).length > 0) {
            this.layerRBG.setVisible(this.band === "NATURE");
          }
          if (this.layerND && Object.keys(this.layerND).length > 0) {
            this.layerND.setVisible(this.band != "NATURE");
          }
        }
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async getNDVI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDVI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND && this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getNDMI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDMI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDMI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND && this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getNDWI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDWI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND && this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getMNDWI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getMNDWI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND && this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getNDBI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getNDBI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND && this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getSAVI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getSAVI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND && this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getFDI(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getFDI({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            palette: JSON.parse(process.env.VUE_APP_VISUAL_PALETTE_NDVI),
            min: -1,
            max: 1,
          },
          need_geotiff: true,
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    async getVisual(coordinates) {
      this.$store.dispatch("setSpinnerApp", { show: true });
      try {
        let response = await imageFunc.getVisual({
          image: this.imageDayId,
          roi: {
            type: "Polygon",
            coordinates: coordinates,
          },
          visual: {
            min: 0,
            max: 3000,
            bands: ["B12", "B8", "B5"],
          },
        });
        if (response && response.status === 200) {
          this.dataND = response.data ? response.data : null;
          if (this.dataND.data && this.dataND.data.length > 0) {
            this.blob = this.base64toBlob(
              this.dataND.data[0].data,
              "image/tiff"
            );
            this.geotiff = await GeoTIFF.fromBlob(this.blob);
            this.geotiffImage = await this.geotiff.getImage();
          }
        }
      } catch (error) {}

      this.$store.dispatch("setSpinnerApp", { show: false });
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    async getDataFromImage(point) {
      return new Promise(async (resolve, reject) => {
        try {
          const bbox = this.geotiffImage.getBoundingBox();
          const pixelWidth = this.geotiffImage.getWidth();
          const pixelHeight = this.geotiffImage.getHeight();
          const bboxWidth = bbox[2] - bbox[0];
          const bboxHeight = bbox[3] - bbox[1];

          const widthPct = (point[0] - bbox[0]) / bboxWidth;
          const heightPct = (point[1] - bbox[1]) / bboxHeight;
          const xPx = Math.floor(pixelWidth * widthPct);
          const yPx = Math.floor(pixelHeight * (1 - heightPct));
          const window = [xPx, yPx, xPx + 1, yPx + 1];
          const data = await this.geotiffImage.readRasters({ window });
          resolve(data);
        } catch (error) {
          resolve(null);
        }
      });
    },
    async pointerMove(data) {
      var vm = this;
      if (vm.band != "WILD FIRES" && vm.band != "NATURE") {
        if (!vm.measureTooltipElement) this.createMeasureTooltip();
        vm.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
        let d = await this.getDataFromImage(
          transform(data.evt.coordinate, "EPSG:3857", "EPSG:4326")
        );
        if (vm.measureTooltipElement) {
          if (d && d.length > 0 && d[0].length > 0) {
            // var quotient = Math.floor(parseFloat(d[0][0]));
            // var remainder = parseFloat(d[0][0]) - quotient;
            // remainder = Math.ceil(remainder * 1000);
            // vm.measureTooltipElement.innerHTML =
            //   quotient + "." + (remainder + "").substring(0, 2);
            if (parseFloat(d[0][0]) < 0) {
              let val = (parseFloat(d[0][0]) + "").substring(0, 5);
              if (val === "-0.0" || val === "-0.00") val = "0.00";
              vm.measureTooltipElement.innerHTML = val;
            } else {
              if (vm.measureTooltipElement) {
                vm.measureTooltipElement.innerHTML = (
                  parseFloat(d[0][0]) + ""
                ).substring(0, 4);
              }
            }
          } else {
            if (vm.measureTooltipElement)
              vm.measureTooltipElement.innerHTML = "NaN";
          }
          vm.measureTooltip.setPosition(data.evt.coordinate);
        }

        // vm.measureTooltipElement.innerHTML = toLonLat(data.evt.coordinate).join(
        //   ","
        // );
        // vm.measureTooltip.setPosition(data.evt.coordinate);
        if (data.id != this.mapId) {
          if (
            this.layerPointMove &&
            Object.keys(this.layerPointMove).length > 0 &&
            this.layerPointMove.getSource().getFeatures().length === 0
          ) {
            let feature = new Feature({
              geometry: new Point(data.evt.coordinate),
            });
            feature.id_ = this.mapId;
            this.layerPointMove.getSource().addFeature(feature);
          } else {
            let feature = this.layerPointMove
              .getSource()
              .getFeatureById(this.mapId);
            if (feature && Object.keys(feature).length > 0) {
              feature.setGeometry(new Point(data.evt.coordinate));
            }
          }
        }
      } else {
        if (this.measureTooltipElement) {
          vm.clearMeasureTooltip();
        }
      }
    },
    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
      }
      this.measureTooltipElement = document.createElement("div");
      this.measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [1, -16],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
        id: this.mapId,
      });
      this.map.addOverlay(this.measureTooltip);
    },
    clearMeasureTooltip() {
      this.measureTooltipElement = null;
      if (this.measureTooltip) this.map.removeOverlay(this.measureTooltip);
      if (this.layerPointMove) {
        this.layerPointMove.getSource().clear();
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    async initLayers() {
      if (this.configJson && Object.keys(this.configJson).length > 0) {
        if (this.configJson.layers && this.configJson.layers.length > 0) {
          for (let i = 0; i < this.configJson.layers.length; i++) {
            let layer = undefined;
            let type = Object(this.configJson.layers[i])["type"];
            switch (type) {
              case "TILE_LAYER":
                layer = await this.registerTileLayer(this.configJson.layers[i]);
                break;
              case "SHAPE_FILE":
                layer = await this.registerShapeFile(this.configJson.layers[i]);
                break;
              case "JSON_FILE":
                layer = await this.registerJsonFile(this.configJson.layers[i]);
                break;
              case "WMS_LAYER":
                layer = await this.registerWmsLayer(this.configJson.layers[i]);
                break;
              case "ARCGIS_MAPSERVICE":
                layer = await this.registerArcgisMapservice(
                  this.configJson.layers[i]
                );
                break;
            }
            if (layer) {
              layer.set("id", this.configJson.layers[i].id);
              layer.setZIndex(i + 1);
              this.map.addLayer(layer);
            }
          }
        }
      }
    },

    registerTileLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          if (layer.title === "OpenStreetMap") {
            resolve(
              new TileLayer({
                source: new OSM(),
                opacity: Object(layer).opacity,
                visible: Object(layer).show,
              })
            );
          } else {
            var obj = {
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileImage({
                url: Object(layer).source.url,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
              visible: Object(layer).show,
            };
            resolve(new TileLayer(obj));
          }
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerShapeFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let vector = new VectorLayer({
            source: vectorSource,
            style: [
              new Style({
                image: new CircleStyle({
                  fill: new Fill({
                    color: "rgba(255,0,0,0.2)",
                  }),
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 1.25,
                  }),
                  radius: 5,
                }),
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
              }),
            ],
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerJsonFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let style = null;
          if (!layer.icon) {
            style = new Style({
              image: new CircleStyle({
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
                radius: 5,
              }),
              fill: new Fill({
                color: "rgba(255,0,0,0.2)",
              }),
              stroke: new Stroke({
                color: "#FF0000",
                width: 1.25,
              }),
            });
          } else {
            style = new Style({
              image: new Icon({
                size: [16, 16],
                offset: [0, 0],
                opacity: 1,
                scale: 1.0,
                src: layer.icon,
              }),
            });
          }
          let vector = new VectorLayer({
            source: vectorSource,
            style: [style],
            id: layer.id,
          });
          resolve(vector);
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
    registerWmsLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          var obj = {
            title: Object(layer)["title"],
            id: Object(layer).id,
            source: new ImageWMS({
              url: Object(layer).source.url,
              params: Object(layer).source.params,
            }),
            show: Object(layer).show,
            opacity: Object(layer).opacity,
          };
          resolve(new XYZ(obj));
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      });
    },
    registerArcgisMapservice(layer) {
      try {
        return new Promise(function (resolve, reject) {
          resolve(
            new TileLayer({
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileArcGISRest({
                url: layer.source.url,
                params: layer.source.params,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
            })
          );
        });
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    configJson() {
      return this.$store.getters.getDataJson;
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss">
.splitview-map {
  position: absolute;
  height: 100%;
  width: 100%;
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 1;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: rgba(0, 0, 0, 5);
    color: #c1cfdb;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: rgba(0, 0, 0, 5);
  }
}
.splitview-legend {
  position: absolute;
  right: 0.75rem;
  bottom: 3.875rem;
  background: rgba(17, 20, 24, 0.8);
  // height: 50px;
  min-width: 200px;
  border-radius: 0.5rem;
}
.splitview-times-scroll {
  background: #111418;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
