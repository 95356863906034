<template>
  <div>
    <div class="field-list__header">
      <div class="header-top d-flex align-items-center">
        <div class="header-back">
          <button class="btn btn-back" @click="back2MainMap">
            <span class="button-icon">
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="100%"
                  height="100%"
                  viewBox="0 0 16 16"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <defs>
                    <path
                      id="1195a"
                      d="M1596 23h-12.17l5.59-5.59L1588 16l-8 8 8 8 1.41-1.41-5.58-5.59H1596z"
                    ></path>
                  </defs>
                  <g>
                    <g transform="translate(-1580 -16)">
                      <use fill="" xlink:href="#1195a"></use>
                    </g>
                  </g></svg
              ></span>
            </span>
          </button>
        </div>
        <div class="header-label">
          <p class="field-name title">Field</p>
        </div>
      </div>
    </div>
    <div
      class="field-list__content"
      :style="{ height: propHeight - 50 + 'px' }"
    >
      <div class="field-info">
        <FieldItem
          :prop-map="propMap"
          :prop-field="propField"
          @editField="editField"
          @removeField="removeField"
          ref="fieldItem"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FieldItem from "./fieldlistcontent/FieldItem.vue";
export default {
  components: {
    FieldItem,
  },
  props: {
    propHeight: {
      type: Number,
      default: 0,
    },
    propMap: {
      type: Object,
    },
    propField: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changPreview(data) {
      if (this.$refs.fieldItem) {
        this.$refs.fieldItem.changPreview(data.coordinates);
      }
    },
    editField(id) {
      this.$emit("editField", id);
    },
    removeField(id) {
      this.$emit("removeField", id);
    },
    back2MainMap() {
      this.$router.push({
        name: "Monitoring",
      });
    },
    saveFields() {
      this.$emit("saveFields");
    },
    cancelDrawing() {
      this.$emit("cancelDrawing");
    },
  },
};
</script>
<style lang="scss" scoped>
.field-list__header {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 16px;
  color: white;
  font-size: 20px;
  text-transform: capitalize;
  border-bottom: 1px solid #010203;
  .header-top {
    width: 100%;
    height: 49px;
    .header-back {
      button {
        color: #8296aa;
        border: 0;
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 18px;
          height: 18px;
        }
      }
    }
    .header-label {
      display: block;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .title {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;
        text-align: center;
      }
      .field-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .area-info {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        color: white;
        letter-spacing: 0.4px;
        text-transform: lowercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        margin: 0;
      }
    }
  }
}
.field-list__content {
  overflow-y: auto;
  .field-info {
    display: flex;
    position: relative;
    padding: 8px 12px 12px 16px;
    background: #212930;
    border-bottom: 1px solid #010203;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #323d49;
      cursor: default;
    }
  }
}
</style>