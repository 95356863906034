import axios from "axios";
export default {
    async getSessions() {
        const object = axios({
            method: "get",
            url: "http://watch.pcccr.vn/fireWatch/sessions",
        });
        let response = await object;
        return response;
    },
    async getFirePoints(data) {
        const object = axios({
            method: "post",
            url: "http://watch.pcccr.vn/map/firePoints.json",
            data: data,
        });
        let response = await object;
        return response;
    },
    async getParcel(params){
        const object = axios({
            method: "get",
            url: "http://watch.pcccr.vn/fireWatch/getParcel",
            params: params,
        });
        let response = await object;
        return response;
    }
}