<template>
  <div
    :style="{ top: (documentHeight - 3 * 126) / 2 + 4 + 'px' }"
    class="widget-layers animate__animated"
    :class="propShow ? 'animate__fadeInLeft' : 'animate__fadeOutLeft'"
  >
    <div class="widget-layers__header">
      <div class="widget-layers__header--title">
        <span>Bản đồ</span>
      </div>
      <div class="widget-layers__header--icons">
        <button class="btn-close btn-icon" @click="hide">
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
    <div
      class="widget-layers__body"
      :style="{
        height: documentHeight - (documentHeight - 3 * 126) / 2 - 130 + 'px',
      }"
    >
      <ul class="basemaps-select-list">
        <li class="select-list-item" v-for="layer in mapLayers" :key="layer.id">
          <input
            class="styled-checkbox"
            :id="layer.id"
            type="checkbox"
            :value="layer.id"
            v-model="selected"
          />
          <label :for="layer.id">{{ layer.title }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      mapLayers: [],
    };
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.layers) {
    //     for (let i = 0; i < this.layers.length; i++) {
    //       if (this.layers[i].show) this.selected.push(this.layers[i].id);
    //     }
    //   }
    // }, 1000);
    setTimeout(() => {
      if (this.layers) {
        this.mapLayers = Object.assign(
          JSON.parse(JSON.stringify(this.layers)),
          []
        );
        for (let i = 0; i < this.layers.length; i++) {
          if (this.layers[i].show) this.selected.push(this.layers[i].id);
        }
      }
    }, 1000);
  },
  watch: {
    selected(newVal, oldVal) {
      if (newVal === oldVal) return;
      // visible
      var difference1 = newVal.filter((x) => !oldVal.includes(x));
      // disible
      var difference2 = oldVal.filter((x) => !newVal.includes(x));
      this.$emit("visibleLayers", difference1);
      this.$emit("hideLayers", difference2);
    },
  },
  methods: {
    hide() {
      this.$emit("close");
    },
    addLayer2Widget(layer) {
      this.mapLayers.push({
        id: layer.id,
        title: layer.title,
        show: layer.show,
        opacity: layer.opacity,
      });
      if (layer.show) this.selected.push(layer.id);
    },
  },

  computed: {
    configJson() {
      return this.$store.getters.getDataJson;
    },
    layers() {
      let result = [];
      if (this.configJson && Object.keys(this.configJson).length > 0) {
        result = this.configJson.layers ? this.configJson.layers : [];
      }
      return result;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.widget-layers {
  position: absolute;
  left: 3.5rem;
  width: 304px;
  height: auto;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  .widget-layers__header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom: 1px solid #ddd;
  }
  .widget-layers__header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .widget-layers__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .widget-layers__header--title {
    font-weight: 600;
  }
  .widget-layers__header--icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .btn-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    // border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    // margin-right: 0.5rem;
    i {
      font-size: 1.125rem;
      color: black;
    }
  }
  .btn-icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  .btn-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .widget-layers__body {
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    padding: 0.5rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    overflow-y: auto;
    .basemaps-select-list {
      list-style: none;
      margin: 0;
      padding: 0;
      .select-list-item {
        background: white;
        padding: 0.5rem;
        border-radius: 0.125rem;
        margin-top: 0.375rem;
        &:first-child {
          margin: 0;
        }
        label {
          padding-left: 0.5rem;
        }
        .styled-checkbox {
          position: absolute; // take it out of document flow
          opacity: 0; // hide it

          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
          }

          // Box.
          & + label:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 16px;
            height: 16px;
            background: white;
            border: 1px solid #ddd;
          }
          // Box checked
          &:checked + label:before {
            background: $background-color;
          }

          // Disabled state label.
          &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
          }

          // Disabled box.
          &:disabled + label:before {
            box-shadow: none;
            background: #ddd;
          }

          // Checkmark. Could be replaced with an image
          &:checked + label:after {
            content: "";
            position: absolute;
            left: 3px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
              4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
</style>