const SET_FIELDS_UPLOAD = "SET_FIELDS_UPLOAD"
export default {
    state: {
        fieldsUpload:[]
    },    
    getters: {
        getFieldsUpload:state=>{
            return state.fieldsUpload
        }
    },    
    mutations: {        
        [SET_FIELDS_UPLOAD](state,payload) {
            state.fieldsUpload = payload;
        }
    },    
    actions: {
        setFieldsUpload({ commit }, payload) {
            commit(SET_FIELDS_UPLOAD, payload);
        },
    },
}