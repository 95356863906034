var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"legend-container"},[_c('div',{staticClass:"legend-header"},[_c('div',{staticClass:"d-flex align-items-center",class:this.propBand === 'WILD FIRES' ? 'no-actions' : ''},[_c('div',{staticClass:"selected-band"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"band-name",attrs:{"ripple":false,"color":"#fff","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.propBand)+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"dark":""}},_vm._l((_vm.items.filter(function (x) { return x.visible; })),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.bandClick(item.value)}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])],1)}),1)],1)],1),(this.propBand != 'WILD FIRES')?_c('div',{staticClass:"actions"},[_c('div',[_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.propContrast.negative ? 'active' : '',attrs:{"color":"transparent","small":"","elevation":"0","icon":""},on:{"click":_vm.contrastClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-contrast-box ")])],1)]}}],null,false,2367700272)},[_c('span',[_vm._v("Contrast view")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"transparent","small":"","elevation":"0","icon":""},on:{"click":_vm.downloadClick}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"icon":""}},[_vm._v(" mdi-download ")])],1)]}}],null,false,3867430156)},[_c('span',[_vm._v("Download")])])],1),_c('div',[(_vm.buttons.expand)?_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.buttons.percent ? 'active' : '',attrs:{"color":"transparent","small":"","elevation":"0","icon":""},on:{"click":function($event){_vm.buttons.percent = !_vm.buttons.percent}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"icon":""}},[_vm._v(" mdi-percent ")])],1)]}}],null,false,1732355561)},[_c('span',[_vm._v("Percent")])]):_vm._e()],1),_c('div',[_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"transparent","small":"","elevation":"0","icon":""},on:{"click":function($event){_vm.buttons.expand = !_vm.buttons.expand}}},'v-btn',attrs,false),on),[(_vm.buttons.expand)?_c('v-icon',{attrs:{"icon":""}},[_vm._v(" mdi-arrow-collapse ")]):_c('v-icon',{attrs:{"icon":""}},[_vm._v(" mdi-arrow-expand ")])],1)]}}],null,false,2544391826)},[_c('span',[_vm._v(_vm._s(_vm.buttons.expand ? "Collapse" : "Expand"))])])],1)]):_vm._e()])]),(_vm.buttons.expand)?_c('div',{staticClass:"legend-body"},[_c('ul',{staticClass:"list-unstyled legend-of-scene d-flex flex-column"},[_c('li',{staticClass:"scene-legend"},[_c('table',[_c('tbody',_vm._l((_vm.legends),function(legend,index){return _c('tr',{key:'legend' + index},[_c('td',{staticClass:"square",style:({ backgroundColor: legend.background_color })}),_c('td',{staticClass:"edge-value"},[_vm._v(_vm._s(legend.label))]),_c('td',{staticClass:"description"},[_vm._v(_vm._s(legend.description))]),_c('td',{staticClass:"area"},[(_vm.buttons.percent)?_c('span',[_vm._v(_vm._s(_vm.percentFormat(legend.percent)))]):_c('span',[_vm._v(_vm._s(_vm.areaFormat(legend.area)))])])])}),0)])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }