<template>
  <div class="wrapper d-flex align-items-stretch">
    <nav id="sidebar" :class="isCollapse ? 'active' : ''">
      <div class="top-menu">
        <ul class="list-unstyled sidebar-menu">
          <li class="nav-item inactive">
            <button
              class="btn menu-button toggle"
              @click="isCollapse = !isCollapse"
            >
              <span class="button-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    height="100%"
                    viewBox="0 0 18 12"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <defs>
                      <path
                        id="xz6ca"
                        d="M1883 18v2h18v-2zm0 7h18v-2h-18zm0 5h18v-2h-18z"
                      ></path>
                    </defs>
                    <g>
                      <g transform="translate(-1883 -18)">
                        <g></g>
                        <g><use xlink:href="#xz6ca"></use></g>
                      </g>
                    </g></svg></span
              ></span>
            </button>
          </li>
          <li class="nav-item nav-item-notification inactive">
            <button class="btn menu-button notification">
              <span class="button-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      fill=""
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                    ></path></svg></span
              ></span>
            </button>
          </li>

          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    height="100%"
                    viewBox="0 0 18 19"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      id="2tgba"
                      transform="translate(-1547 -6447)"
                      d="M1550.26 6454l5.74 4.47 5.74-4.47-5.74-4.47zm-1.63 1.27L1547 6454l9-7 9 7-1.64 1.27-7.36 5.73zm14.74 2.53l1.63 1.27-9 7-9-7 1.62-1.26 7.37 5.73z"
                    ></path></svg></span
              ></span>
              <span class="button-text">Monitoring</span>
            </button>
          </li>
          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    height="100%"
                    viewBox="0 0 22 16"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <defs>
                      <path
                        id="iraya"
                        d="M1562.425 6510.433h-11.923a3.667 3.667 0 1 1 0-7.333 3.667 3.667 0 0 1 3.669 3.667h1.834a5.494 5.494 0 0 0-4.036-5.299c.927-1.228 2.385-2.035 4.036-2.035a5.052 5.052 0 0 1 5.044 5.042v.458h1.376a2.759 2.759 0 0 1 2.752 2.75 2.759 2.759 0 0 1-2.752 2.75zm.313-7.296a6.866 6.866 0 0 0-6.738-5.537 6.857 6.857 0 0 0-6.087 3.703 5.496 5.496 0 0 0 .587 10.964h11.917a4.585 4.585 0 0 0 4.583-4.584c0-2.42-1.88-4.381-4.263-4.546z"
                      ></path>
                    </defs>
                    <g>
                      <g transform="translate(-1545 -6497)">
                        <g></g>
                        <g><use xlink:href="#iraya"></use></g>
                      </g>
                    </g></svg></span
              ></span>
              <span class="button-text">Weather</span>
            </button>
          </li>
          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <g
                      id="black"
                      stroke="none"
                      stroke-width="1"
                      fill=""
                      fill-rule="evenodd"
                    >
                      <g
                        id="Group"
                        transform="translate(3.000000, 3.000000)"
                        fill=""
                        fill-rule="nonzero"
                      >
                        <rect
                          id="Rectangle"
                          x="16"
                          y="7"
                          width="2"
                          height="11"
                        ></rect>
                        <rect
                          id="Rectangle"
                          x="12"
                          y="9"
                          width="2"
                          height="9"
                        ></rect>
                        <rect
                          id="Rectangle"
                          x="8"
                          y="11"
                          width="2"
                          height="7"
                        ></rect>
                        <rect
                          id="Rectangle"
                          x="4"
                          y="13"
                          width="2"
                          height="5"
                        ></rect>
                        <path
                          d="M18,0 L18,2 C15.8400047,2 13.9821476,2.89892857 9.90928585,5.59641184 L8.81813419,6.31984729 C4.7666015,8.98303787 2.74525893,9.94489208 0.223840269,9.99768115 L0,10 L0,8 C2.15999534,8 4.01785235,7.10107143 8.09071415,4.40358816 L9.18186581,3.68015271 C13.2333985,1.01696213 15.2547411,0.0551079155 17.7761597,0.00231885058 L18,0 Z"
                          id="Path"
                        ></path>
                        <rect
                          id="Rectangle"
                          x="0"
                          y="15"
                          width="2"
                          height="3"
                        ></rect>
                      </g>
                    </g></svg></span
              ></span>
              <span class="button-text">Dashboard</span>
            </button>
          </li>
          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 20 18"
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      d="M18.3574 1C18.2576 0.999967 18.1591 1.02059 18.0698 1.06027L13.1983 3.22596L7.68972 1.04942C7.68072 1.046 7.67044 1.0454 7.66079 1.04199C7.63601 1.03375 7.61066 1.02689 7.58493 1.02143C7.55936 1.0153 7.53337 1.01051 7.50715 1.00713C7.45495 1.00331 7.40248 1.00331 7.35031 1.00713C7.32409 1.01051 7.2981 1.0153 7.27252 1.02143C7.2468 1.02689 7.22145 1.03375 7.19666 1.04199C7.18702 1.0454 7.17674 1.04597 7.16774 1.04942L1.38211 3.33511C1.14986 3.42678 1.00021 3.63149 1.00024 3.85736V16.4286C1.00036 16.6219 1.11038 16.8021 1.29273 16.9075C1.47511 17.013 1.70514 17.0293 1.90406 16.9509L7.42869 14.768L12.9533 16.9509C12.963 16.9543 12.9732 16.9509 12.9829 16.956C13.1385 17.0179 13.3172 17.0144 13.4695 16.9463C13.4798 16.9423 13.4914 16.9463 13.5016 16.94L18.6444 14.6543C18.8623 14.5575 19 14.3595 18.9999 14.1429V1.5717C19.0001 1.2561 18.7124 1.00013 18.3574 1ZM6.78587 13.7715L2.28593 15.5492V4.2288L6.78587 2.45109V13.7715ZM12.5715 15.5492L8.07155 13.7715V2.45109L12.5715 4.22877V15.5492ZM17.7143 13.7898L13.8572 15.504V4.21049L17.7143 2.49623V13.7898Z"
                    ></path></svg></span
              ></span>
              <span class="button-text">Interactive map</span>
            </button>
          </li>
          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span
                  ><svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H10C10.5523 21 11 20.5523 11 20V4C11 3.44772 10.5523 3 10 3H4ZM9 5H5V19H9V5Z"
                      fill=""
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 3C13.4477 3 13 3.44772 13 4V8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H14ZM19 5H15V7H19V5Z"
                      fill=""
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 11C13.4477 11 13 11.4477 13 12V20C13 20.5523 13.4477 21 14 21H20C20.5523 21 21 20.5523 21 20V12C21 11.4477 20.5523 11 20 11H14ZM19 13H15V19H19V13Z"
                      fill=""
                    ></path></svg
                ></span>
              </span>
              <span class="button-text">Zoning</span>
            </button>
          </li>
          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span
                  ><svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 18 20"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      d="M13.53 10.06L12.47 9L7.59 13.88L5.47 11.76L4.41 12.82L7.59 16L13.53 10.06ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z"
                      fill=""
                    ></path></svg
                ></span>
              </span>
              <span class="button-text">Field activity log</span>
            </button>
          </li>
          <li class="nav-item inactive">
            <button class="btn menu-button">
              <span class="button-icon">
                <span
                  ><svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      d="M21.0664 19.1328C20.2272 19.1328 19.5185 19.6733 19.2515 20.4219H13.8149C13.6201 19.8759 13.1905 19.4464 12.6445 19.2515V16.4592C15.8623 16.3633 19.0898 15.5459 19.0898 13.8906V11.957C19.0898 13.0269 15.9187 13.8906 12 13.8906C8.08128 13.8906 4.91016 13.0269 4.91016 11.957V13.8906C4.91016 15.5459 8.13768 16.3633 11.3555 16.4592V19.2515C10.8095 19.4464 10.3799 19.8759 10.1851 20.4219H4.74852C4.48148 19.6733 3.77283 19.1328 2.93359 19.1328C1.86743 19.1328 1 20.0002 1 21.0664C1 22.1326 1.86743 23 2.93359 23C3.77283 23 4.48148 22.4595 4.74852 21.7109H10.1851C10.4521 22.4595 11.1608 23 12 23C12.8392 23 13.5479 22.4595 13.8149 21.7109H19.2515C19.5185 22.4595 20.2272 23 21.0664 23C22.1326 23 23 22.1326 23 21.0664C23 20.0002 22.1326 19.1328 21.0664 19.1328Z"
                      fill=""
                    ></path>
                    <path
                      d="M19.0898 2.93359C19.0898 1.86575 15.9157 1 12 1C8.0843 1 4.91016 1.86575 4.91016 2.93359C4.91016 4.00143 8.0843 4.86719 12 4.86719C15.9157 4.86719 19.0898 4.00143 19.0898 2.93359Z"
                      fill=""
                    ></path>
                    <path
                      d="M19.0898 4.22266C19.0898 5.29251 15.9187 6.15625 12 6.15625C8.08128 6.15625 4.91016 5.29251 4.91016 4.22266V6.15625C4.91016 7.92233 8.58398 8.73438 12 8.73438C15.416 8.73438 19.0898 7.92233 19.0898 6.15625V4.22266Z"
                      fill=""
                    ></path>
                    <path
                      d="M4.91016 8.08984V10.0234C4.91016 11.7895 8.58398 12.6016 12 12.6016C15.416 12.6016 19.0898 11.7895 19.0898 10.0234V8.08984C19.0898 9.1597 15.9187 10.0234 12 10.0234C8.08128 10.0234 4.91016 9.1597 4.91016 8.08984Z"
                      fill=""
                    ></path></svg
                ></span>
              </span>
              <span class="button-text">Data manager</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="bottom-menu">
        <ul class="list-unstyled sidebar-menu">
          <li class="nav-item">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButtonUser"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="button-icon">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fit=""
                      preserveAspectRatio="xMidYMid meet"
                      focusable="false"
                    >
                      <defs>
                        <path
                          id="qkq3a"
                          d="M1404 7279c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm0-5c-1.94 0-3.5 1.56-3.5 3.5s1.56 3.5 3.5 3.5 3.5-1.56 3.5-3.5-1.56-3.5-3.5-3.5zm0 8.5c-1.46 0-4.93.59-6.36 2.33a7.95 7.95 0 0 1-1.64-4.83c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83-1.43-1.74-4.9-2.33-6.36-2.33zm-4.93 3.78c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78a7.893 7.893 0 0 1-4.93 1.72c-1.86 0-3.57-.64-4.93-1.72zM1404 7270c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z"
                        ></path>
                      </defs>
                      <g>
                        <g transform="translate(-1394 -7270)">
                          <g></g>
                          <g><use fill="" xlink:href="#qkq3a"></use></g>
                        </g>
                      </g>
                    </svg>
                  </span>
                </span>

                <span class="button-text">My account</span>
                <div class="arrow">
                  <span class="triangle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="8"
                      height="5"
                    >
                      <defs>
                        <path
                          id="triangle"
                          d="M400.706 7595.71l2.6 2.59c.4.39 1.02.39 1.4 0l2.6-2.59c.63-.63.18-1.71-.7-1.71h-5.18c-.9 0-1.33 1.08-.7 1.71z"
                        />
                      </defs>
                      <use
                        xlink:href="#triangle"
                        transform="translate(-400 -7594)"
                      />
                    </svg>
                  </span>
                </div>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButtonUser"
              >
                <li>
                  <div class="user">
                    <div class="user-avatar">
                      <div class="avatar-icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          fit=""
                          preserveAspectRatio="xMidYMid meet"
                          focusable="false"
                        >
                          <path
                            d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                            fill="var(--border-secondary)"
                          ></path>
                          <path
                            d="M41.463 38.034C40.419 37.209 39.147 36.615 37.767 36.327L31.188 35.007C30.498 34.875 30 34.257 30 33.537V32.028C30.423 31.434 30.822 30.645 31.239 29.82C31.563 29.181 32.052 28.218 32.295 27.969C33.627 26.631 34.914 25.128 35.313 23.19C35.685 21.372 35.319 20.418 34.89 19.65C34.89 17.733 34.83 15.333 34.377 13.587C34.323 11.223 33.894 9.894 32.814 8.73C32.052 7.905 30.93 7.713 30.027 7.56C29.673 7.5 29.184 7.416 29.004 7.32C27.405 6.456 25.824 6.033 23.937 6C19.986 6.162 15.129 8.676 13.503 13.158C12.999 14.523 13.05 16.764 13.092 18.564L13.053 19.647C12.666 20.403 12.285 21.363 12.66 23.187C13.056 25.128 14.343 26.634 15.699 27.99C15.921 28.218 16.422 29.19 16.752 29.832C17.175 30.654 17.577 31.44 18 32.031V33.54C18 34.257 17.499 34.875 16.806 35.01L10.221 36.33C8.85 36.621 7.57799 37.209 6.53699 38.034C6.21299 38.295 6.009 38.676 5.976 39.09C5.943 39.504 6.08099 39.909 6.35999 40.218C10.842 45.162 17.271 48 24 48C30.729 48 37.161 45.165 41.64 40.218C41.919 39.909 42.06 39.501 42.024 39.087C41.991 38.673 41.787 38.292 41.463 38.034Z"
                            fill="#313D49"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div class="user-info">
                      <div class="user-name">{{ username }}</div>
                      <div class="user-email">{{ username }}@gmail.com</div>
                      <div class="user-team">Team {{ username }}</div>
                    </div>
                  </div>
                </li>
                <li><a class="dropdown-item" href="#">Select an account</a></li>
                <li><a class="dropdown-item" href="#">Team management</a></li>
                <li>
                  <a class="dropdown-item" href="#">Api</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Contact us</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Setting</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">Upgrade plan</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="onLogout">Log out</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div
      id="content"
      :style="[
        isCollapse
          ? { width: 'calc(100% - 56px)' }
          : { width: 'calc(100% - 224px)' },
      ]"
    >
      <main><slot name="default"></slot></main>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    breadcrumbitems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },
      isCollapse: true,
      language: null,
      languages: [
        {
          code: "en",
          label: "English",
          icon: "",
          id: 1,
        },
        {
          code: "vi",
          label: "Vietnamese",
          icon: "",
          id: 2,
        },
      ],
      menuActive: null,
    };
  },
  created() {
    if (!this.lang) {
      if (this.languages.findIndex((x) => x.code === this.lang) >= 0)
        this.language = this.languages.find((x) => x.code === this.lang);
      else {
        this.language = this.languages.find((x) => x.code === "vi");
      }
    } else {
      this.language = this.languages.find((x) => x.code === this.lang);
    }
    this.$store.dispatch("setIsCollapseMenu", this.isCollapse);
  },
  watch: {
    $route: {
      handler(val) {},
      deep: true,
    },
    isCollapse(val) {
      this.$store.dispatch("setIsCollapseMenu", val);
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch("clearUser");
      this.$router.push({
        name: "Login",
      });
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    username() {
      return this.$store.getters.getUserName;
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.wrapper {
  width: 100%;
  background: #bbe6d6;
}
#sidebar {
  min-width: 224px;
  max-width: 224px;
  background-color: #212930;
  box-shadow: inset 7px 0 9px -7px rgba(1, 2, 3, 0.4);
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#sidebar.active {
  min-width: 56px;
  max-width: 56px;
  text-align: center;
}
.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#sidebar {
  &.active {
    li {
      button {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 100%;
        background: 0;
        border-style: none;
        border-radius: 50%;
        touch-action: auto !important;
        margin: 0 auto;
        text-align: center;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
          color: white;
        }
        .button-text {
          display: none;
        }
        span.button-icon {
          &:hover {
            background: transparent;
          }
        }
      }
      &.nav-item-notification {
        position: static;
      }
    }
  }
  ul {
    margin: 0;
    li {
      padding: 4px;
      button {
        display: flex;
        align-items: center;
        position: relative;
        background: 0;
        border-style: none;
        touch-action: auto !important;
        margin: 0 auto;
        width: 100%;

        &.notification {
          margin: 0;
          width: auto;
        }
        &.toggle {
          margin: 0;
          width: auto;
        }
        .button-text {
          display: block;
          color: #c1cfdb;
          font-size: 14px;
          &:hover {
            color: white;
          }
        }
        span.button-icon {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 34px;
          height: 34px;
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 0.2s ease-in-out;
            z-index: -1;
          }
          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #8296aa;
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            height: 24px;
            width: 24px;
          }
          &:hover {
            background: transparent;
            display: inline-block;
            animation: swing ease-in-out 0.5s 1 alternate;
          }
        }
      }
      &.nav-item-notification {
        position: absolute;
        top: 0;
        left: 3.5rem;
      }
      &.active {
        button {
          span.button-icon {
            span {
              color: #3592fd;
            }
          }
          span.button-text {
            color: #3592fd;
          }
        }
      }
    }
  }
}
#content {
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
</style>
<style lang="scss">
#sidebar {
  .bottom-menu {
    button {
      &:hover {
        background: #313d49 !important;
        .arrow {
          background: #313d49;
        }
      }
    }
    .dropdown-toggle::after {
      top: 70%;
      right: 20%;
      color: #8296aa;
      display: none;
    }
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 22px;
      right: 6px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #212930;
      .triangle {
        color: #8296aa;
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
      }
    }
    .dropdown-menu {
      font-size: 14px;
      background-color: #010203;
      li {
        :hover {
          background-color: rgba(75, 92, 108, 0.4) !important;
          color: white !important;
        }
        .dropdown-item {
          color: #c1cfdb;
        }
        padding: 0;
        .user {
          display: flex;
          min-width: 200px;
          padding: 12px 16px;
          border-bottom: 1px solid #313d49;
          .user-avatar {
            width: 48px;
            height: 48px;
            margin: 4px 12px 0 0;
            .avatar-icon {
              width: 48px;
              height: 48px;
              background-color: #11151b;
              background-repeat: no-repeat;
              display: inline-block;
              fill: currentColor;
              border-radius: 50%;
            }
          }
          .user-info {
            display: flex;
            flex-direction: column;
            .user-name {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #ffffff;
              letter-spacing: 0.25px;
            }
            .user-email {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #8799ab;
              letter-spacing: 0.4px;
            }
            .user-team {
              max-width: 290px;
              word-break: break-all;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #ffffff;
              letter-spacing: 0.4px;
            }
          }
        }
      }
    }
  }
}
</style>