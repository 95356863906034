const ndvi = [{
    label: "-1.00 - 0.05",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(173, 0, 40)",
    min: -1,
    max: 0.05,
  },
  {
    label: "0.05 - 0.10",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(197, 20, 42)",
    min: 0.05,
    max: 0.1,
  },
  {
    label: "0.10 - 0.15",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(224, 45, 44)",
    min: 0.1,
    max: 0.15,
  },
  {
    label: "0.15 - 0.20",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(239, 76, 58)",
    min: 0.15,
    max: 0.2,
  },
  {
    label: "0.20 - 0.25",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(254, 108, 74)",
    min: 0.2,
    max: 0.25,
  },
  {
    label: "0.25 - 0.30",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(255, 141, 90)",
    min: 0.25,
    max: 0.3,
  },
  {
    label: "0.30 - 0.35",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(255, 171, 105)",
    min: 0.3,
    max: 0.35,
  },
  {
    label: "0.35 - 0.40",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(255, 198, 125)",
    min: 0.35,
    max: 0.4,
  },
  {
    label: "0.40 - 0.45",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(255, 224, 147)",
    min: 0.4,
    max: 0.45,
  },
  {
    label: "0.45 - 0.50",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(255, 239, 171)",
    min: 0.45,
    max: 0.5,
  },
  {
    label: "0.50 - 0.55",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(253, 254, 194)",
    min: 0.5,
    max: 0.55,
  },
  {
    label: "0.55 - 0.60",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(234, 247, 172)",
    min: 0.55,
    max: 0.6,
  },
  {
    label: "0.60 - 0.65",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(213, 239, 148)",
    min: 0.6,
    max: 0.65,
  },
  {
    label: "0.65 - 0.70",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(185, 227, 131)",
    min: 0.65,
    max: 0.7,
  },
  {
    label: "0.70 - 0.75",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(155, 216, 115)",
    min: 0.7,
    max: 0.75,
  },
  {
    label: "0.75 - 0.80",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(119, 202, 111)",
    min: 0.75,
    max: 0.8,
  },
  {
    label: "0.80 - 0.85",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(83, 189, 107)",
    min: 0.8,
    max: 0.85,
  },
  {
    label: "0.85 - 0.90",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(20, 170, 96)",
    min: 0.85,
    max: 9,
  },
  {
    label: "0.90 - 0.95",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(0, 151, 85)",
    min: 0.9,
    max: 0.95,
  },
  {
    label: "0.95 - 1.00",
    percent: 0,
    pixels: 0,
    area: 0,
    background_color: "rgb(0, 126, 71)",
    min: 0.95,
    max: 1,
  },]

  export default { ndvi }