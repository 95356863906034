import axios from "axios";
import basicApi from "@/utils/apis/basic";
import { endpointApiUrl } from "@/utils/commons/environment";
export default {
  async getNDVI(body) {
    let headers = basicApi.image.get_ndvi.headers;
    const object = axios({
      method: basicApi.image.get_ndvi.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_ndvi.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getNDMI(body) {
    let headers = basicApi.image.get_ndmi.headers;
    const object = axios({
      method: basicApi.image.get_ndmi.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_ndmi.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getNDWI(body) {
    let headers = basicApi.image.get_ndwi.headers;
    const object = axios({
      method: basicApi.image.get_ndwi.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_ndwi.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getMNDWI(body) {
    let headers = basicApi.image.get_mndwi.headers;
    const object = axios({
      method: basicApi.image.get_mndwi.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_mndwi.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getNDBI(body) {
    let headers = basicApi.image.get_ndbi.headers;
    const object = axios({
      method: basicApi.image.get_ndbi.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_ndbi.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getSAVI(body) {
    let headers = basicApi.image.get_savi.headers;
    const object = axios({
      method: basicApi.image.get_savi.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_savi.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getFDI(body) {
    let headers = basicApi.image.get_platic.headers;
    const object = axios({
      method: basicApi.image.get_platic.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_platic.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getVisual(body) {
    let headers = basicApi.image.get_visual.headers;
    const object = axios({
      method: basicApi.image.get_visual.method,
      url:
        endpointApiUrl /*process.env.VUE_APP_API_URL*/ +
        basicApi.image.get_visual.url,
      data: body,
      headers: headers,
    });
    let response = await object;
    return response;
  },
};
