var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monitoring-content"},[_c('OpenlayerMap',{ref:"openlayerMap",attrs:{"prop-fields":_vm.fields,"prop-measure-tool":_vm.measureTool,"prop-buttons-tool":_vm.buttonsTool},on:{"initMapSuccess":_vm.initMapSuccess,"measureEnd":_vm.measureEnd,"mapMoveend":_vm.mapMoveend}}),_c('div',{staticClass:"top-side"},[_c('SearchBox',{on:{"onSearch":_vm.onSearch,"pan2Location":_vm.onPan2Location}}),_c('MapTopPanel')],1),_c('div',{staticClass:"right-side"},[_c('RightSide',{attrs:{"prop-fields":_vm.fields,"prop-map":_vm.map},on:{"openDialogAddField":_vm.openDialogAddField}})],1),_c('div',{staticClass:"left-side",style:({ top: (_vm.documentHeight - 3 * 126) / 2 + 'px' })},[_c('LeftSide',{attrs:{"prop-buttons-tool":_vm.buttonsTool},on:{"leftActionClick":_vm.leftActionClick}})],1),(_vm.measureTool.value && !_vm.measureTool.active)?_c('div',{staticClass:"measure-result",style:([
      _vm.isShowRightSide && !_vm.isSmallDevice
        ? {
            left:
              (_vm.documentWidth - 304 - (_vm.isCollapseMenu ? 56 : 224) - 240) / 2 +
              'px',
          }
        : { left: (_vm.documentWidth - 240 - 56) / 2 } ])},[_c('div',{staticClass:"measure-popup"},[_c('div',{staticClass:"result-title d-flex justify-content-between align-items-center"},[_vm._v(" Measure distance "),_c('v-btn',{staticClass:"button-close",attrs:{"icon":"","color":"#8296aa"},on:{"click":_vm.onCloseMeasureResult}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"result-body"},[(_vm.measureTool.type === 'LineString')?_c('div',{staticClass:"text"},[_vm._v(" Total distance: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.measureTool.value)}})]):_vm._e(),(_vm.measureTool.type === 'Polygon')?_c('div',{staticClass:"text"},[_vm._v(" Total area: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.measureTool.value)}})]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"monitoring-dialog"},[_c('AddFieldDialg',{attrs:{"prop-show":_vm.dialog.addField.show,"prop-field-total":_vm.fields.length},on:{"close":function($event){_vm.dialog.addField.show = false},"drawField":_vm.onDrawField}}),_c('Pan2LocationDialg',{attrs:{"prop-show":_vm.dialog.pan2Location.show},on:{"close":function($event){_vm.dialog.pan2Location.show = false},"accept":_vm.onAcceptCoordinate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }