<template>
  <div class="legend-container">
    <div class="legend-header">
      <div
        class="d-flex align-items-center"
        :class="this.propBand === 'WILD FIRES' ? 'no-actions' : ''"
      >
        <div class="selected-band">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                color="#fff"
                v-bind="attrs"
                v-on="on"
                text
                class="band-name"
              >
                {{ propBand }}
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item
                v-for="(item, index) in items.filter((x) => x.visible)"
                :key="index"
                @click="bandClick(item.value)"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">{{
                      item.text
                    }}</v-list-item-title>
                  </template>
                  <span>{{ item.text }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="actions" v-if="this.propBand != 'WILD FIRES'">
          <div>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="contrastClick"
                  color="transparent"
                  small
                  elevation="0"
                  :class="propContrast.negative ? 'active' : ''"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon> mdi-contrast-box </v-icon>
                </v-btn>
              </template>
              <span>Contrast view</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  small
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="downloadClick"
                >
                  <v-icon icon> mdi-download </v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip top v-if="buttons.expand" open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :class="buttons.percent ? 'active' : ''"
                  @click="buttons.percent = !buttons.percent"
                  color="transparent"
                  small
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon icon> mdi-percent </v-icon>
                </v-btn>
              </template>
              <span>Percent</span>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="buttons.expand = !buttons.expand"
                  color="transparent"
                  small
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon icon v-if="buttons.expand">
                    mdi-arrow-collapse
                  </v-icon>

                  <v-icon icon v-else> mdi-arrow-expand </v-icon>
                </v-btn>
              </template>
              <span>{{ buttons.expand ? "Collapse" : "Expand" }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="legend-body" v-if="buttons.expand">
      <ul class="list-unstyled legend-of-scene d-flex flex-column">
        <li class="scene-legend">
          <table>
            <tbody>
              <tr v-for="(legend, index) in legends" :key="'legend' + index">
                <td
                  class="square"
                  :style="{ backgroundColor: legend.background_color }"
                ></td>
                <td class="edge-value">{{ legend.label }}</td>
                <td class="description">{{ legend.description }}</td>
                <td class="area">
                  <span v-if="buttons.percent">{{
                    percentFormat(legend.percent)
                  }}</span>
                  <span v-else>{{ areaFormat(legend.area) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propBand: {
      type: String,
      default: "",
    },
    propContrast: {
      type: Object,
      default: () => {},
    },
    propLegends: {
      type: Array,
      default: () => [],
    },
    propArea: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [
        {
          id: "NDVI",
          value: "NDVI",
          label: "NDVI",
          text: "NDVI",
          visible: true,
        },
        {
          id: "NDMI",
          value: "NDMI",
          label: "NDMI",
          text: "NDMI",
          visible: true,
        },
        {
          id: "NDWI",
          value: "NDWI",
          label: "NDWI",
          text: "NDWI",
          visible: true,
        },

        {
          id: "MNDWI",
          value: "MNDWI",
          label: "MNDWI",
          text: "MNDWI",
          visible: true,
        },
        {
          id: "NDBI",
          value: "NDBI",
          label: "NDBI",
          text: "NDBI",
          visible: true,
        },
        {
          id: "SAVI",
          value: "SAVI",
          label: "SAVI",
          text: "SAVI",
          visible: true,
        },
        {
          id: "FDI",
          value: "FDI",
          label: "FDI",
          text: "FDI",
          visible: true,
        },
        {
          id: "WILD_FIRES",
          value: "WILD FIRES",
          label: "WILD FIRES",
          text: "WILD FIRES",
          visible: true,
        },
        {
          id: "NATURE",
          value: "NATURE",
          label: "NATURE",
          text: "NATURE",
          visible: true,
        },
      ],
      legends: [
        {
          label: "-1.00 - 0.05",
          percent: 0,
          pixels: 0,
          background_color: "rgb(173, 0, 40)",
        },
        {
          label: "0.05 - 0.10",
          percent: 0,
          pixels: 0,
          background_color: "rgb(197, 20, 42)",
        },
        {
          label: "0.10 - 0.15",
          percent: 0,
          pixels: 0,
          background_color: "rgb(224, 45, 44)",
        },
        {
          label: "0.15 - 0.20",
          percent: 0,
          pixels: 0,
          background_color: "rgb(239, 76, 58)",
        },
        {
          label: "0.20 - 0.25",
          percent: 0,
          pixels: 0,
          background_color: "rgb(254, 108, 74)",
        },
        {
          label: "0.25 - 0.30",
          percent: 0,
          pixels: 0,
          background_color: "rgb(255, 141, 90)",
        },
        {
          label: "0.30 - 0.35",
          percent: 0,
          pixels: 0,
          background_color: "rgb(255, 171, 105)",
        },
        {
          label: "0.35 - 0.40",
          percent: 0,
          pixels: 0,
          background_color: "rgb(255, 198, 125)",
        },
        {
          label: "0.40 - 0.45",
          percent: 0,
          pixels: 0,
          background_color: "rgb(255, 224, 147)",
        },
        {
          label: "0.45 - 0.50",
          percent: 0,
          pixels: 0,
          background_color: "rgb(255, 239, 171)",
        },
        {
          label: "0.50 - 0.55",
          percent: 0,
          pixels: 0,
          background_color: "rgb(253, 254, 194)",
        },
        {
          label: "0.55 - 0.60",
          percent: 0,
          pixels: 0,
          background_color: "rgb(234, 247, 172)",
        },
        {
          label: "0.60 - 0.65",
          percent: 0,
          pixels: 0,
          background_color: "rgb(213, 239, 148)",
        },
        {
          label: "0.65 - 0.70",
          percent: 0,
          pixels: 0,
          background_color: "rgb(185, 227, 131)",
        },
        {
          label: "0.70 - 0.75",
          percent: 0,
          pixels: 0,
          background_color: "rgb(155, 216, 115)",
        },
        {
          label: "0.75 - 0.80",
          percent: 0,
          pixels: 0,
          background_color: "rgb(119, 202, 111)",
        },
        {
          label: "0.80 - 0.85",
          percent: 0,
          pixels: 0,
          background_color: "rgb(83, 189, 107)",
        },
        {
          label: "0.85 - 0.90",
          percent: 0,
          pixels: 0,
          background_color: "rgb(20, 170, 96)",
        },
        {
          label: "0.90 - 0.95",
          percent: 0,
          pixels: 0,
          background_color: "rgb(0, 151, 85)",
        },
        {
          label: "0.95 - 1.00",
          percent: 0,
          pixels: 0,
          background_color: "rgb(0, 126, 71)",
        },
      ],
      buttons: {
        percent: false,
        expand: false,
      },
    };
  },
  created() {},
  watch: {
    propLegends: {
      handler(val) {
        this.initLegends();
        if (val) {
          for (let i = 0; i < val.length; i++) {
            this.legends.push(val[i]);
            this.legends[i].area = this.propArea * val[i].percent;
            if (val[i].color) {
              if ((val[i].color + "").startsWith("#"))
                this.legends[i].background_color = val[i].color + "";
              else this.legends[i].background_color = "#" + (val[i].color + "");
            }
          }
          this.legends.reverse();
        }
      },
      immediate: true,
    },
  },
  methods: {
    downloadClick() {
      this.$emit("onDownload");
    },
    bandClick(val) {
      // if (val === "WILD FIRES") this.items[7].visible = false;
      // else this.items[7].visible = true;
      if (this.propBand != val) this.$emit("selectBandChange", val);
    },
    contrastClick() {
      this.propContrast.positive = !this.propContrast.positive;
      this.propContrast.negative = !this.propContrast.negative;
      this.$emit("contrastChange");
    },
    convertToArea(val) {
      if (this.buttons.percent) return val.toFixed(2);
      else {
        return "ha";
      }
    },
    percentFormat(val) {
      let result = "";
      if (val) {
        try {
          result = parseFloat(val).toFixed(2) + " %";
        } catch (error) {}
      }
      return result ? result : val + " %";
    },
    areaFormat(val) {
      let result = "";
      if (val) {
        try {
          result = (parseFloat(val) / 100 / 10000).toFixed(2) + " ha";
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
      return result ? result : val;
    },
    initLegends() {
      this.legends = [];
      // for (let i = 0; i < this.legends.length; i++) {
      //   this.legends[i].area = 0;
      //   this.legends[i].pixels = 0;
      //   this.legends[i].percent = 0;
      // }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.legend-container {
  .selected-band {
    height: 50px;
    border-right: 1px solid rgba($color: #8799ab, $alpha: 0.7);
    button.band-name {
      height: 50px !important;
      font-size: 14px;
      font-weight: 400;
      .v-icon {
        font-size: 18px;
      }
    }
  }
  .no-actions {
    .selected-band {
      width: 100%;
      border-right: 0;
      button {
        width: 100%;
      }
    }
  }
  .actions {
    display: flex;
    button {
      height: 50px !important;
      width: 48px !important;
      font-size: 14px;
      font-weight: 400;
      .v-icon {
        font-size: 18px;
        color: white !important;
      }
      &.active {
        .v-icon {
          color: #005ce0 !important;
        }
      }
    }
  }

  .v-menu__content {
    .v-list {
      .v-list-item {
        min-height: 40px !important;
        .v-list-item__title {
          font-size: 14px !important;
        }
        &:hover {
          color: white;
          background: #323d49;
          cursor: pointer;
        }
      }
    }
  }
  .legend-body {
    border-top: 1px solid rgba($color: #8799ab, $alpha: 0.7);
    padding: 0.5rem 0;
    .legend-of-scene {
      margin: 0;
      table {
        margin: 0;
        width: 100%;
        tr {
          margin-bottom: 1px;
          td {
            padding-left: 0.25rem;
            &.square {
              padding: 0;
              width: 24px;
              height: 24px;
              border-bottom: 1px solid #323d49;
            }
            &.edge-value {
              padding-left: 0.5rem;
              color: $text-secondary-light;
            }
            &.description {
              color: $text-secondary-light;
            }
            &.area {
              width: 112px;
              color: $text-secondary-light;
              text-align: right;
              padding-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
