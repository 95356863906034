<template>
  <div>
    <div class="field-list__header">
      <div class="header-top d-flex align-items-center">
        <div class="header-back">
          <button class="btn btn-back" @click="back2MainMap">
            <span class="button-icon">
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="100%"
                  height="100%"
                  viewBox="0 0 16 16"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <defs>
                    <path
                      id="1195a"
                      d="M1596 23h-12.17l5.59-5.59L1588 16l-8 8 8 8 1.41-1.41-5.58-5.59H1596z"
                    ></path>
                  </defs>
                  <g>
                    <g transform="translate(-1580 -16)">
                      <use fill="" xlink:href="#1195a"></use>
                    </g>
                  </g></svg
              ></span>
            </span>
          </button>
        </div>
        <div class="header-label">
          <p class="field-name title">Fields Upload</p>
        </div>
      </div>
    </div>
    <div
      class="field-list__content"
      :style="{
        height:
          propHeight -
          (propFields && propFields.length > 0 ? 132 + 50 : 72 + 50) +
          'px',
      }"
    >
      <ul
        class="list-unstyled list-fields"
        v-if="propFields && propFields.length > 0"
      >
        <li class="item" v-for="field in propFields" :key="field.id">
          <FieldItem
            :prop-map="propMap"
            :prop-field="field"
            @removeField="removeField"
            @editField="editField"
            :ref="'fieldItem'"
            :dataid="field.id"
          />
        </li>
      </ul>
      <NoFields v-else />
    </div>
    <div class="field-list__footer" v-if="propFields && propFields.length > 0">
      <div class="footer-button d-flex flex-column">
        <button
          class="btn button-save"
          :class="isDisableButtonSave ? 'button-disabled' : ''"
          :disabled="isDisableButtonSave"
          @click="saveFields"
        >
          <span class="button-text">SAVE TO MY FIELDS</span>
        </button>
        <button class="btn button-cancel" @click="cancelDrawing">
          <span class="button-text">CANCEL UPLOAD</span>
        </button>
      </div>
    </div>
    <div class="field-list__footer" v-else>
      <div class="footer-button">
        <div class="upload-button">
          <div class="upload-btn-wrapper">
            <button class="btn button-add">
              <span class="button-icon">
                <span
                  ><svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 14 14"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
                      fill=""
                    ></path></svg
                ></span>
              </span>
              <span class="button-text">&nbsp;Add Field</span>
            </button>
            <input
              type="file"
              name="iconfile"
              accept=".kml, .kmz, .geojson, .zip"
              @change="filesChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FieldItem from "./fieldlistcontent/FieldItem.vue";
import NoFields from "./NoFields.vue";
export default {
  components: {
    FieldItem,
    NoFields,
  },
  props: {
    propHeight: {
      type: Number,
      default: 0,
    },
    propMap: {
      type: Object,
    },
    propFields: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changPreview(data) {
      if (this.$refs.fieldItem) {
        for (let i = 0; i < this.$refs.fieldItem.length; i++) {
          if (
            this.$refs.fieldItem[i].propField &&
            this.$refs.fieldItem[i].propField.id + "" === data.id + ""
          ) {
            this.$refs.fieldItem[i].changPreview(data.coordinates);
          }
        }
      }
    },
    editField(id) {
      this.$emit("editField", id);
    },
    removeField(id) {
      this.$emit("removeField", id);
    },
    back2MainMap() {
      this.$router.push({
        name: "Monitoring",
      });
    },
    saveFields() {
      if (this.isDisableButtonSave) {
        this.$store.dispatch(
          "setMessage",
          "Please reduce area size up to 10000 ha."
        );
        return;
      }
      this.$emit("saveFields");
    },
    cancelDrawing() {
      this.$emit("cancelDrawing");
    },
    filesChanged(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        this.$emit("filesChanged", files);
      }
    },
  },
  computed: {
    isDisableButtonSave() {
      return (
        this.propFields.length === 0 ||
        this.propFields.filter((x) => x.area > 10000 * 10000).length > 0 // default = m2. Convert to ha and check with 10000 ha
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.field-list__header {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 16px;
  color: white;
  font-size: 20px;
  text-transform: capitalize;
  border-bottom: 1px solid #010203;
  .header-top {
    width: 100%;
    height: 49px;
    // margin-bottom: 12px;
    .header-back {
      button {
        color: #8296aa;
        border: 0;
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 18px;
          height: 18px;
        }
      }
    }
    .header-label {
      display: block;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .title {
        margin: 10px 0 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;
        text-align: center;
      }
      .field-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .area-info {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        color: white;
        letter-spacing: 0.4px;
        text-transform: lowercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        margin: 0;
      }
    }
  }
}
.field-list__content {
  overflow-y: auto;
  .list-fields {
    li {
      display: flex;
      position: relative;
      padding: 8px 12px 12px 16px;
      background: #212930;
      border-bottom: 1px solid #010203;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .add-fields {
    display: block;
    padding: 0 12px;
    margin: 12px auto;
    &:hover {
      cursor: pointer;
    }
    .add-box {
      min-height: 108px;
      padding: 11px 0;
      display: flex;
      align-items: center;
      background: rgba(53, 146, 253, 0.1);
      border: 1px dashed #3592fd;
      -o-border-image: 1
        repeating-linear-gradient(
          45deg,
          #3592fd 0,
          #3592fd 2px,
          transparent 2px,
          transparent 5px
        );
      border-image: 1
        repeating-linear-gradient(
          45deg,
          #3592fd 0,
          #3592fd 2 px,
          transparent 2 px,
          transparent 5 px
        );
      border-radius: 8px;
      color: #3592fd;
      span.add-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        width: 72px;
        height: 72px;

        span {
          width: 100%;
        }
      }
    }
    .box-info {
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #3592fd;
        letter-spacing: 0.25px;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #3592fd;
        letter-spacing: 0.4px;
      }
    }
  }
}
.field-list__footer {
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, transparent 10%, #212930 90%);
  padding: 0.75rem 1rem;
  .footer-button {
    button {
      width: 100%;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 48px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      border-radius: 8px;
      margin-top: 0.75rem;
      border: 0;

      &.button-save {
        margin: 0;
        background: #3592fd;
        &:hover {
          background: #0477fa;
        }
      }
      &.button-disabled {
        background: rgba(75, 92, 108, 0.4) !important;
      }
      &.button-cancel {
        background: rgba(75, 92, 108, 0.4);
        &:hover {
          background: rgba(75, 92, 108, 1);
        }
      }
      &.button-add {
        margin-top: 0;
        background: #3592fd;
        &:hover {
          background: #0477fa;
        }
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 14px;
          height: 14px;
          span {
            width: 100%;
          }
        }
        span.button-text {
          padding-left: 0.5rem;
        }
      }
    }

    .upload-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;

      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      .upload-btn-wrapper input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>