import { render, staticRenderFns } from "./RightSide.vue?vue&type=template&id=dd5adf54&scoped=true&"
import script from "./RightSide.vue?vue&type=script&lang=js&"
export * from "./RightSide.vue?vue&type=script&lang=js&"
import style0 from "./RightSide.vue?vue&type=style&index=0&id=dd5adf54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd5adf54",
  null
  
)

export default component.exports