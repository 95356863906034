<template>
  <div>
    <div class="field-list__header">
      <div class="header-top d-flex justify-content-between align-items-center">
        <div class="header-filter">
          <button class="btn btn-filter">
            <span class="button-icon">
              <span
                ><svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 16 10"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                    fill=""
                  ></path></svg
              ></span>
            </span>
          </button>
        </div>
        <div class="header-label">
          <p class="field-name title">Field list</p>
          <p class="area-info">{{ areaFormat }}</p>
        </div>
        <div class="header-sort">
          <button class="btn btn-sort">
            <span class="button-icon">
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M3.665.5L.332 3.825h2.5v10.842H4.5V3.825H7L3.665.5zm7.5 11.675V1.333H9.5v10.842H7l3.333 3.325 3.333-3.325h-2.5z"
                  ></path></svg
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="field-list__content"
      id="fieldlist-content"
      :style="{ height: propHeight - 78 - 62 + 'px' }"
    >
      <ul class="list-unstyled list-fields d-flex flex-column">
        <li
          class="item"
          v-for="field in propFields"
          :key="field.id"
          @dblclick="go2AnalyticField(field.id)"
        >
          <FieldItem :prop-map="propMap" :prop-field="field" />
        </li>
      </ul>
      <div
        class="add-fields"
        @click="openDialogAddField"
        :data-value="documentHeight - 250 - propFields.length * 85 - 32"
        v-if="documentHeight - 250 - propFields.length * 85 - 32 > 0"
      >
        <div class="add-box">
          <div class="box-icon">
            <span class="add-icon">
              <span>
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M43.1578 52.0884L22.2801 55.4288L12.8357 3.05568L32.8509 0.553784L43.1578 52.0884Z"
                    stroke="currentColor"
                  ></path>
                  <path
                    d="M32.0832 28.5832H28.5832V32.0832H27.4165V28.5832H23.9165V27.4165H27.4165V23.9165H28.5832V27.4165H32.0832V28.5832Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </span>
          </div>
          <div class="box-info">
            <span class="title">Add your fields</span>
            <span class="text">
              Browse satellite images, vegetation indices, weather data and much
              more
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="field-list__footer">
      <div class="footer-button">
        <button class="btn" @click="openDialogAddField">
          <span class="button-icon">
            <span
              ><svg
                width="100%"
                height="100%"
                viewBox="0 0 14 14"
                fill=""
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill=""></path></svg
            ></span>
          </span>
          <span class="button-text">&nbsp;Add Field</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import FieldItem from "./fieldlistcontent/FieldItem.vue";
export default {
  components: {
    FieldItem,
  },
  props: {
    propHeight: {
      type: Number,
      default: 0,
    },
    propMap: {
      type: Object,
    },
    propFields: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openDialogAddField() {
      this.$emit("openDialogAddField");
    },
    go2AnalyticField(id) {
      this.$router.push({
        name: "AnalyticField",
        params: {
          id: id,
        },
      });
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    areaTotal() {
      return this.propFields.reduce(
        (sum, record) => sum + parseFloat(record["area"]),
        0
      );
    },
    areaFormat() {
      let result = "";
      if (this.areaTotal) {
        if (parseFloat(this.areaTotal) / 10000 < 0.5)
          result = this.areaTotal + " m²";
        else result = (parseFloat(this.areaTotal) / 10000).toFixed(2) + " ha";
      }
      return result ? result : "0 ha";
    },
  },
};
</script>
<style lang="scss" scoped>
.field-list__header {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 16px;
  color: white;
  font-size: 20px;
  text-transform: capitalize;
  border-bottom: 1px solid #010203;
  .header-top {
    width: 100%;
    height: 49px;
    margin-bottom: 12px;
    .header-filter {
      button {
        color: #8296aa;
        border: 0;
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 18px;
          height: 18px;
        }
      }
    }
    .header-label {
      display: block;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .title {
        margin: 10px 0 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.5px;
        text-align: center;
      }
      .field-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .area-info {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        color: white;
        letter-spacing: 0.4px;
        text-transform: lowercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        margin: 0;
      }
    }
    .header-sort {
      button {
        color: #8296aa;
        border: 0;
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
.field-list__content {
  overflow-y: auto;
  .list-fields {
    li {
      display: flex;
      position: relative;
      padding: 8px 12px 12px 16px;
      background: #212930;
      border-bottom: 1px solid #010203;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #323d49;
        cursor: pointer;
      }
    }
  }
  .add-fields {
    display: block;
    padding: 0 12px;
    margin: 12px auto;
    &:hover {
      cursor: pointer;
    }
    .add-box {
      min-height: 108px;
      padding: 11px 0;
      display: flex;
      align-items: center;
      background: rgba(53, 146, 253, 0.1);
      border: 1px dashed #3592fd;
      -o-border-image: 1
        repeating-linear-gradient(
          45deg,
          #3592fd 0,
          #3592fd 2px,
          transparent 2px,
          transparent 5px
        );
      border-image: 1
        repeating-linear-gradient(
          45deg,
          #3592fd 0,
          #3592fd 2 px,
          transparent 2 px,
          transparent 5 px
        );
      border-radius: 8px;
      color: #3592fd;
      span.add-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        width: 72px;
        height: 72px;

        span {
          width: 100%;
        }
      }
    }
    .box-info {
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #3592fd;
        letter-spacing: 0.25px;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #3592fd;
        letter-spacing: 0.4px;
      }
    }
  }
}
.field-list__footer {
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, transparent 10%, #212930 90%);
  padding: 14px 16px 16px 15px;
  .footer-button {
    button {
      width: 100%;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 48px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      border-radius: 8px;
      background-color: #0477fa;
      span.button-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        width: 14px;
        height: 14px;
        span {
          width: 100%;
        }
      }
      span.button-text {
        padding-left: 0.5rem;
      }
    }
  }
}
</style>