<template>
  <div>
    <div class="tools-map">
      <div
        class="tool-item"
        :class="propButtonsTool.layers.show ? 'active' : ''"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('LAYERS_MAP')"
            >
              <span class="button-icon layers-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    id="footer-sample-full"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                    class="iconify iconify--bx"
                  >
                    <path
                      d="M21.484 7.125l-9.022-5a1.003 1.003 0 0 0-.968 0l-8.978 4.96a1 1 0 0 0-.003 1.748l9.022 5.04a.995.995 0 0 0 .973.001l8.978-5a1 1 0 0 0-.002-1.749z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M12 15.856l-8.515-4.73l-.971 1.748l9 5a1 1 0 0 0 .971 0l9-5l-.971-1.748L12 15.856z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M12 19.856l-8.515-4.73l-.971 1.748l9 5a1 1 0 0 0 .971 0l9-5l-.971-1.748L12 19.856z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>{{ $t("tooltip_layers") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="tools-map">
      <div class="tool-item">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('ZOOM_IN')"
            >
              <span class="button-icon">
                <span>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 14 14"
                    fill=""
                    xmlns="http://www.w3.org/2000/svg"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill=""></path>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>{{ $t("tooltip_zoom_in") }}</span>
        </v-tooltip>
      </div>
      <div class="tool-item">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              class="btn"
              @click="activeTool('ZOOM_OUT')"
            >
              <span class="button-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="100%"
                    height="100%"
                    viewBox="0 0 14 2"
                    fit=""
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <defs><path id="A2" d="M43 447H29v-2h14z"></path></defs>
                    <use xlink:href="#A2" transform="translate(-29 -445)"></use>
                  </svg>
                </span>
              </span>
            </button>
          </template>
          <span>{{ $t("tooltip_zoom_out") }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    activeTool(code) {
      this.$emit("leftActionClick", {
        code: code,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
div {
  .tools-map {
    padding: 0.25rem;
    &:first-child {
      margin: 0;
    }
    .tool-item {
      display: block;
      text-align: center;
      height: 2.5rem;
      width: 2.5rem;
      border: none;
      margin-bottom: 0.025rem;
      &:last-child {
        margin-bottom: 0;
        button {
          border-bottom-right-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }
      &:first-child {
        button {
          border-top-right-radius: 0.5rem;
          border-top-left-radius: 0.5rem;
        }
      }
      &.active {
        button {
          color: #005ce0;
        }
      }
      button {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 1;
        padding: 0;
        color: white;
        background: #111418;
        border-radius: 0;
        border: 0;
        span.button-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          width: 14px;
          height: 14px;
          &.measure-icon,
          &.layers-icon,
          &.split-icon,
          &.location-icon {
            width: 20px;
            height: 20px;
          }
        }
        &:hover {
          background: #212930;
        }
      }
    }
  }
}
</style>