import axios from "axios";
export default {
    async getDataLabels(x,y,z) {
        let headers = {};
        const object = axios({
            method: "get",
            url: process.env.VUE_APP_API_WINDY_LABELS +
                +z+"/"+x+"/"+y+".json",
            headers: headers,
        });
        let response = await object;
        return response;
    },
    async getDataForecast(x,y,z) {
        let headers = {};
        const object = axios({
            method: "get",
            url: process.env.VUE_APP_API_WINDY_FORECAST +
                +z+"/"+x+"/"+y,
            headers: headers,
        });
        let response = await object;
        return response;
    }
}