var UTEST = {
    R: 6378137,
    MAX_LATITUDE: 85.0511287798,
    project: function(t) {
        var i = Math.PI / 180,
            e = this.MAX_LATITUDE,
            n = Math.max(Math.min(e, t.lat), -e),
            o = Math.sin(n * i);
        return new E(this.R * t.lng * i, (this.R * Math.log((1 + o) / (1 - o))) / 2);
    },
    unproject: function(t) {
        var i = 180 / Math.PI;
        return new N((2 * Math.atan(Math.exp(t.y / this.R)) - Math.PI / 2) * i, (t.x * i) / this.R);
    },
    clone: function() {
        return new E(this.x, this.y);
    },
    add: function(t) {
        return this.clone()._add(A(t));
    },
    _add: function(t) {
        return (this.x += t.x), (this.y += t.y), this;
    },
    subtract: function(t) {
        return this.clone()._subtract(A(t));
    },
    _subtract: function(t) {
        return (this.x -= t.x), (this.y -= t.y), this;
    },
    divideBy: function(t) {
        return this.clone()._divideBy(t);
    },
    _divideBy: function(t) {
        return (this.x /= t), (this.y /= t), this;
    },
    multiplyBy: function(t) {
        return this.clone()._multiplyBy(t);
    },
    _multiplyBy: function(t) {
        return (this.x *= t), (this.y *= t), this;
    },
    scaleBy: function(t) {
        return new E(this.x * t.x, this.y * t.y);
    },
    unscaleBy: function(t) {
        return new E(this.x / t.x, this.y / t.y);
    },
    round: function() {
        return this.clone()._round();
    },
    _round: function() {
        return (this.x = Math.round(this.x)), (this.y = Math.round(this.y)), this;
    },
    floor: function() {
        return this.clone()._floor();
    },
    _floor: function() {
        return (this.x = Math.floor(this.x)), (this.y = Math.floor(this.y)), this;
    },
    ceil: function() {
        return this.clone()._ceil();
    },
    _ceil: function() {
        return (this.x = Math.ceil(this.x)), (this.y = Math.ceil(this.y)), this;
    },
    trunc: function() {
        return this.clone()._trunc();
    },
    _trunc: function() {
        return (this.x = B(this.x)), (this.y = B(this.y)), this;
    },
    distanceTo: function(t) {
        var i = (t = A(t)).x - this.x,
            e = t.y - this.y;
        return Math.sqrt(i * i + e * e);
    },
    equals: function(t) {
        return (t = A(t)).x === this.x && t.y === this.y;
    },
    contains: function(t) {
        return (t = A(t)), Math.abs(t.x) <= Math.abs(this.x) && Math.abs(t.y) <= Math.abs(this.y);
    },
    toString: function() {
        return "Point(" + l(this.x) + ", " + l(this.y) + ")";
    },
    _getNewPixelOrigin: function(t, i) {

        var e = this.getSize()._divideBy(2);
        return this.project(t, i)._subtract(e)._add(this._getMapPanePos())._round()
    },
    _transform: function(t, i) {
        let _b = .5;
        let _d = .5;
        let _a = .5 / (Math.PI * 6378137);
        let _c = -1 * .5 / (Math.PI * 6378137);
        return (i = i || 1), (t.x = i * (_a * t.x + _b)), (t.y = i * (_c * t.y + _d)), t;
    },
};

function getOffsetY(o, t) {
    return (o * t) | 0
}

function getOffsetX(r, t) {
    return (r * t) | 0
}

function getLShift(t) {
    return Math.log2(t * t)
}

function getShift(t) {
    return Math.log2(t)
}

function getTrans(e, t) {
    return Math.pow(2, e) /
        Math.pow(2, t);
}

function sortTiles(e, t, n) {
    for (
        var i,
            s,
            r = this,
            o = function(n, i) {
                var s = wrapCoords({ x: n, y: i, z: e.zoom });
                return whichTile(s, t);
            },
            l = [],
            c = e.origTiles.minY; c <= e.origTiles.maxY; c++
    ) {
        var d = o(e.origTiles.minX, c);
        if (!d || d.y !== s) {
            i = null;
            for (
                var u = [],
                    h = function(e) {
                        var t = o(e, c);
                        if (t && t.x !== i) {
                            var a = n.filter(function(e) {
                                return e.x === t.x && e.y === t.y;
                            })[0];
                            u.push(a), (i = t.x), (s = t.y);
                        }
                    },
                    f = e.origTiles.minX; f <= e.origTiles.maxX; f++
            )
                h(f);
            u.length > 0 && l.push(u);
        }
    }
    return l;
}

function loadImage(e, i) {
    var s = {
            limit: 50,
            size: 1
        },
        r = 0,
        o = 6,
        l = document.createElement("canvas"),
        c = l.getContext("2d");
    return (
        (this.status = "loading"),
        (this.promise = new Promise(function(t, n) {
            var s = new Image();
            (s.crossOrigin = "Anonymous"),
            (s.onload = function() {
                (l.width = s.width), (l.height = s.height), c.drawImage(s, 0, 0, s.width, s.height);
                var n = c.getImageData(0, 0, s.width, s.height);
                (e.data = n.data), (e.status = "loaded");
                var i = (function(e, t) {
                        var n,
                            i,
                            a,
                            s,
                            r = new ArrayBuffer(28),
                            o = new Uint8Array(r),
                            l = new Float32Array(r),
                            c = 4 * t * 4 + 8;
                        for (s = 0; s < 28; s++)(n = e[c]), (i = e[c + 1]), (a = e[c + 2]), (n = Math.round(n / 64)), (i = Math.round(i / 16)), (a = Math.round(a / 64)), (o[s] = (n << 6) + (i << 2) + a), (c += 16);
                        return l;
                    })(e.data, 257),
                    a = i[0],
                    o = (i[1] - i[0]) / 255,
                    d = i[2],
                    u = (i[3] - i[2]) / 255,
                    h = i[4],
                    f = (i[5] - i[4]) / 255;
                (e.headerPars = [o, a, u, d, f, h]),
                (e.decodeR = e.transformR ?

                    function(t) {
                        return e.transformR(t * o + a);
                    } :
                    function(e) {
                        return e * o + a;
                    }),
                (e.decodeG = e.transformG ?

                    function(t) {
                        return e.transformG(t * u + d);
                    } :
                    function(e) {
                        return e * u + d;
                    }),
                (e.decodeB = e.transformB ?

                    function(t) {
                        return e.transformB(t * f + h);
                    } :
                    function(e) {
                        return e * f + h;
                    }),
                (r = 0),
                t(e);
            }),
            (s.onerror = function() {
                (e.status = "failed"), a.emit("loadingFailed", e.url), ++r > o && (a.emit("noConnection"), (r = 0)), n("Failed to load tile");
            }),
            (s.src = e.url),
            (s.complete || void 0 === s.complete) && ((s.src = i.emptyGIF), (s.src = e.url));
        })),
        this.promise
    );
}


function whichTile(e, t) {
    var
        i = {
            extreme: [0, 0, 1, 2, 3, 4, 4, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
            ultra: [0, 0, 0, 2, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            high: [0, 0, 0, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
            normal: [0, 0, 0, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
            low: [0, 0, 0, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
        },
        a = function(e) {
            return Math.pow(2, e);
        },
        s = function(e, t) {
            return a(e) / a(t);
        },
        r = Object.keys(i),
        o = function(e, t) {
            if (e.dataTilesZoom) return e.dataTilesZoom;

            var n = e.dataQuality,
                a = e.upgradeDataQuality ? r[Math.max(r.indexOf(n) - 1, 0)] : n;
            return Math.min(e.maxTileZoom, i[a][t]);
        }
    if (!t.fullPath) return null;
    var n = e.z,
        i = o(t, n),
        r = s(n, i),
        l = Math.floor(e.x / r),
        c = Math.floor(e.y / r),
        d = e.x % r,
        u = e.y % r,
        h = t.fullPath.replace("<z>", i.toString()).replace("<y>", c.toString()).replace("<x>", l.toString()),
        f = a(i);
    return l < 0 || c < 0 || l >= f || c >= f ? null : { url: h, x: l, y: c, z: i, intX: d, intY: u, trans: r, transformR: t.transformR || null, transformG: t.transformG || null, transformB: t.transformB || null };
}

function testJPGtransparency(e, t) {
    return !!(192 & e[t + 2] || 192 & e[t + 6] || 192 & e[t + 1030] || 192 & e[t + 1034]);
}

function testPNGtransparency(e, t) {
    return !(e[t + 3] && e[t + 7] && e[t + 1028 + 3] && e[t + 1028 + 7]);

}

function interpolateNearest(e, t, n, i, a, s, r, o, l, c) {
    null !== e && ((r = e[t]), (o = e[t + 1]), (l = e[t + 2]), (c = e[t + 3]));
    var d = Math.max(r, o, l, c);
    return d === r ? n : d === o ? i : d === l ? a : s;
}

function testN(e, t) {
    var n = t.supportedLanguages,
        i = "bcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789a",
        a = i.split(""),
        s = function(e) {
            var t = "";
            do {
                (t = i.charAt(e % 60) + t), (e = Math.floor(e / 60));
            } while (e);
            return t;
        },
        r = function(e) {
            for (var t = 0, n = 0; n < e.length; n++) t = 60 * t + a.indexOf(e.charAt(n));
            return t;
        },
        o = function(e, t) {
            for (var n in e) t(e[n], n);
        },
        l = function(e, t) {
            if (null === e) return e;
            if (e instanceof Date) return new Date(e.getTime());
            if (e instanceof Array) {
                var n = [];
                return (
                    e.forEach(function(e) {
                        n.push(e);
                    }),
                    n.map(function(e) {
                        return l(e);
                    })
                );
            }
            if ("object" == typeof e && e !== {}) {
                var i = Object.assign({}, e);
                return (
                    Object.keys(i).forEach(function(e) {
                        (t && !t.includes(e)) || (i[e] = l(i[e]));
                    }),
                    i
                );
            }
            return e;
        },
        c = function(e) {
            return "number" == typeof e.dir && "number" == typeof e.wind && e.dir <= 360 && e.wind > 0;
        },
        d = function(e, t, n) {
            return Math.max(Math.min(e, n), t);
        },
        u = function(e) {
            return (Math.PI - Math.log(Math.tan(0.5 * (1 - e) * Math.PI))) / (2 * Math.PI);
        },
        h = 0,
        f = function(e, t, n, i, a) {
            return (0.5 * -e + 3 * t * 0.5 - 3 * n * 0.5 + 0.5 * i) * a * a * a + (e - 5 * t * 0.5 + 2 * n - 0.5 * i) * a * a + (0.5 * -e + 0.5 * n) * a + t;
        },
        p = function(e, t, n) {
            return e + n * (t - e);
        };
    (e.tsMinute = 6e4),
    (e.tsHour = 36e5),
    (e.num2char = s),
    (e.char2num = r),
    (e.latLon2str = function(e) {
        var t = Math.floor(100 * (e.lat + 90)),
            n = Math.floor(100 * (e.lon + 180));
        return s(t) + "a" + s(n);
    }),
    (e.str2latLon = function(e) {
        var t = e.split("a");
        return { lat: r(t[0]) / 100 - 90, lon: r(t[1]) / 100 - 180 };
    }),
    (e.toggleClass = function(e, t, n) {
        return e.classList[t ? "add" : "remove"](n);
    }),
    (e.emptyFun = function() {}),
    (e.emptyGIF = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=="),
    (e.isValidLatLonObj = function(e) {
        return (e && "object" == typeof e && "lat" in e && "lon" in e && !isNaN(+e.lat) && !isNaN(+e.lon)) || !1;
    }),
    (e.normalizeLatLon = function(e) {
        return parseFloat(e).toFixed(3);
    }),
    (e.replaceClass = function(e, t, n) {
        void 0 === n && (n = document.body);
        var i = n.className;
        e.test(i) ? (n.className = i.replace(e, t)) : n.classList.add(t);
    }),
    (e.each = o),
    (e.clone = l),
    (e.deg2rad = function(e) {
        return (e * Math.PI) / 180;
    }),
    (e.degToRad = 0.017453292),
    (e.radToDeg = 57.2957795),
    (e.debounce = function(e, t, n) {
        var i;
        return function() {
            for (var a = [], s = arguments.length; s--;) a[s] = arguments[s];
            var r = this;

            function o() {
                clearTimeout(i), n || e.apply(r, a);
            }
            var l = n && !i;
            clearTimeout(i), (i = setTimeout(o, t)), l && e.apply(r, a);
        };
    }),
    (e.throttle = function(e, t) {
        var n,
            i,
            a = this;

        function s() {
            (n = !1), i && (r.apply(a, i), (i = !1));
        }

        function r() {
            for (var r = [], o = arguments.length; o--;) r[o] = arguments[o];
            n ? (i = r) : (e.apply(a, r), setTimeout(s, t), (n = !0));
        }
        return r;
    }),
    (e.pad = function(e, t) {
        void 0 === t && (t = 2);
        for (var n = String(e); n.length < t;) n = "0" + n;
        return n;
    }),
    (e.template = function(e, t) {
        return e ?
            e.replace(/\{\{?\s*(.+?)\s*\}?\}/g, function(e, n) {
                return t && n in t ? String(t[n]) : "";
            }) :
            "";
    }),
    (e.wind2obj = function(e) {
        return { wind: Math.sqrt(e[0] * e[0] + e[1] * e[1]), dir: 10 * Math.floor(18 + (18 * Math.atan2(e[0], e[1])) / Math.PI) };
    }),
    (e.wave2obj = function(e) {
        return { period: Math.sqrt(e[0] * e[0] + e[1] * e[1]), dir: 10 * Math.floor(18 + (18 * Math.atan2(e[0], e[1])) / Math.PI), size: e[2] };
    }),
    (e.hasDirection = c),
    (e.windDir2html = function(e) {
        return c(e) ? '<div class="iconfont" style="transform: rotate(' + e.dir + "deg); -webkit-transform:rotate(" + e.dir + 'deg);">"</div>' : "";
    }),
    (e.isNear = function(e, t) {
        return Math.abs(+e.lat - +t.lat) < 0.01 && Math.abs(+e.lon - +t.lon) < 0.01;
    }),
    (e.bound = d),
    (e.smoothstep = function(e, t, n) {
        var i = d((n - e) / (t - e), 0, 1);
        return i * i * i * (i * (6 * i - 15) + 10);
    }),
    (e.lonDegToXUnit = function(e) {
        return 0.5 + 0.00277777777777777 * e;
    }),
    (e.lat01ToYUnit = u),
    (e.latDegToYUnit = function(e) {
        return u(0.5 - 0.00555555555555555 * e);
    }),
    (e.unitXToLonDeg = function(e) {
        return 360 * (e - 0.5);
    }),
    (e.unitYToLatDeg = function(e) {
        return (Math.atan(Math.exp(Math.PI - e * (2 * Math.PI))) / (0.5 * Math.PI)) * 180 - 90;
    }),
    (e.unitXToLonRad = function(e) {
        return 2 * (e - 0.5) * Math.PI;
    }),
    (e.unitYToLatRad = function(e) {
        return 2 * Math.atan(Math.exp(Math.PI - 2 * e * Math.PI)) - 0.5 * Math.PI;
    }),
    (e.getAdjustedNow = function(e) {
        var t,
            n = Date.now() - h;
        return e && ((t = n - e) < 0 && (h += t), t > 1e4 && (h += t)), n;
    }),
    (e.isValidLang = function(e) {
        return n.includes(e);
    }),
    (e.joinPath = function(e, t) {
        return e + ("/" === t.charAt(0) ? "" : "/") + t;
    }),
    (e.addQs = function(e, t) {
        return e + (/\?/.test(e) ? "&" : "?") + t;
    }),
    (e.qs = function(e) {
        var t = [];
        return (
            o(e, function(e, n) {
                return void 0 !== e && t.push(n + "=" + e);
            }),
            t.join("&")
        );
    }),
    (e.loadScript = function(e, t) {
        return new Promise(function(n, i) {
            var a = document.createElement("script");
            (a.type = "text/javascript"),
            (a.async = !0),
            (a.onload = function() {
                n();
            }),
            (a.onerror = function(e) {
                i(e);
            }),
            document.head.appendChild(a),
                t && "function" == typeof t && t(a),
                (a.src = e);
        });
    }),
    (e.copy2clipboard = function(e) {
        var t = document.createElement("textarea");
        (t.value = e), document.body.appendChild(t), t.select(), document.execCommand("copy"), document.body.removeChild(t);
    }),
    (e.download = function(e, t, n) {
        var i = document.createElement("a"),
            a = e instanceof Blob ? e : new Blob([e], { type: t });
        (i.style.display = "none"),
        document.body.appendChild(i),
            window.URL && (i.href = window.URL.createObjectURL(a)),
            i.setAttribute("download", n),
            i.click(),
            window.URL && window.URL.revokeObjectURL(i.href),
            document.body.removeChild(i);
    }),
    (e.getNativePlugin = function(e) {
        return null;
    }),
    (e.$ = function(e, t) {
        return (t || document).querySelector(e);
    }),
    (e.isFunction = function(e) {
        return "function" == typeof e;
    }),
    (e.isTouchEvent = function(e) {
        return Boolean("touches" in e && e.touches);
    }),
    (e.spline = function(e, t, n, i, a) {
        return 0.5 * (2 * t + (-e + n) * a + (2 * e - 5 * t + 4 * n - i) * a * a + (3 * t - e - 3 * n + i) * a * a * a);
    }),
    (e.cubicHermite = f),
    (e.bicubicFiltering = function(e, t, n) {
        return f(f(e[0], e[1], e[2], e[3], t), f(e[4], e[5], e[6], e[7], t), f(e[8], e[9], e[10], e[11], t), f(e[12], e[13], e[14], e[15], t), n);
    }),
    (e.clamp0X = function(e, t) {
        return Math.min(Math.max(e, 0), t - 1);
    }),
    (e.lerp = p),
    (e.lerpColor256 = function(e, t, n) {
        return e.map(function(i, a) {
            return d(p(e[a], t[a], n), 0, 255);
        });
    })
    return e
}

function tilesReady(e, t, i, pixel, data) {
    var a = {
            height: data.height,
            offset: data.offset,
            offsetX: data.offsetX,
            offsetY: data.offsetY,
            shift: data.shift,
            trans: data.trans,
            width: data.width
        },
        r = function(t, s) {
            var r = (s + a.offsetY) >> a.shift,
                o = r >> 8,
                l = r - (o << 8),
                c = (s + a.offsetY) % a.trans,
                d = (t + a.offsetX) >> a.shift,
                u = d >> 8,
                h = d - (u << 8),
                f = (t + a.offsetX) % a.trans,
                p = a.trans,
                m = e && e[o] && e[o][u];
            if (!m) return console.warn("interpolator: Undefined dTile"), NaN;
            var v = m.data;
            if (!v) return console.warn("interpolator: Undefined dTile.data"), NaN;
            var g = (a.offset + h + (l << 8) + l) << 2;
            if (i.PNGtransparency && testPNGtransparency(v, g)) return NaN;
            if (i.JPGtransparency && testJPGtransparency(v, g)) return NaN;
            var w = v[g],
                y = v[g + 4],
                b = v[g + 1],
                T = v[g + 5],
                S = v[g + 2],
                E = v[g + 6],
                L = v[(g += 1028)],
                _ = v[g + 4],
                A = v[g + 1],
                C = v[g + 5],
                M = v[g + 2],
                P = v[g + 6],
                R = (p - c) * (p - f),
                x = (p - c) * f,
                O = c * (p - f),
                D = f * c,
                N = p * p,
                I = (w * R + y * x + L * O + _ * D) / N,
                k = i.interpolateNearestG ? interpolateNearest(null, 0, b, T, A, C, R, x, O, D) : (b * R + T * x + A * O + C * D) / N,
                W = (S * R + E * x + M * O + P * D) / N;
            return [m.decodeR(I), m.decodeG(k), m.decodeB(W)];
        };
    var
        i = { x: Math.round(pixel.x), y: Math.round(pixel.y) },
        o = i.x,
        l = i.y;
    return o < 0 || l < 0 || o > a.width || l > a.height ? null : r.call(a, o, l);

}


function getDataZoom(e, t) {
    var i = {
            extreme: [0, 0, 1, 2, 3, 4, 4, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
            ultra: [0, 0, 0, 2, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            high: [0, 0, 0, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
            normal: [0, 0, 0, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
            low: [0, 0, 0, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
        },
        r = Object.keys(i);
    if (e.dataTilesZoom) return e.dataTilesZoom;
    var n = e.dataQuality,
        a = e.upgradeDataQuality ? r[Math.max(r.indexOf(n) - 1, 0)] : n;
    return Math.min(e.maxTileZoom, i[a][t]);

}

function wrapCoords(e) {
    var t = 1 << e.z;
    return (e.x = e.x % t), e.x < 0 && (e.x += t), e;
};

function A(t, i, e) {
    return t instanceof E ? t : g(t) ? new E(t[0], t[1]) : null == t ? t : "object" == typeof t && "x" in t && "y" in t ? new E(t.x, t.y) : new E(t, i, e)
}

function I(t, i) {
    if (t)
        for (var e = i ? [t, i] : t, n = 0, o = e.length; n < o; n++)
            this.extend(e[n])
}

function N(t, i, e) {
    if (isNaN(t) || isNaN(i)) throw new Error("Invalid LatLng object: (" + t + ", " + i + ")");
    (this.lat = +t), (this.lng = +i), void 0 !== e && (this.alt = +e);
}

function E(t, i, e) {
    (this.x = e ? Math.round(t) : t), (this.y = e ? Math.round(i) : i);
}
(E.prototype = {
    clone: function() {
        return new E(this.x, this.y);
    },
    add: function(t) {
        return this.clone()._add(A(t));
    },
    _add: function(t) {
        return (this.x += t.x), (this.y += t.y), this;
    },
    subtract: function(t) {
        return this.clone()._subtract(A(t));
    },
    _subtract: function(t) {
        return (this.x -= t.x), (this.y -= t.y), this;
    },
    divideBy: function(t) {
        return this.clone()._divideBy(t);
    },
    _divideBy: function(t) {
        return (this.x /= t), (this.y /= t), this;
    },
    multiplyBy: function(t) {
        return this.clone()._multiplyBy(t);
    },
    _multiplyBy: function(t) {
        return (this.x *= t), (this.y *= t), this;
    },
    scaleBy: function(t) {
        return new E(this.x * t.x, this.y * t.y);
    },
    unscaleBy: function(t) {
        return new E(this.x / t.x, this.y / t.y);
    },
    round: function() {
        return this.clone()._round();
    },
    _round: function() {
        return (this.x = Math.round(this.x)), (this.y = Math.round(this.y)), this;
    },
    floor: function() {
        return this.clone()._floor();
    },
    _floor: function() {
        return (this.x = Math.floor(this.x)), (this.y = Math.floor(this.y)), this;
    },
    ceil: function() {
        return this.clone()._ceil();
    },
    _ceil: function() {
        return (this.x = Math.ceil(this.x)), (this.y = Math.ceil(this.y)), this;
    },
    trunc: function() {
        return this.clone()._trunc();
    },
    _trunc: function() {
        return (this.x = B(this.x)), (this.y = B(this.y)), this;
    },
    distanceTo: function(t) {
        var i = (t = A(t)).x - this.x,
            e = t.y - this.y;
        return Math.sqrt(i * i + e * e);
    },
    equals: function(t) {
        return (t = A(t)).x === this.x && t.y === this.y;
    },
    contains: function(t) {
        return (t = A(t)), Math.abs(t.x) <= Math.abs(this.x) && Math.abs(t.y) <= Math.abs(this.y);
    },
    toString: function() {
        return "Point(" + l(this.x) + ", " + l(this.y) + ")";
    },
}),

(N.prototype = {
    equals: function(t, i) {
        return !!t && ((t = j(t)), Math.max(Math.abs(this.lat - t.lat), Math.abs(this.lng - t.lng)) <= (void 0 === i ? 1e-9 : i));
    },
    toString: function(t) {
        return "LatLng(" + l(this.lat, t) + ", " + l(this.lng, t) + ")";
    },
    distanceTo: function(t) {
        return F.distance(this, j(t));
    },
    wrap: function() {
        return F.wrapLatLng(this);
    },
    toBounds: function(t) {
        var i = (180 * t) / 40075017,
            e = i / Math.cos((Math.PI / 180) * this.lat);
        return D([this.lat - i, this.lng - e], [this.lat + i, this.lng + e]);
    },
    clone: function() {
        return new N(this.lat, this.lng, this.alt);
    },
});

export default { UTEST, getTrans, getShift, getLShift, getOffsetX, getOffsetY, sortTiles, loadImage, whichTile, testJPGtransparency, testPNGtransparency, interpolateNearest, testN, tilesReady, getDataZoom, wrapCoords, A, I, N, E }