<template>
  <div class="button-draw-more">
    <button
      class="btn"
      :class="propIsDisabledTool ? 'button-disabled' : ''"
      @click="onOffTool"
    >
      <span class="button-icon">
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fit=""
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path
              d="M0 .808h3.958v1.32l6.085-.808V0H14v3.958h-1.32l-.808 6.085h1.32V14H9.235v-1.32H3.958V14H0v-3.958h1.32V4.765H0V.808zm10.042 3.15v-1.32l-6.085.808v1.32h-1.32v5.277h1.32v1.32h5.277v-1.32h1.32l.808-6.085h-1.32zM1.32 2.127v1.32h1.32v-1.32H1.32zm10.042-.808v1.32h1.32V1.32h-1.32zM1.32 11.362v1.32h1.32v-1.32H1.32zm9.235 0v1.32h1.32v-1.32h-1.32z"
            ></path></svg
        ></span>
      </span>
      <span class="button-text"> Draw field contours</span>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    propIsDisabledTool: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onOffTool() {
      this.$emit("onOffTool", !this.propIsDisabledTool);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-draw-more {
  button {
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 8px;
    background: rgba(17, 20, 24, 0.8);
    color: white;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    transition: all 0.2s;
    white-space: nowrap;
    border: none;
    &:hover {
      cursor: pointer;
    }
    span.button-icon {
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
    &.button-disabled {
      opacity: 1;
      background: #4b5c6c;
      color: #c1cfdb;
    }
  }
}
</style>