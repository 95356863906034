import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Monitoring from '../views/Monitoring.vue'
import MonitoringDrawField from '../views/MonitoringDrawField.vue'
import MonitoringUploadFields from '../views/MonitoringUploadFields.vue'
import MonitoringAnalyticField from '../views/MonitoringAnalyticField.vue'
import MonitoringSplitView from '../views/MonitoringSplitView.vue'
import Login from "../views/Login.vue"

import store from "../store/index.js";

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
    if (store.getters.loggedIn) {
        next()
    } else {
        try {
            let objectData = JSON.parse(localStorage.getItem("data"));
            if (objectData.logged) {
                if (objectData.time && new Date().getTime() - objectData.time < 36000000) {
                    next();
                } else
                    next('/login')
            } else next('/login')
        } catch (error) {
            next('/login')
        }
    }

}
const routes = [{
        path: '/',
        component: Main,
        beforeEnter: ifAuthenticated,
        redirect: {
            name: "Monitoring"
        },
        children: [{
                path: 'main-map',
                name: 'Monitoring',
                component: Monitoring,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'draw-field',
                name: 'DrawField',
                component: MonitoringDrawField,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'upload-fields',
                name: 'UploadFields',
                component: MonitoringUploadFields,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'analytic/field/:id',
                name: 'AnalyticField',
                component: MonitoringAnalyticField,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'split-view/:id',
                name: 'SplitViewField',
                component: MonitoringSplitView,
                beforeEnter: ifAuthenticated,
            },
        ]
    },
    {
        path: '/login',
        component: Login,
        name: "Login"
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router