<template>
  <div class="v-application--is-ltr row-season" data-app>
    <div class="row no-gutters">
      <div class="col-12 col-md-6 col-lg-2 col-xl-2">
        <div class="col-field input-field-background">
          <v-select
            v-model="entity.crop_name"
            height="32"
            :items="crops"
            label="Crop name"
            hide-details
            @change="cropNameChange"
            clearable
          ></v-select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 col-xl-2">
        <div class="col-field input-field-background">
          <v-text-field
            label="Maturity"
            hide-details="auto"
            v-model="entity.maturity"
            readonly
          ></v-text-field>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 col-xl-3">
        <div class="col-field input-field-background">
          <v-menu
            v-model="menuSowing"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="entity.sowing_date"
                label="Sowing date *"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="entity.sowing_date"
              no-title
              color="green lighten-1"
              @input="menuSowing = false"
              @change="sowingDateChange"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2 col-xl-3" data-app>
        <div class="col-field input-field-background">
          <v-menu
            v-model="menuHarvesting"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="entity.harvesting_date"
                label="Harvesting date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="entity.harvesting_date"
              no-title
              color="green lighten-1"
              @input="menuHarvesting = false"
              @change="harvestingDateChange"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2 col-xl-2">
        <div class="col-field input-field-background col-year">
          <v-select
            v-model="entity.season"
            height="32"
            :items="propYears"
            label="Season"
            hide-details
            @change="seasonChange"
          ></v-select>
        </div>
      </div>
    </div>
    <v-btn icon class="button-remove" @click="removeRowSeason">
      <span class="button-icon">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fit=""
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path
              fill=""
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4h-3.5z"
            ></path>
          </svg>
        </span>
      </span>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    propSeason: {
      type: Object,
      default: () => {},
    },
    propYears: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      entity: {
        id: null,
        crop_name: null,
        maturity: null,
        sowing_date: null,
        harvesting_date: null,
        season: null,
      },
      menuHarvesting: false,
      menuSowing: false,
      crops: [
        {
          id: "alfalfa",
          text: "Alfalfa",
          value: "Alfalfa",
        },
        {
          id: "almonds",
          text: "Almonds",
          value: "Almonds",
        },
        {
          id: "apple",
          text: "Apple",
          value: "Apple",
        },
        {
          id: "asparagus",
          text: "Asparagus",
          value: "Asparagus",
        },
        {
          id: "avocado",
          text: "Avocado",
          value: "Avocado",
        },
        {
          id: "bananas",
          text: "Bananas",
          value: "Bananas",
        },
      ],
    };
  },
  created() {
    if (this.propSeason && Object.keys(this.propSeason).length > 0) {
      this.entity.id = this.propSeason.id;
      this.entity.season = this.propSeason.season
        ? this.propSeason.season
        : null;
      this.entity.crop_name = this.propSeason.crop_name
        ? this.propSeason.crop_name
        : null;
      this.entity.maturity = this.propSeason.maturity
        ? this.propSeason.maturity
        : null;
      try {
        this.entity.sowing_date = this.propSeason.sowing_date
          ? this.propSeason.sowing_date
          : null;
      } catch (error) {}
      this.entity.harvesting_date = this.propSeason.harvesting_date
        ? this.propSeason.harvesting_date
        : null;
    }
  },
  methods: {
    removeRowSeason() {
      this.$emit("removeRowSeason", this.propSeason);
    },
    cropNameChange(e) {
      this.$emit("dataChange");
    },
    seasonChange(e) {
      this.$emit("dataChange");
    },
    sowingDateChange(e) {
      this.$emit("dataChange");
    },
    harvestingDateChange(e) {
      this.$emit("dataChange");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.row-season {
  position: relative;
  margin-top: 0.75rem !important;
  div[class^="col-xl-"],
  div[class*="col-xl-"] {
    padding: 0;
    border-right: 1px solid rgba(#8799ab, 0.2);
    &:first-child {
      padding-left: 0;
      .col-field {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    &:last-child {
      padding-right: 0;
      border-right: none;
      .col-field {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  .input-field-background {
    background: $input-field-background;
  }
  .col-field {
    padding: 0.125rem;
    padding-left: 0.5rem;
  }
  .col-year {
    width: 65%;
  }

  .button-remove {
    position: absolute;
    right: 4px;
    top: 8px;
    color: #c1cfdb !important;
    span.button-icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
    }
  }
}
</style>
<style lang="scss">
.row-season {
  .v-input--is-focused {
    .v-input__slot:before {
      border-color: #3592fd;
    }
  }
  .v-input__control {
    label {
      color: #c1cfdb;
      font-size: 14px;
    }
    .v-text-field__slot {
      input {
        color: white;
        font-size: 14px;
      }
    }
    .v-select__slot {
      .v-select__selections {
        .v-select__selection {
          color: white;
          font-size: 14px;
        }
      }
    }
  }
  .v-icon {
    color: #c1cfdb !important;
    font-size: 18px;
  }
}
</style>