<template>
  <div v-if="show">
    <v-dialog
      v-model="show"
      :content-class="'save-fields-question'"
      @click:outside="onClose"
    >
      <v-card>
        <v-card-title>
          Do you want to delete field?
          <v-btn icon class="button-close" color="#8296aa" @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-actions>
          <v-btn depressed class="button-save" @click="onDeleteField"
            >Delete AND CONTINUE</v-btn
          >
          <v-btn depressed class="button-cancel" @click="onDelete">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  methods: {
    onDeleteField() {
      this.$emit("onDeleteField");
      this.onClose();
    },
    onDelete() {
      this.$emit("removeFields");
      this.onClose();
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.save-fields-question {
  background: #212930 !important;
  border-radius: 0.5rem !important;
  color: white;
  box-shadow: 0 12px 24px rgb(0, 0, 0 / 40%);
  max-width: 20rem;
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .v-card {
    background: #212930 !important;
    box-shadow: none;
    .v-card__title {
      color: white;
      text-align: center;
      word-break: normal;
      .button-close {
        position: absolute;
        top: 0.5rem;
        right: 0;

        &:hover {
          color: white;
        }
      }
    }
    .v-card__text {
      span {
        color: #8799ab;
      }
      padding-bottom: 0.75rem !important;
    }

    .v-card__actions {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      button {
        color: white;
        border-radius: 0.5rem;
        width: 100%;
        margin: 0;
        &.button-save {
          margin: 0 !important;
          background: #3592fd;
          &:hover {
            background: #0477fa;
          }
        }
        &.button-cancel {
          margin: 0 !important;
          margin-top: 0.5rem !important;
          background: rgba(75, 92, 108, 0.4);
          &:hover {
            background: rgba(75, 92, 108, 1);
          }
        }
      }
    }
  }
}
</style>