<template>
  <div class="monitoring-page" slot="main-content">
    <MonitoringPage />
  </div>
</template>
<script>
import MonitoringPage from "@/components/pages/monitoring/all/Monitoring";
export default {
  components: {
    MonitoringPage,
  },
};
</script>