<template>
  <v-app>
    <div id="app">
      <router-view />
      <Spinner v-if="isSpinner.show" />
      <Alert />
    </div>
  </v-app>
</template>
<script>
import Spinner from "./components/commons/components/spinner/Spinner.vue";
import Alert from "@/components/commons/messages/Alert";
export default {
  components: {
    Spinner,
    Alert,
  },
  data() {
    return {};
  },
  created() {
    this.readLocalDataJson();
  },
  methods: {
    readLocalDataJson: function () {
      var vm = this;
      $.getJSON("/data/config.json", function (data) {
        if (data) {
          vm.$store.dispatch("setDataJson", data);
        }
      })
        .success(function (data) {})
        .error(function () {})
        .complete(function () {});
    },
    onResize() {
      this.$store.dispatch(
        "setDocumentWidth",
        document.documentElement.clientWidth
      );
      this.$store.dispatch(
        "setDocumentHeight",
        document.documentElement.clientHeight
      );
    },
  },
  mounted() {
    var vm = this;
    window.addEventListener("resize", vm.onResize, { passive: true });
    setTimeout(() => {
      this.onResize();
    }, 10);
  },
  computed: {
    isSpinner() {
      return this.$store.getters.getSpinnerApp;
    },
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      var vm = this;
      window.removeEventListener("resize", vm.onResize, { passive: true });
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Roboto, Helvetica Neue, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
}
</style>
