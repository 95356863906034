<template>
  <div class="popup-content">
    <h5 class="popup-content__title">Thông tin môi trường</h5>
    <div
      class="popup-content__body"
      v-if="currentData && Object.keys(currentData).length > 0"
    >
      <table class="table">
        <tbody>
          <tr>
            <td colspan="2">
              {{ currentData.name }} &nbsp; {{ toStringHDMS(propCoordinate) }}
            </td>
          </tr>
          <tr>
            <td>Nhiệt độ:</td>
            <td>{{ (currentData.main.temp - 273.15).toFixed(2) }}&nbsp;°C</td>
            <!-- <td>
              <select v-model="tempSelected">
                <option
                  v-for="item in combobox.temp"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.label }}
                </option>
              </select>
            </td> -->
          </tr>

          <tr>
            <td>Gió:</td>
            <td>{{ currentData.wind.speed }} &nbsp;m/s</td>
            <!-- <td>
              <select v-model="windSelected">
                <option
                  v-for="item in combobox.wind"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.label }}
                </option>
              </select>
            </td> -->
          </tr>
          <tr
            v-if="currentData.rain && Object.keys(currentData.rain).length > 0"
          >
            <td>Lượng mưa:</td>
            <td>{{ currentData.rain["1h"] }}&nbsp;%</td>
          </tr>
          <tr>
            <td>Độ ẩm:</td>
            <td>{{ currentData.main.humidity }}&nbsp;%</td>
          </tr>
          <tr>
            <td>Mây:</td>
            <td>{{ currentData.clouds.all }}&nbsp;%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { toStringHDMS } from "ol/coordinate";
import {
  openWeatherApiUrl,
  openWeatherApiKey,
} from "@/utils/commons/environment";
export default {
  props: {
    propData: {
      type: Object,
    },
    propCoordinate: {
      type: Object,
    },
  },
  data() {
    return {
      windSelected: 1,
      tempSelected: 1,
      combobox: {
        wind: [
          {
            id: 1,
            label: "m/s",
            conversion: 1,
            precision: 0,
          },
          {
            id: 2,
            label: "km/h",
            conversion: 3.6,
            precision: 0,
          },
          {
            id: 3,
            label: "mph",
            conversion: 2.236936,
            precision: 0,
          },
          {
            id: 4,
            label: "kt",
            conversion: 1.943844,
            precision: 0,
          },
        ],
        temp: [
          {
            id: 1,
            label: "°C",
            conversion: function (e) {
              return e - 273.15;
            },
            precision: 0,
          },
          {
            id: 2,
            label: "°F",
            conversion: function (e) {
              return (9 * e) / 5 - 459.67;
            },
            precision: 0,
          },
        ],
      },
      currentData: {},
    };
  },
  watch: {
    windSelected: {
      handler(val) {
        let index = this.combobox.wind.findIndex((x) => x.id === val);
        if (index >= 0) {
          let conversion = this.combobox.wind[index].conversion;
          this.propData.wind.valueDisplay =
            this.propData.wind.value * conversion;
          if (this.propData.wind.valueDisplay) {
            this.propData.wind.valueDisplay = Math.round(
              this.propData.wind.valueDisplay
            );
          }
        }
      },
      immediate: true,
    },
    tempSelected: {
      handler(val) {
        let index = this.combobox.temp.findIndex((x) => x.id === val);
        if (index >= 0) {
          this.propData.temp.valueDisplay = this.combobox.temp[
            index
          ].conversion(this.propData.temp.value);
          if (this.propData.temp.valueDisplay) {
            this.propData.temp.valueDisplay = Math.round(
              this.propData.temp.valueDisplay
            );
          }
        }
      },
      immediate: true,
    },
    propCoordinate: {
      handler(val) {
        this.currentData = {};
        if (val && Object.keys(val).length > 0) this.getCurrentWeatherData();
      },
      immediate: true,
    },
  },
  methods: {
    toStringHDMS(coordinate) {
      return toStringHDMS([coordinate.lng, coordinate.lat]);
    },
    async getCurrentWeatherData() {
      this.currentData = {};
      try {
        let url = openWeatherApiUrl
          .replace("_lat", this.propCoordinate.lat)
          .replace("_lng", this.propCoordinate.lng)
          .replace("_apikey", openWeatherApiKey);
        let response = await axios({
          url: url,
          method: "get",
        });
        if (response && response.status === 200) {
          this.currentData = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-content {
  .popup-content__title {
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    margin: 0;
    padding: 1rem;
    font-size: 14px;
    font-weight: 700;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .popup-content__body {
    margin: 0;
    padding: 0.75rem 1rem;
    h6 {
      font-size: 14px;
      i {
        cursor: pointer;
      }
    }
  }
  .popup-content__footer {
    border-top: 1px solid #ebebeb;
    padding: 0.75rem 1rem;
    button {
      text-align: center;
      &:hover,
      &:active {
        color: white;
      }
    }
  }
}
table {
  tr {
    td {
      font-size: 12px;
    }
  }
}
</style>