var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fields"},[_c('div',{staticClass:"field-list",class:_vm.isSmallDevice ? 'small-device' : '',style:([
      !_vm.isSmallDevice
        ? { height: _vm.documentHeight + 'px', width: 304 + 'px' }
        : {
            height: _vm.documentHeight - 50 - 16 + 'px',
            width: 272 + 'px',
            right: 8 + 'px',
          } ])},[_c('FieldList',{attrs:{"propMap":_vm.propMap,"prop-fields":_vm.propFields,"propHeight":!_vm.isSmallDevice ? _vm.documentHeight : _vm.documentHeight - 50 - 16},on:{"openDialogAddField":_vm.openDialogAddField}})],1),(_vm.isSmallDevice)?_c('div',{staticClass:"open-fields"},[_c('button',{staticClass:"btn open-close-fields"},[_vm._v(" Open fields "),_c('span',[_vm._v(" 1")]),_c('span',{staticClass:"button-icon"},[_c('span',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"100%","height":"100%","fill":"currentColor","fit":"","preserveAspectRatio":"xMidYMid meet","focusable":"false"}},[_c('path',{attrs:{"d":"M6.754 10L4 7.033 1.246 10 .4 9.087 4 5.2l3.6 3.887-.846.913zM1.246 4.8L4 1.833 6.754 4.8l.846-.913L4 0 .4 3.887l.846.913z"}})])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }