<template>
  <div class="monitoring-draw-field-page" slot="main-content">
    <MonitoringDrawFieldPage />
  </div>
</template>
<script>
import MonitoringDrawFieldPage from "@/components/pages/monitoring/draw/DrawField";
export default {
  components: {
    MonitoringDrawFieldPage,
  },
};
</script>