<template>
  <div
    id="popup-content"
    class="popup-content"
    v-if="propFeature && Object.keys(propFeature).length > 0"
  >
    <h5 class="popup-content__title">
      {{ properties ? properties.hc_ten : null }} - Mức
      {{ properties ? properties.muccanhbao : null }}
    </h5>
    <div class="popup-content__body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="text-center">Thuộc tỉnh</th>
            <th class="text-center">Mực nước</th>
            <th class="text-center">W</th>
            <th class="text-center">Q<small>đến</small></th>
            <th class="text-center">Q<small>ra</small> khỏi hồ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <span>
                {{ properties ? properties.hc_tinhten : null }}
                <br />
                <small class="text-danger">{{
                  properties ? properties.td_nguonsolieu : null
                }}</small>
              </span>
            </td>
            <td class="text-center">
              <span>
                {{ properties ? Math.ceil(properties.td_mucnuoc) : null }} (m)
                <br />
                <small>
                  {{
                    formatUnixDateTime2String(
                      properties ? properties.td_thoigian : null
                    )
                  }}</small
                >
              </span>
            </td>
            <td class="text-center">
              <span>
                {{ properties ? Math.ceil(properties.td_dungtich) : null }}
                (tr.m³)
                <br />
                <small v-if="properties && properties.td_tyledungtichsotk">
                  Tỷ lệ
                  {{
                    properties
                      ? Math.ceil(properties.td_tyledungtichsotk)
                      : null
                  }}</small
                >
              </span>
            </td>
            <td class="text-center">
              <span>
                {{ properties ? Math.ceil(properties.td_qden) : null }}
                (m³/s)
              </span>
            </td>
            <td class="text-center">
              <span>
                {{ properties ? Math.ceil(properties.td_qxa) : null }}
                (m³/s)
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    propFeature: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  watch: {
    propFeature: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatUnixDateTime2String(value) {
      console.log(value);
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result.replace(":", "h").replace(" ", " ngày ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    properties() {
      return this.propFeature ? this.propFeature.get("properties") : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-content {
  .popup-content__title {
    background-color: #d4c500;
    color: white;
    margin: 0;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 700;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .popup-content__body {
    margin: 0;
    padding: 0;
    h6 {
      font-size: 14px;
      i {
        cursor: pointer;
      }
    }
  }
}
table {
  margin: 0;
  thead {
    tr {
      background-color: #90c9f9;
      color: black;
      th {
        font-size: 12px;
        border: 1px solid white;
      }
    }
  }
  tbody {
    tr {
      background-color: #e2e2e2;
      td {
        font-size: 12px;
        border: 1px solid white;
      }
    }
  }
  &.table-bordered {
    border: 1px solid white;
  }
}

.table > :not(:first-child) {
  border-top: none !important;
}
</style>
