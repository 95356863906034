<template>
  <div class="monitoring-split-view-page" slot="main-content">
    <MonitoringSplitViewPage />
  </div>
</template>
<script>
import MonitoringSplitViewPage from "@/components/pages/monitoring/splitview/SplitView";
export default {
  components: {
    MonitoringSplitViewPage,
  },
};
</script>