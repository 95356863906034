<template>
  <div>
    <div class="edit-field-map" id="editFieldMap"></div>
    <div class="tools-map">
      <div class="tool-item">
        <button class="btn" :title="$t('tooltip_zoom_in')" @click="zoomIn">
          <span class="button-icon">
            <span>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 14"
                fill=""
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill=""></path>
              </svg>
            </span>
          </span>
        </button>
      </div>
      <div class="tool-item">
        <button class="btn" :title="$t('tooltip_zoom_out')" @click="zoomOut">
          <span class="button-icon">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 14 2"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <defs><path id="A2" d="M43 447H29v-2h14z"></path></defs>
                <use xlink:href="#A2" transform="translate(-29 -445)"></use>
              </svg>
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "ol/Map";
import View from "ol/View";
import { OSM, Vector as VectorSource, TileImage } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import { Polygon, MultiPoint } from "ol/geom.js";
import { Modify, Select } from "ol/interaction.js";
import Collection from "ol/Collection";
import { getCenter } from "ol/extent";
export default {
  props: {
    propField: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      view: Object,
      draw: Object,
      modify: Object,
      select: Object,
    };
  },
  mounted() {},
  watch: {
    propField: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          setTimeout(() => {
            this.initMap();
          }, 10);
        }
      },
      immediate: true,
    },
  },
  methods: {
    initMap() {
      const source = new VectorSource({
        wrapX: false,
      });
      this.layer = new VectorLayer({
        source: source,
        id: "drawLayer",
        style: new Style({
          fill: new Fill({
            color: this.hexToRgbA("#fab710", 0.5),
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 2,
          }),
        }),
      });
      var ext = null,
        feature;
      if (this.propField && this.propField.coordinates) {
        var vm = this;
        feature = this.layer.getSource().getFeatureById(this.propField.id);
        if (!feature) {
          let points = [];
          for (let j = 0; j < this.propField.coordinates.length; j++) {
            points.push(
              transform(this.propField.coordinates[j], "EPSG:4326", "EPSG:3857")
            );
          }
          if (points.length === 0) return;
          let feature = new Feature({
            geometry: new Polygon([points]),
            id: this.propField.id,
          });
          feature.id_ = this.propField.id;
          this.layer.getSource().addFeature(feature);
          ext = feature.getGeometry().getExtent();
        }
      }
      this.view = new View({
        center:
          this.propField && this.propField.coordinate
            ? this.propField.coordinate
            : fromLonLat([108.22779930115105, 16.062106484745996]),
        minZoom: 1,
        maxZoom: 20,
        zoom: 5,
      });
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({ doubleClickZoom: false }),
        layers: [
          new TileLayer({
            title: "Satellite",
            id: "satellitemap",
            source: new TileImage({
              url: process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
          }),
          this.layer,
        ],
        target: "editFieldMap",
        view: this.view,
      });
      if (ext) {
        this.map.getView().fit(ext, this.map.getSize());
        if (this.map.getView().getMinZoom() < this.map.getView().getZoom()) {
          this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        }
      }
      this.$emit("updateFeature", this.feature);
      this.modifyField();
    },
    zoomIn() {
      if (this.map.getView().getMaxZoom() > this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() + 1,
        });
      }
    },
    zoomOut() {
      if (this.map.getView().getMinZoom() < this.map.getView().getZoom()) {
        this.map.getView().animate({
          duration: 500,
          zoom: this.map.getView().getZoom() - 1,
        });
      }
    },
    modifyField() {
      if (!this.map || Object.keys(this.map).length === 0) return;
      if (this.propField && this.propField.coordinates) {
        var vm = this;
        let feature = this.layer.getSource().getFeatureById(this.propField.id);
        if (feature) {
          this.select = new Select({
            features: new Collection([feature]),

            style: [
              new Style({
                image: new Circle({
                  radius: 4,
                  fill: new Fill({
                    color: vm.hexToRgbA("#fff", 1),
                  }),
                  stroke: new Stroke({
                    color: vm.hexToRgbA("#FFBF23", 1),
                    width: 4,
                  }),
                }),
                geometry: function (feature) {
                  var coordinates = feature.getGeometry().getCoordinates()[0];
                  return new MultiPoint(coordinates);
                },
              }),
              new Style({
                fill: new Fill({
                  color: vm.hexToRgbA("#fab710", 0.5),
                }),
                stroke: new Stroke({
                  color: "#FFBF23",
                  width: 2,
                  lineDash: [5, 10],
                }),
              }),
            ],
          });
          this.map.addInteraction(this.select);
          vm.modify = new Modify({
            features: vm.select.getFeatures(),
          });
          vm.map.addInteraction(vm.modify);
          this.modify.on("modifyend", function (event) {
            if (event.features.array_ && event.features.array_.length > 0)
              vm.$emit("updateFeature", event.features.array_[0]);
          });
        }
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.edit-field-map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tools-map {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  padding: 0.25rem;
  z-index: 2;
  &:first-child {
    margin: 0;
  }
  .tool-item {
    display: block;
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
    border: none;
    margin-bottom: 0.025rem;
    &:last-child {
      margin-bottom: 0;
      button {
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }
    }
    &:first-child {
      button {
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
      }
    }
    button {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 1;
      padding: 0;
      color: white;
      background: #111418;
      border-radius: 0;
      border: 0;
      span.button-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor;
        width: 14px;
        height: 14px;
        &.measure-icon,
        &.layers-icon,
        &.split-icon,
        &.location-icon {
          width: 20px;
          height: 20px;
        }
      }
      &:hover {
        background: #212930;
      }
    }
  }
}
</style>