<template>
  <div class="fields">
    <div
      class="field-list"
      :class="isSmallDevice ? 'small-device' : ''"
      :style="[
        !isSmallDevice
          ? { height: documentHeight + 'px', width: 304 + 'px' }
          : {
              height: documentHeight - 50 - 16 + 'px',
              width: 272 + 'px',
              right: 8 + 'px',
            },
      ]"
    >
      <FieldList
        :propMap="propMap"
        :prop-fields="propFields"
        :propHeight="!isSmallDevice ? documentHeight : documentHeight - 50 - 16"
        @openDialogAddField="openDialogAddField"
      />
    </div>
    <div class="open-fields" v-if="isSmallDevice">
      <button class="btn open-close-fields">
        Open fields <span>&nbsp;1</span>
        <span class="button-icon">
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="currentColor"
              fit=""
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <path
                d="M6.754 10L4 7.033 1.246 10 .4 9.087 4 5.2l3.6 3.887-.846.913zM1.246 4.8L4 1.833 6.754 4.8l.846-.913L4 0 .4 3.887l.846.913z"
              ></path></svg
          ></span>
        </span>
      </button>
    </div>
  </div>
</template>
<script>
import FieldList from "./rightside/FieldList.vue";
export default {
  components: { FieldList },
  props: {
    propMap: {
      type: Object,
    },
    propFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSmallDevice: false,
    };
  },
  watch: {
    documentWidth: {
      handler(val) {
        if (val) {
          if (val < 576) this.isSmallDevice = true;
          else this.isSmallDevice = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    openDialogAddField() {
      this.$emit("openDialogAddField");
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.fields {
  background: #212930;
  box-shadow: inset 7px 0 9px -7px rgba(1, 2, 3, 0.4);
}
.field-list {
  background: #212930;
  &.small-device {
    position: absolute;
    bottom: 54px;
    right: 2px;
    border-radius: 8px;
  }
}
.open-fields {
  position: absolute;
  bottom: 0;
  right: 8px;
  width: 272px;
  button {
    position: relative;
    width: 100%;
    padding: 12px 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white;
    border-radius: 8px 8px 0 0;
    text-align: center;
    background: #181f26;
    cursor: pointer;
    transition: background 0.1s;
    span.button-icon {
      position: absolute;
      top: 19px;
      right: 20px;
      width: 8px;
      height: 10px;
      font-size: 0;
      line-height: 0;
      transform-origin: 50% 50%;
      color: white;
    }
  }
}
</style>