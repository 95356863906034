<template>
  <div class="d-flex align-items-center">
    <div class="picker-control">
      <v-menu
        v-model="menuShowDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="transparent"
            small
            v-bind="attrs"
            v-on="on"
            class="button-date"
          >
            <v-icon icon> mdi-calendar-range </v-icon>
          </v-btn>
        </template>
        <v-date-picker
          :allowedDates="allowedDates"
          v-model="selectedDate"
          @input="menuShowDate = false"
          no-title
          @change="dateChange"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="wrap-scene-list menu-wrapper" :class="'wrap-' + className">
      <v-btn
        v-if="propImageDays.length > 0"
        color="transparent"
        class="button-prev"
        :class="'btn-prev-' + className"
      >
        <v-icon icon> mdi-chevron-left </v-icon>
      </v-btn>

      <div
        class="scene-list scene-scroll"
        :id="id"
        :class="'scroll-' + className"
      >
        <div
          class="scene-item"
          v-for="(day, index) in days"
          :key="index"
          :class="day.id === selectedId ? 'active' : ''"
        >
          <div class="scene-item-content">
            <div class="scene-item__date d-flex" @click="selectedId = day.id">
              <span class="scene-item__icon">
                <span class="satellite-icon svg-icon">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <svg
                        v-bind="attrs"
                        v-on="on"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fit=""
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                      >
                        <path
                          d="M18.563 13.055l-3.77-3.77.985-.985a.65.65 0 0 0 .19-.456.64.64 0 0 0-.19-.456L12.61 4.22c-.252-.252-.66-.252-.912 0l-.985.985-3.77-3.77c-.252-.252-.66-.252-.912 0l-3.164 3.17c-.252.252-.252.66 0 .912l3.77 3.77-.985.985a.65.65 0 0 0-.19.456.64.64 0 0 0 .19.456L8.82 14.35a.64.64 0 0 0 .912 0l.985-.985 3.77 3.77a.64.64 0 0 0 .912 0l3.167-3.167a.65.65 0 0 0 .19-.456.64.64 0 0 0-.19-.456zM4.236 5.062L6.5 2.807 9.803 6.12 7.548 8.374 4.236 5.062zm5.04 7.92L7.02 10.727l5.137-5.136 2.255 2.255-5.136 5.136zm5.665 2.784l-3.312-3.312 2.255-2.255 3.313 3.313-2.255 2.255zM7.47 17.46c-1.317 0-2.555-.513-3.486-1.444A4.9 4.9 0 0 1 2.54 12.53c0-.356-.3-.645-.645-.645s-.645.3-.645.645a6.18 6.18 0 0 0 1.822 4.398A6.18 6.18 0 0 0 7.47 18.75c.356 0 .645-.3.645-.645s-.3-.645-.645-.645zm-.258-1.24c.356 0 .645-.3.645-.645s-.3-.645-.645-.645a2.13 2.13 0 0 1-1.515-.628 2.13 2.13 0 0 1-.628-1.515c0-.356-.3-.645-.645-.645s-.645.3-.645.645a3.41 3.41 0 0 0 1.005 2.427 3.41 3.41 0 0 0 2.427 1.005z"
                        ></path>
                      </svg>
                    </template>
                    <span>{{ day.id }}</span>
                  </v-tooltip>
                </span>
              </span>
              <div class="scene-item__text">{{ day.label }}</div>
            </div>
            <div class="scene-item__cloud">
              <span class="scene-item__icon">
                <span class="cloud-icon svg-icon"
                  ><span class="scene-item__icon">
                    <span class="cloud-icon svg-icon">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <svg
                            v-bind="attrs"
                            v-on="on"
                            width="100%"
                            height="100%"
                            viewBox="0 0 22 14"
                            xmlns="http://www.w3.org/2000/svg"
                            fit=""
                            preserveAspectRatio="xMidYMid meet"
                            focusable="false"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.5962 4.72969C16.7658 1.99256 14.1287 0 11 0C8.5651 0 6.42446 1.21226 5.20443 3.03892C5.51492 3.15503 5.80388 3.3121 6.06346 3.49801C6.90015 4.09726 7.5 5.0481 7.5 6H6.5C6.5 5.4519 6.12961 4.77542 5.48118 4.31101C4.90879 3.90105 4.17439 3.69788 3.4092 3.89548C1.40769 4.67946 0 6.55969 0 8.75C0 11.6462 2.46583 14 5.5 14H17.4167C19.9467 14 22 12.04 22 9.625C22 8.14291 21.2264 6.84092 20.0425 6.05642C20 6.03918 19.952 6.0205 19.8987 6.00103C19.6511 5.91052 19.2951 5.80437 18.8681 5.74451C18.0166 5.62514 16.8961 5.6904 15.7721 6.41948C14.6005 7.1794 14.0386 7.98224 13.7659 8.56886C13.6285 8.86446 13.5621 9.11062 13.5299 9.27636C13.5138 9.35927 13.5063 9.42204 13.5029 9.46072C13.5011 9.48005 13.5004 9.49333 13.5001 9.50006L13.5 9.50508C13.5 9.50493 13.5 9.50453 13.5 9.50388L13.5 9.50227L13.5 9.50122L13.5 9.50064C13.5 9.50032 13.5 9.50001 13 9.5C12.5 9.49999 12.5 9.49966 12.5 9.49932L12.5 9.49861L12.5 9.49704L12.5 9.49338L12.5002 9.48397C12.5002 9.48086 12.5003 9.47741 12.5004 9.47362C12.5006 9.46865 12.5008 9.46309 12.5011 9.45696C12.502 9.43538 12.5037 9.40672 12.5069 9.37152C12.5132 9.30114 12.5252 9.2045 12.5482 9.08597C12.5942 8.84884 12.684 8.52405 12.8591 8.14735C13.2114 7.38938 13.8995 6.44222 15.2279 5.58052C16.0408 5.05322 16.855 4.81048 17.5962 4.72969Z"
                            ></path>
                          </svg>
                        </template>
                        <span>{{ day.cloud_cover.toFixed(2) }}%</span>
                      </v-tooltip>
                    </span>
                  </span></span
                >
              </span>
            </div>
          </div>
        </div>
      </div>

      <v-btn
        v-if="propImageDays.length > 0"
        color="transparent"
        class="button-next"
        :class="'btn-next-' + className"
      >
        <v-icon icon> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import moment from "moment";
/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
export default {
  props: {
    propImageDays: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuShowDate: false,
      selectedDate: null,
      selectedId: null,
      id:
        "scroll" +
        (new Date().getTime() -
          Math.floor(Math.random() * 999999999) +
          Math.floor(Math.random() * 888888888)),
      className: null,
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      days: [],
    };
  },
  created() {
    this.className = this.generateClass();
  },
  mounted() {
    var vm = this;
    // duration of scroll animation
    var scrollDuration = 300;
    // paddles
    var leftPaddle = document.getElementsByClassName(
      "btn-prev-" + vm.className
    );
    var rightPaddle = document.getElementsByClassName(
      "btn-next-" + vm.className
    );
    // get items dimensions
    var itemsLength = this.days.length; //$(".scene-item").length;
    var itemSize = 140; //$(".scene-item").outerWidth(true);
    // get some relevant size for the paddle triggering point
    var paddleMargin = 0;

    // get wrapper width
    var getMenuWrapperSize = function () {
      return $(".wrap-" + vm.className).outerWidth(true);
    };
    var menuWrapperSize = getMenuWrapperSize() - 50;
    // the wrapper is responsive
    $(window).on("resize", function () {
      menuWrapperSize = getMenuWrapperSize() - 50;
    });
    // size of the visible part of the menu is equal as the wrapper size
    var menuVisibleSize = menuWrapperSize;
    // get total width of all menu items
    var getMenuSize = function () {
      return itemsLength * itemSize;
    };
    var menuSize = getMenuSize();
    // get how much of menu is invisible
    var menuInvisibleSize = menuSize - menuWrapperSize;

    // $(".scroll-" + vm.className).scrollLeft(getMenuSize());
    var getMenuPosition = function () {
      return $(".scroll-" + vm.className).scrollLeft();
    };
    setTimeout(() => {
      $(".scroll-" + vm.className).scrollLeft(menuInvisibleSize); // 2*48 = chieu rong 2 buton
      if (menuInvisibleSize > 0) {
        $(leftPaddle).removeClass("v-btn--disabled");
        $(rightPaddle).addClass("v-btn--disabled");
      } else {
        $(leftPaddle).addClass("v-btn--disabled");
        $(rightPaddle).addClass("v-btn--disabled");
      }
    }, 100);

    // finally, what happens when we are actually scrolling the menu
    $(".scroll-" + vm.className).on("scroll", function () {
      menuInvisibleSize = menuSize - menuWrapperSize; // 96 = 2 button with = 48px
      // get how much have we scrolled so far
      var menuPosition = getMenuPosition();
      var menuEndOffset = menuInvisibleSize - paddleMargin - 2 * 48;
      // show & hide the paddles
      // depending on scroll position
      if (menuPosition <= paddleMargin) {
        $(leftPaddle).addClass("v-btn--disabled");
        $(rightPaddle).removeClass("v-btn--disabled");
      } else if (menuPosition < menuEndOffset) {
        // show both paddles in the middle
        $(leftPaddle).removeClass("v-btn--disabled");
        $(rightPaddle).removeClass("v-btn--disabled");
      } else if (menuPosition >= menuEndOffset) {
        $(leftPaddle).removeClass("v-btn--disabled");
        $(rightPaddle).addClass("v-btn--disabled");
      }
    });

    // scroll to left
    $(rightPaddle).on("click", function () {
      $("#" + vm.id).animate(
        { scrollLeft: getMenuPosition() + 150 },
        scrollDuration
      );
    });

    // scroll to right
    $(leftPaddle).on("click", function () {
      $("#" + vm.id).animate(
        { scrollLeft: getMenuPosition() - 150 },
        scrollDuration
      );
    });
  },

  watch: {
    propImageDays: {
      handler(val) {
        if (this.propImageDays && this.propImageDays.length > 0) {
          this.days = Object.assign([], this.propImageDays);
          let max = Math.max.apply(
            Math,
            this.days.map(function (o) {
              return o.datetime;
            })
          );
          this.selectedDate = moment
            .unix(new Date(max / 1000))
            .format("YYYY-MM-DD");
          for (let i = 0; i < this.days.length; i++) {
            let date = new Date(this.days[i].datetime);
            this.days[i].label =
              this.monthNames[date.getMonth()] +
              " " +
              date.toLocaleDateString("en-US").split("/")[1] +
              ", " +
              date.getFullYear();
            if (this.days[i].datetime === max)
              this.selectedId = this.days[i].id;
          }
        } else {
          this.days = [];
        }
      },
      immediate: true,
    },
    selectedId: {
      handler(val) {
        if (val) {
          this.$emit("selectIdChange", val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    generateClass() {
      var letters =
        "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFIJKLMNOPQRSTUVWXYZ";
      var strClass = "";
      for (var i = 0; i < 10; i++) {
        strClass += letters[Math.floor(Math.random() * 60)];
      }
      return strClass;
    },
    allowedDates(val) {
      return this.days
        .map((x) =>
          moment.unix(new Date(x.datetime / 1000)).format("YYYY-MM-DD")
        )
        .includes(val);
    },

    dateChange(e) {
      if (e) {
        try {
          let index = this.propImageDays.findIndex(
            (x) =>
              moment.unix(new Date(x.datetime / 1000)).format("YYYY-MM-DD") ===
              e
          );
          if (index >= 0) {
            this.selectedId = this.propImageDays[index].id;
            var vm = this;
            var getMenuWrapperSize = function () {
              return $(".wrap-" + vm.className).outerWidth(true);
            };
            if (
              getMenuWrapperSize() - 50 - 2 * 48 <
              this.propImageDays.length * 140
            ) {
              if (index * 130 < getMenuWrapperSize() - 50 - 2 * 48) {
                $("#" + vm.id).animate({ scrollLeft: 0 }, 300);
              } else {
                $("#" + vm.id).animate(
                  {
                    scrollLeft:
                      index * 130 +
                      60 -
                      (getMenuWrapperSize() - 50 - 2 * 48) / 2,
                  },
                  300
                );
              }
            }
          }
        } catch (error) {}
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.picker-control {
  button.button-date {
    height: 50px;
    .v-icon {
      color: #8296aa !important;
    }
  }
}
.wrap-scene-list {
  display: flex;
  position: relative;
  // max-width: calc(100%);
  overflow-x: hidden;
  overflow-y: hidden;
  button {
    &.button-prev {
      height: 50px;
      width: 48px;
    }
    &.button-next {
      height: 50px;
      width: 48px;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 48px;
    }
    .v-icon {
      color: #8296aa !important;
    }
    &.v-btn--disabled {
      .v-icon {
        color: #8296aa !important;
      }
    }
  }

  .scene-list {
    width: 100%;
    display: block;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    overflow: hidden; /* Hide scrollbars */
    -webkit-overflow-scrolling: touch;
    .scene-item {
      display: inline-block;
      width: 130px;
      vertical-align: middle;
      height: 100%;
      box-sizing: border-box;

      .scene-item-content {
        display: flex;
        width: 100%;
        align-items: flex-start;
        height: 100%;
        .scene-item__icon {
          display: flex;
          flex-direction: column;
          color: white;
          .satellite-icon {
            margin-right: 6px;
          }
          .cloud-icon {
            margin: 0;
          }
          .svg-icon {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            svg {
              outline: 0;
            }
          }
        }
        .scene-item__text {
          margin-left: 0.4px;
          word-wrap: break-word;
          display: inline-block;
          vertical-align: middle;
          line-height: 18px;
          overflow: hidden;
          height: 100%;
          width: 60px;
          white-space: normal;
          text-align: center;
          color: white;
        }

        .scene-item__date {
          padding: 6px;
          height: 100%;
          &:hover {
            background: #323d49;
            cursor: pointer;
            .scene-item__text,
            .scene-item__icon {
              color: #8296aa;
            }
          }
        }
        .scene-item__cloud {
          padding: 6px;
          vertical-align: middle;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          &:hover {
            cursor: pointer;
          }
        }
      }
      &.active {
        color: $text-link;
        .scene-item__date {
          background: rgba(53, 146, 253, 0.2);
          color: $text-link;
          .svg-icon {
            color: $text-link;
          }
          .scene-item__text {
            color: $text-link;
          }
        }
      }
    }
  }
}
</style>