<template>
  <div class="d-flex" v-if="show">
    <v-dialog
      v-model="show"
      persistent
      no-click-animation
      :content-class="'edit-field-dialog'"
      @click:outside="outsideClick"
    >
      <div class="d-flex w-100">
        <div class="left">
          <EditFieldMap
            :prop-field="propField"
            @updateFeature="updateFeature"
          />
        </div>
        <div class="right">
          <div class="right-header">
            <div class="d-flex justify-content-between align-items-center">
              <v-btn
                text
                color="error"
                class="button-delete"
                @click="deleteField"
                >Delete</v-btn
              >
              <span class="title">Edit Field</span>
              <v-btn icon class="button-close" color="#8296aa" @click="onClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="right-content">
            <div class="v-application--is-ltr row no-gutters">
              <div class="col-12 col-md-6">
                <div class="col-field input-field-background border-radius-8">
                  <v-text-field
                    label="Field name"
                    hide-details="auto"
                    v-model="name"
                    maxlength="50"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="col-group input-field-background border-radius-8">
                  <v-text-field
                    label="Group name"
                    hide-details="auto"
                    v-model="group_name"
                    maxlength="50"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="row no-gutters add-season text-center">
              <button class="btn" @click="addNewRowSeason">
                <span class="button-icon">
                  <span
                    ><svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 14 14"
                      fill=""
                      xmlns="http://www.w3.org/2000/svg"
                      fit=""
                      preserveAspectRatio="xMidYMid meet"
                      focusable="false"
                    >
                      <path
                        d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z"
                        fill=""
                      ></path></svg
                  ></span>
                </span>
                <span class="button-text">&nbsp;Add Season</span>
              </button>
            </div>
            <row-season
              v-for="season in rowsSeason"
              :key="season.id"
              :prop-season="season"
              :prop-years="years"
              @removeRowSeason="removeRowSeason"
              @dataChange="onDataChange"
              ref="seasonEdit"
            ></row-season>
          </div>
          <div class="dialog-actions text-right">
            <v-btn depressed class="button-cancel" @click="cancelEdit"
              >Cancel</v-btn
            >
            <v-btn depressed class="button-save" @click="saveChangeField"
              >Save</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import RowSeason from "./editfielddialog/RowSeason.vue";
import EditFieldMap from "./editfielddialog/EditFieldMap.vue";
export default {
  components: {
    RowSeason,
    EditFieldMap,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propField: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      rowsSeason: [],
      name: null,
      group_name: null,
      years: [],
      feature: null,
      dataChanged: false,
    };
  },
  created() {
    this.$store.dispatch("setSpinnerApp", { show: true });
    this.show = this.propShow;
    let nowYear = new Date().getFullYear();
    this.name = this.propField.name ? this.propField.name : null;
    this.group_name = this.propField.group_name
      ? this.propField.group_name
      : null;
    if (this.propField.rowsSeason && this.propField.rowsSeason.length > 0) {
      this.rowsSeason = Object.assign([], this.propField.rowsSeason);
    } else {
      for (let i = nowYear; i > nowYear - 5; --i) {
        this.rowsSeason.push({
          id:
            new Date().getTime() -
            Math.floor(Math.random() * 999999999) +
            Math.floor(Math.random() * 888888888),
          crop_name: null,
          maturity: null,
          sowing_date: null,
          harvesting_date: null,
          season: i,
        });
      }
    }
    for (let i = nowYear + 5; i > nowYear - 50; --i) {
      this.years.push({
        id: i,
        value: i,
        label: i,
        text: i,
      });
    }
    this.$store.dispatch("setSpinnerApp", { show: false });
  },
  mounted() {},
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
    name: {
      handler(val, oldVal) {
        if (!this.dataChanged && val && oldVal === this.propField.name) {
          this.dataChanged = true;
        }
      },
      immediate: true,
    },
    group_name: {
      handler(val, oldVal) {
        if (!this.dataChanged && val && oldVal === this.propField.group_name) {
          this.dataChanged = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    deleteField() {
      this.$emit("removeField", this.propField.id);
      this.onClose();
    },
    updateFeature(f) {
      this.feature = f;
      this.dataChanged = true;
    },
    saveChangeField() {
      if (this.$refs.seasonEdit) {
        for (let i = 0; i < this.$refs.seasonEdit.length; i++) {
          if (this.$refs.seasonEdit[i].entity) {
            let index = this.rowsSeason.findIndex(
              (x) => x.id === this.$refs.seasonEdit[i].entity.id
            );
            if (index >= 0) {
              for (const [key, value] of Object.entries(
                this.$refs.seasonEdit[i].entity
              )) {
                this.rowsSeason[index][key] = value;
              }
            }
          }
        }
      }
      this.propField.name = this.name
        ? this.name.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        : null;
      this.propField.group_name = this.group_name
        ? this.group_name.trim().replace(/\s\s+/g, " ").replace(/  +/g, " ")
        : null;
      this.$emit("saveChangeField", {
        rowsSeason: this.rowsSeason,
        feature: this.feature,
        field: this.propField,
      });
    },
    cancelEdit() {
      if (this.dataChanged) {
        this.$emit("showSaveChangeQuestionDialog");
      } else this.onClose();
    },
    outsideClick() {
      if (this.dataChanged) this.$emit("showSaveChangeQuestionDialog");
      else this.onClose();
    },
    onDataChange() {
      this.dataChanged = true;
    },
    removeRowSeason(item) {
      let index = this.rowsSeason.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.rowsSeason.splice(index, 1);
        this.dataChanged = true;
      }
    },
    addNewRowSeason() {
      this.rowsSeason.unshift({
        id:
          new Date().getTime() -
          Math.floor(Math.random() * 999999999) +
          Math.floor(Math.random() * 888888888),
        crop_name: null,
        maturity: null,
        sowing_date: null,
        harvesting_date: null,
        season: new Date().getFullYear(),
      });
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.edit-field-dialog {
  background: #212930 !important;
  border-radius: 0.5rem;
  color: white;
  width: 1140px !important;
  .left {
    width: 30%;
    position: relative;
  }
  .right {
    width: 70%;
    padding: 0;
    .right-header {
      padding: 0.5rem;
      .title {
        color: white;
        font-size: 16px;
        font-weight: 400;
      }
      .button-delete {
        color: $error-primary;
      }
      .button-close {
        color: #8296aa;
      }
    }
    .right-content {
      padding: 0.5rem;
      max-height: 400px;
      overflow-y: auto;
      .row {
        &.no-gutters {
          padding: 0;
          margin: 0;
          .col-md-6 {
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            &:first-child {
              padding-right: 0.25rem;
            }
            &:last-child {
              padding-left: 0.25rem;
            }
            .input-field-background {
              background: $input-field-background;
            }
            .col-field {
              padding: 0.125rem;
              padding-left: 0.5rem;
            }
            .col-group {
              padding: 0.125rem;
              padding-left: 0.5rem;
            }
          }
          .v-input__control {
            label {
              color: #c1cfdb;
              font-size: 14px;
            }
            .v-text-field__slot {
              input {
                color: white;
                font-size: 14px;
              }
            }
            .v-select__slot {
              .v-select__selections {
                .v-select__selection {
                  color: white;
                  font-size: 14px;
                }
              }
            }
          }
          .v-icon {
            color: #c1cfdb !important;
            font-size: 18px;
          }
        }
        &.add-season {
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
          button {
            margin: 0 auto;
            font-size: 14px;
            width: 8.5rem;
            color: $accent-color;
            border: none;
            &:hover {
              color: $accent-color-hover;
            }
            span.button-icon {
              width: 14px;
              height: 14px;
              background-repeat: no-repeat;
              display: inline-block;
              fill: currentColor;
            }
          }
        }
      }
    }
  }
  .dialog-actions {
    padding: 1rem 0.5rem;
    button {
      color: white;
      text-align: center;
      font-weight: 500;
      width: 15rem;
      border-radius: 0.5rem;

      &.button-save {
        background: #3592fd;
        &:hover {
          background: #0477fa;
        }
      }
      &.button-cancel {
        margin-right: 0.5rem;
        background: rgba(75, 92, 108, 0.4);
        &:hover {
          background: rgba(75, 92, 108, 1);
        }
      }
    }
  }
}
</style>