<template>
  <div class="map-view">
    <div id="openlayer__popup" class="openlayer__popup">
      <a id="openlayer__popup-closer" class="openlayer__popup-closer"></a>
      <OverlayFirePointInfo :prop-feature="firePointFeature" />
    </div>
    <!-- Popup -->
    <div id="openlayer__env-popup" class="openlayer__popup">
      <a id="openlayer__env-popup-closer" class="openlayer__popup-closer"></a>
      <OverlayEnvironmentInfo
        :prop-coordinate="overlay.environment.coordinate"
        :propData="overlay.environment"
      />
    </div>
    <div id="openlayer__hochua-popup" class="openlayer__popup hochua-popup">
      <a
        id="openlayer__hochua-popup-closer"
        class="openlayer__popup-closer"
      ></a>
      <HoChuaOverlay :prop-feature="hochua.selected" />
    </div>
    <div
      class="monitoring-map"
      id="openlayerMap"
      :style="[
        documentWidth >= 576
          ? { width: 'calc(100% - 304px)' }
          : { width: 'calc(100% - 56px)' },
      ]"
    ></div>
    <WidgetLayers
      v-show="propButtonsTool.layers.show"
      :propShow="propButtonsTool.layers.show2"
      :map="map"
      @close="closeWidgetLayers"
      ref="widgetLayers"
    />
  </div>
</template>
<script>
let sketch;

/**
 * The help tooltip element.
 * @type {HTMLElement}
 */
let helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {Overlay}
 */
let helpTooltip;

/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
let measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
let measureTooltip;

/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
const continuePolygonMsg = "Click to continue drawing the polygon";

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
const continueLineMsg = "Click to continue drawing the line";
const formatLength = function (line) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = function (polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};
/**
 * This is a workaround.
 * Returns the associated layer.
 * @param {ol.Map} map.
 * @return {ol.layer.Vector} Layer.
 */
Feature.prototype.getLayer = function (map) {
  var this_ = this,
    layer_,
    layersToLookFor = [];
  /**
   * Populates array layersToLookFor with only
   * layers that have features
   */
  var check = function (layer) {
    var source = layer.getSource();
    if (source instanceof VectorSource) {
      var features = source.getFeatures();
      if (features.length > 0) {
        layersToLookFor.push({
          layer: layer,
          features: features,
        });
      }
    }
  };
  //loop through map layers
  map.getLayers().forEach(function (layer) {
    if (layer instanceof LayerGroup) {
      layer.getLayers().forEach(check);
    } else {
      check(layer);
    }
  });
  layersToLookFor.forEach(function (obj) {
    var found = obj.features.some(function (feature) {
      return this_ === feature;
    });
    if (found) {
      //this is the layer we want
      layer_ = obj.layer;
    }
  });
  return layer_;
};

import HoChuaOverlay from "./openlayermap/HoChuaOverlay";
import OverlayEnvironmentInfo from "./openlayermap/OverlayEnvironmentInfo";
import OverlayFirePointInfo from "./openlayermap/OverlayFirePointInfo";
import WidgetLayers from "./openlayermap/WidgetLayers";
import Map from "ol/Map";
import View from "ol/View";
import Draw from "ol/interaction/Draw";
import XYZ from "ol/source/XYZ";
import { Vector as VectorSource, TileImage } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";

import { unByKey } from "ol/Observable.js";
import Overlay from "ol/Overlay.js";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon, MultiPoint } from "ol/geom.js";
import LayerGroup from "ol/layer/Group";
import Feature from "ol/Feature";
import { Modify, Select } from "ol/interaction.js";
import { GeoJSON } from "ol/format.js";
import Collection from "ol/Collection";
import {
  Style,
  Fill,
  Stroke,
  Circle as CircleStyle,
  Icon,
  Text,
} from "ol/style.js";
import { Point } from "ol/geom";

import { doubleClick } from "ol/events/condition";
import ContextMenu from "ol-contextmenu";
// import windyPlugin from "@/plugins/windy";
import windyPlugin from "@/plugins/windy2";
export default {
  components: {
    WidgetLayers,
    OverlayFirePointInfo,
    OverlayEnvironmentInfo,
    HoChuaOverlay,
  },
  props: {
    propMap: {
      type: Object,
    },
    propView: {
      type: Object,
    },
    propFields: {
      type: Array,
      default: () => [],
    },
    propMeasureTool: {
      type: Object,
      default: () => {},
    },
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      layerMeasure: Object,
      view: Object,
      draw: Object,
      modify: Object,
      select: Object,
      id: null,
      fillColor: "#fab710",
      fill: new Fill({
        color: "rgba(255,255,255,0.4)",
      }),
      strokeColor: "#3399CC",
      stroke: new Stroke({
        color: "#3399CC",
        width: 1.25,
      }),
      firePointFeature: null,
      environment: {
        data: null,
        utils: null,
        wind: {
          layersData: [],
          locationObject: null,
          layerObject: null,
          tiles: [],
        },
        temp: {
          layersData: [],
          locationObject: null,
          layerObject: null,
          tiles: [],
        },
        rain: {
          layersData: [],
          locationObject: null,
          layerObject: null,
          tiles: [],
        },
        soilMoisture: {
          layersData: [],
          locationObject: null,
          layerObject: null,
          tiles: [],
        },
      },
      overlay: {
        environment: {
          wind: {
            value: null,
            dir: null,
            valueDisplay: null,
          },
          temp: {
            value: null,
            dir: null,
            valueDisplay: null,
          },
          rain: {
            value: null,
            dir: null,
            valueDisplay: null,
          },
          soilMoisture: {
            value: null,
            dir: null,
            valueDisplay: null,
          },
          coordinate: null,
        },
      },

      layerHoChuaLevel: Object,
      hochua: {
        selected: null,
        level1: {
          items: [],
          interval: null,
          style: null,
          counter: 0,
        },
        level2: {
          items: [],
          interval: null,
          style: null,
          counter: 0,
        },
        level3: {
          items: [],
          interval: null,
          style: null,
          counter: 0,
        },
      },
    };
  },
  created() {},
  mounted() {
    if (!this.propMap || Object.keys(this.propMap).length === 0) {
      const source = new VectorSource({
        wrapX: false,
      });
      var vm = this;
      this.layer = new VectorLayer({
        source: source,
        id: "drawLayer",
      });
      this.layer.setZIndex(100);

      const sourceMeasure = new VectorSource({
        wrapX: false,
      });
      this.layerMeasure = new VectorLayer({
        source: sourceMeasure,
        id: "measureLayer",
        style: new Style({
          fill: new Fill({
            color: "rgba(255,255,255,0.4)",
          }),
          strokeColor: "#3399CC",
          stroke: new Stroke({
            color: "#3399CC",
            width: 2,
          }),
        }),
      });
      this.layerMeasure.setZIndex(102);
      const sourceTemperature = new VectorSource({
        wrapX: false,
      });
      let temperatureLayer = new VectorLayer({
        source: sourceTemperature,
        id: "temperatureLayer",
      });
      temperatureLayer.setZIndex(101);
      this.view = new View({
        center: fromLonLat([108.22779930115105, 16.062106484745996]),
        zoom: 6,
        minZoom: 1,
        maxZoom: 20,
      });
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        interactions: defaults({ doubleClickZoom: false }),
        layers: [this.layer, temperatureLayer, this.layerMeasure],
        target: "openlayerMap",
        view: this.view,
      });
      this.map.on("moveend", (event) => {
        this.$emit("mapMoveend", event.map);
        this.initData();
      });
      this.map.on("pointermove", pointerMove);
      function pointerMove(evt) {
        if (evt.dragging) {
          return;
        }
        var pixel = vm.map.getEventPixel(evt.originalEvent);
        var hit = false;
        try {
          hit = vm.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
            if (layer.get("id") === "firesLayer") return true;
            else if (layer.get("id") === "layerHoChua") {
              vm.hochua.selected = feature;
              var closer = document.getElementById(
                "openlayer__hochua-popup-closer"
              );
              closer.onclick = function () {
                vm.hochua.selected = null;
                select.getFeatures().clear();
                hoaChuapopup.setPosition(undefined);
                closer.blur();
                return false;
              };
              hoaChuapopup.setPosition(feature.getGeometry().flatCoordinates);
              return true;
            } else return false;
          });
        } catch (error) {}
        vm.map.getTargetElement().style.cursor = hit ? "pointer" : "";

        if (!vm.propMeasureTool.active) return;
        /** @type {string} */
        let helpMsg = "Click to start drawing";

        if (sketch) {
          const geom = sketch.getGeometry();
          if (geom instanceof Polygon) {
            helpMsg = continuePolygonMsg;
          } else if (geom instanceof LineString) {
            helpMsg = continueLineMsg;
          }
        }

        helpTooltipElement.innerHTML = helpMsg;
        helpTooltip.setPosition(evt.coordinate);

        helpTooltipElement.classList.remove("hidden");
      }

      // init popup
      var overlay = new Overlay({
        element: document.getElementById("openlayer__popup"),
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.map.addOverlay(overlay);

      // Popup showing the position the user clicked
      var envpopup = new Overlay({
        element: document.getElementById("openlayer__env-popup"),
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.map.addOverlay(envpopup);

      var hoaChuapopup = new Overlay({
        element: document.getElementById("openlayer__hochua-popup"),
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.map.addOverlay(hoaChuapopup);

      var select = new Select({
        multi: false,
        condition: doubleClick,
      });
      vm.map.addInteraction(select);

      select.getFeatures().on(["add"], function (e) {
        if (
          e.element &&
          e.element.getLayer(vm.map).values_.id === "firesLayer"
        ) {
          vm.firePointFeature = e.element;
          e.element.setStyle(
            new Style({
              image: new Icon({
                size: [45, 60],
                offset: [0, 0],
                opacity: 1,
                scale: 0.375,
                src: "/img/icons/fire_24.png",
              }),
            })
          );
          var closer = document.getElementById("openlayer__popup-closer");
          closer.onclick = function () {
            vm.isWeather = false;
            select.getFeatures().clear();
            overlay.setPosition(undefined);
            closer.blur();
            return false;
          };
          overlay.setPosition(e.element.getGeometry().flatCoordinates);
        } else if (e.element && e.element.id_.startsWith("hc_")) {
          vm.hochua.selected = e.element;
          var closer = document.getElementById(
            "openlayer__hochua-popup-closer"
          );
          closer.onclick = function () {
            vm.hochua.selected = null;
            select.getFeatures().clear();
            hoaChuapopup.setPosition(undefined);
            closer.blur();
            return false;
          };
          overlay.setPosition(e.element.getGeometry().flatCoordinates);
        } else select.getFeatures().clear();
      });
      select.getFeatures().on(["remove"], function (e) {
        this.firePointFeature = null;
        vm.hochua.selected = null;
        overlay.setPosition(null);
      });

      this.$emit("initMapSuccess", {
        map: this.map,
        temperatureLayer: temperatureLayer,
      });
      if (this.layer.getSource().getFeatures().length === 0)
        this.drawFields(this.propFields);
    }

    var contextmenuItems = [
      {
        text: "Thông số môi trường",
        classname: "bold",
        callback: getEnviromentInfo,
        icon: "https://cdn.jsdelivr.net/gh/jonataswalker/ol-contextmenu@604befc46d737d814505b5d90fc171932f747043/examples/img/pin_drop.png",
      },
    ];
    var contextmenu = new ContextMenu({
      width: 180,
      items: contextmenuItems,
    });
    this.map.addControl(contextmenu);
    contextmenu.on("open", function (evt) {
      var feature = vm.map.forEachFeatureAtPixel(evt.pixel, (ft) => ft);

      if (feature && feature.get("type") === "removable") {
        contextmenu.clear();
      } else {
        contextmenu.clear();
        contextmenu.extend(contextmenuItems);
        contextmenu.extend(contextmenu.getDefaultItems());
      }
    });

    function getEnviromentInfo(obj) {
      let coordinate = transform(obj.coordinate, "EPSG:3857", "EPSG:4326");
      vm.overlay.environment.coordinate = {};
      vm.overlay.environment.coordinate.lat = coordinate[1];
      vm.overlay.environment.coordinate.lng = coordinate[0];
      envpopup.setPosition(obj.coordinate);
      var closer = document.getElementById("openlayer__env-popup-closer");
      closer.onclick = function () {
        vm.overlay.environment.coordinate = null;
        envpopup.setPosition(undefined);
        closer.blur();
        return false;
      };
      vm.getDataEnvironment({
        lat: coordinate[1],
        lng: coordinate[0],
      });
    }
    // Demo thuy dien buonkop
    this.getLayersManage();
  },

  watch: {
    propFields: {
      handler(val) {
        if (!this.layer || Object.keys(this.layer).length === 0) return;
        if (this.layer.getSource().getFeatures().length === 0)
          this.drawFields(this.propFields);
      },
      immediate: true,
    },
    "propMeasureTool.active": {
      handler(val) {
        //if (val) this.addInteraction();
      },
      immediate: true,
    },
    "propMeasureTool.type": {
      handler(val) {
        if (val && this.propMeasureTool.active) {
          if (this.draw && Object.keys(this.draw).length > 0)
            this.map.removeInteraction(this.draw);
          this.addInteraction();
        }
      },
      immediate: true,
    },
  },
  methods: {
    randomString(length) {
      var chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split(
          ""
        );

      if (!length) {
        length = Math.floor(Math.random() * chars.length);
      }

      var str = "";
      for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
      }
      return str;
    },
    addLayer2Widget(layer) {
      if (this.$refs.widgetLayers)
        this.$refs.widgetLayers.addLayer2Widget(layer);
    },
    closeWidgetLayers() {
      this.propButtonsTool.layers.show2 = false;
      setTimeout(() => {
        this.propButtonsTool.layers.show = false;
      }, 1000);
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    /**
     * Creates a new help tooltip
     */
    createHelpTooltip() {
      if (helpTooltipElement) {
        helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      }
      helpTooltipElement = document.createElement("div");
      helpTooltipElement.className = "ol-tooltip hidden";
      helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: "center-left",
      });
      this.map.addOverlay(helpTooltip);
    },

    /**
     * Creates a new measure tooltip
     */
    createMeasureTooltip() {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement("div");
      measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
      });
      this.map.addOverlay(measureTooltip);
    },
    addInteraction() {
      this.createMeasureTooltip();
      this.createHelpTooltip();
      if (this.layerMeasure && Object.keys(this.layerMeasure).length > 0) {
        this.layerMeasure.getSource().clear();
      }
      var vm = this;
      this.draw = new Draw({
        source: vm.layerMeasure.getSource(),
        type: vm.propMeasureTool.type,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.8)",
            }),
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.4)",
            }),
          }),
        }),
      });
      this.map.addInteraction(this.draw);

      let listener;
      this.draw.on("drawstart", function (evt) {
        // set sketch
        sketch = evt.feature;

        /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
        let tooltipCoord = evt.coordinate;

        listener = sketch.getGeometry().on("change", function (evt) {
          const geom = evt.target;
          let output;
          if (geom instanceof Polygon) {
            output = formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            output = formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }
          vm.propMeasureTool.value = output;
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
        });
      });

      this.draw.on("drawend", function (evt) {
        // unset sketch
        sketch = null;
        // unset tooltip so that a new one can be created
        measureTooltipElement = null;
        if (helpTooltip) {
          vm.map.removeOverlay(helpTooltip);
        }
        if (measureTooltip) {
          vm.map.removeOverlay(measureTooltip);
        }
        vm.map.removeInteraction(vm.draw);
        vm.draw = null;
        unByKey(listener);
        vm.$emit("measureEnd", evt.feature);
      });
    },
    clearMeasureTool() {
      if (this.layerMeasure && Object.keys(this.layerMeasure).length > 0) {
        this.layerMeasure.getSource().clear();
      }
      if (this.draw && Object.keys(this.draw).length > 0) {
        this.map.removeInteraction(this.draw);
        this.draw = null;
      }
    },
    drawFields(items) {
      if (!this.map || Object.keys(this.map).length === 0) return;
      for (let i = 0; i < items.length; i++) {
        if (items[i] && items[i].coordinates) {
          let feature = this.layer.getSource().getFeatureById(items[i].id);
          if (!feature) {
            let points = [];
            for (let j = 0; j < items[i].coordinates.length; j++) {
              points.push(
                transform(items[i].coordinates[j], "EPSG:4326", "EPSG:3857")
              );
            }
            if (points.length === 0) continue;
            let color = this.getRandomColor();
            let feature = new Feature({
              geometry: new Polygon([points]),
              id: items[i].id,
            });
            feature.setId(items[i].id + "");
            feature.setStyle(
              new Style({
                fill: new Fill({
                  color: this.hexToRgbA(
                    items[i].color ? items[i].color : color,
                    0.2
                  ),
                }),
                stroke: new Stroke({
                  color: this.hexToRgbA(
                    items[i].color ? items[i].color : color,
                    1
                  ),
                  width: 1.25,
                }),
              })
            );
            this.layer.getSource().addFeature(feature);
          }
        }
      }
      let fieldIdForcus = localStorage.getItem("field");

      if (fieldIdForcus) {
        let f = this.layer.getSource().getFeatureById(fieldIdForcus);
        if (f && Object.keys(f).length > 0) {
          let extent = f.getGeometry().getExtent();
          this.map.getView().fit(extent);
        }
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    getDataEnvironment(coordinate) {
      var vm = this;
      let center = transform(
        this.map.getView().getCenter(),
        "EPSG:3857",
        "EPSG:4326"
      );
      let zoom = this.map.getView().getZoom();

      var sizedivideBy2 = new windyPlugin.E(
        this.mapWidth / 2,
        this.mapHeight / 2
      );
      let project = windyPlugin.UTEST.project(
        new windyPlugin.N(center[1], center[0]),
        zoom
      );

      let e = windyPlugin.UTEST._transform(project, 256 * Math.pow(2, zoom))
        ._subtract(sizedivideBy2)
        ._add(new windyPlugin.E(0, 0))
        ._round();

      let e2 = windyPlugin.UTEST._transform(
        windyPlugin.UTEST.project(
          new windyPlugin.N(coordinate.lat, coordinate.lng),
          zoom
        ),
        256 * Math.pow(2, zoom)
      );
      let value = windyPlugin.tilesReady(
        [vm.environment.wind.layersData],
        vm.environment.wind.layerObject,
        vm.environment.wind.locationObject,
        {
          x: Math.abs(e2.x - e.x),
          y: Math.abs(e2.y - e.y),
        },
        vm.environment.data
      );
      let valueObj = vm.environment.utils.wind2obj(value);
      let valueTemp = windyPlugin.tilesReady(
        [vm.environment.temp.layersData],
        vm.environment.temp.layerObject,
        vm.environment.temp.locationObject,
        {
          x: Math.abs(e2.x - e.x),
          y: Math.abs(e2.y - e.y),
        },
        vm.environment.data
      );
      let valueTempObj = vm.environment.utils.wind2obj(valueTemp);
      setTimeout(() => {
        if (valueTempObj) {
          vm.overlay.environment.wind.value = valueObj.wind;
          vm.overlay.environment.wind.dir = valueObj.dir;
          vm.overlay.environment.wind.valueDisplay = valueObj.wind
            ? Math.round(valueObj.wind)
            : valueObj.wind;
        }
        if (valueTempObj) {
          vm.overlay.environment.temp.value = valueTempObj.wind;
          vm.overlay.environment.temp.dir = valueTempObj.dir;
          vm.overlay.environment.temp.valueDisplay = valueTempObj.wind
            ? Math.round(valueTempObj.wind - 273.15)
            : valueTempObj.wind;
        }
      }, 10);
    },
    initData() {
      var vm = this;
      let center = transform(
        this.map.getView().getCenter(),
        "EPSG:3857",
        "EPSG:4326"
      );
      let zoom = this.map.getView().getZoom();
      let t = windyPlugin.testN(
        {},
        {
          supportedLanguages: "vi",
        }
      );
      var sizedivideBy2 = new windyPlugin.E(
        this.mapWidth / 2,
        this.mapHeight / 2
      );
      let project = windyPlugin.UTEST.project(
        new windyPlugin.N(center[1], center[0]),
        zoom
      );

      let e = windyPlugin.UTEST._transform(project, 256 * Math.pow(2, zoom))
        ._subtract(sizedivideBy2)
        ._add(new windyPlugin.E(0, 0))
        ._round();

      let pixelBounds = {
        min: {
          x: e.x,
          y: e.y,
        },
        max: {
          x: e.x + this.mapWidth,
          y: e.y + this.mapHeight,
        },
      };
      for (
        var s = pixelBounds,
          l = [],
          c = s.min.x >> 8,
          d = s.max.x >> 8,
          u = s.min.y >> 8,
          h = s.max.y >> 8,
          f = u;
        f <= h;
        f++
      ) {
        for (var p = c; p <= d; p++) {
          var m = windyPlugin.wrapCoords({ x: p, y: f, z: zoom });
          l.push(m);
        }
      }
      let today = new Date(),
        // dateMinus1 = new Date(
        //   new Date().toISOString().replace(/T.*$/, "T00:34:56Z")
        // ),
        datepath = new Date().toISOString().replace(/T.*$/, "T00:34:56Z");
      datepath = datepath.split("-").join("").replace("T", "");
      today.setHours(today.getHours() - 7);
      //dateMinus1.setHours(dateMinus1.getHours() - 7);
      //dateMinus1 = new Date(dateMinus1.setDate(dateMinus1.getDate() - 1));
      let todayHour = today.getHours(),
        todayMonth = today.getMonth() + 1,
        todayDate = today.getDate();
      if (todayDate < 10) todayDate = "0" + todayDate;
      if (todayMonth < 10) todayMonth = "0" + todayMonth;
      let path;
      if (todayHour < 2) {
        path = "" + today.getFullYear() + todayMonth + todayDate + "00";
      } else if (todayHour >= 2 && todayHour < 5) {
        path = "" + today.getFullYear() + todayMonth + todayDate + "03";
      } else if (todayHour >= 5 && todayHour < 8) {
        path = today.getFullYear() + todayMonth + todayDate + "06";
      } else if (todayHour >= 8 && todayHour < 11) {
        path = "" + today.getFullYear() + todayMonth + todayDate + "09";
      } else if (todayHour >= 11 && todayHour < 14) {
        path = "" + today.getFullYear() + todayMonth + todayDate + "12";
      } else if (todayHour >= 14 && todayHour < 17) {
        path = "" + today.getFullYear() + todayMonth + todayDate + "15";
      } else if (todayHour >= 17 && todayHour < 20) {
        path = "" + today.getFullYear() + todayMonth + todayDate + "18";
      } else {
        path = "" + today.getFullYear() + todayMonth + todayDate + "21";
      }
      vm.environment.wind.locationObject = {
        JPGtransparency: false,
        PNGtransparency: false,
        acTime: "next3d",
        dataQuality: "normal",
        directory: "forecast/ecmwf-hres",
        fileSuffix: "jpg",
        filename: "wind",
        fullPath:
          "https://ims.windy.com/im/v3.0/forecast/ecmwf-hres/" +
          datepath.substring(0, 10) +
          "/" +
          path +
          "/wm_grid_257/<z>/<x>/<y>/wind-surface.jpg",
        hasMoreLevels: true,
        isolines: "off",
        layer: "windParticles",
        level: "surface",
        maxTileZoom: 3,
        overlay: "wind",
        particlesIdent: "wind",
        path: path,
        product: "ecmwf",
        refTime: datepath.substring(0, 10),
        server: "https://ims.windy.com",
        transformB: undefined,
        transformG: undefined,
        transformR: undefined,
        upgradeDataQuality: false,
      };
      vm.environment.temp.locationObject = {
        JPGtransparency: false,
        PNGtransparency: false,
        acTime: "next3d",
        dataQuality: "normal",
        directory: "forecast/ecmwf-hres",
        fileSuffix: "jpg",
        filename: "temp",
        fullPath:
          "https://ims.windy.com/im/v3.0/forecast/ecmwf-hres/" +
          datepath.substring(0, 10) +
          "/" +
          path +
          "/wm_grid_257/<z>/<x>/<y>/temp-surface.jpg",
        hasMoreLevels: true,
        isolines: "off",
        layer: "temp",
        level: "surface",
        maxTileZoom: 3,
        overlay: "temp",
        path: path,
        product: "ecmwf",
        refTime: datepath.substring(0, 10),
        renderFrom: "R",
        server: "https://ims.windy.com",
        transformB: undefined,
        transformG: undefined,
        transformR: undefined,
        upgradeDataQuality: false,
      };

      vm.environment.soilMoisture.locationObject = {
        JPGtransparency: false,
        PNGtransparency: false,
        acTime: "next3d",
        dataQuality: "normal",
        directory: "forecast/ecmwf-hres",
        fileSuffix: "png",
        filename: "temp",
        fullPath:
          "https://ims.windy.com/im/v3.0/forecast/ecmwf-hres/" +
          datepath.substring(0, 10) +
          "/" +
          path +
          "/wm_grid_257/<z>/<x>/<y>/awr_0_40-surface.png",
        hasMoreLevels: true,
        isolines: "off",
        layer: "temp",
        level: "surface",
        maxTileZoom: 3,
        overlay: "temp",
        path: path,
        product: "ecmwf",
        refTime: datepath.substring(0, 10),
        renderFrom: "R",
        server: "https://ims.windy.com",
        transformB: undefined,
        transformG: undefined,
        transformR: undefined,
        upgradeDataQuality: false,
      };

      var v = {
        source: "maps",
        lat: center[1],
        lon: center[0],
        zoom: zoom,
      };
      this.environment.temp.layerObject =
        this.environment.wind.layerObject =
        this.environment.rain.layerObject =
        this.environment.soilMoisture.layerObject =
          t.clone(v);
      var w = windyPlugin.getDataZoom(vm.environment.wind.locationObject, zoom),
        y = new windyPlugin.E(this.mapWidth, this.mapHeight),
        b = y.x,
        T = y.y,
        S = Object.assign(this.environment.wind.layerObject, {
          pixelOriginX: s.min.x,
          pixelOriginY: s.min.y,
          dZoom: w,
          width: b,
          height: T,
          origTiles: { minX: c, minY: u, maxX: d, maxY: h },
        }),
        E1 = {},
        E2 = {},
        E3 = {},
        E4 = {},
        L = [];

      l.forEach(function (t) {
        let fullPath = windyPlugin.whichTile(
          t,
          vm.environment.wind.locationObject
        );
        let promise = windyPlugin.loadImage(fullPath, t);
        promise.then((r) => {
          if (r && !E1[r.url]) {
            E1[r.url] = 1;
            r.promise = promise;
            vm.environment.wind.layersData.push(r);
          }
        });

        let fullPathTemp = windyPlugin.whichTile(
          t,
          vm.environment.temp.locationObject
        );
        let promiseTemp = windyPlugin.loadImage(fullPathTemp, t);
        promiseTemp.then((r) => {
          if (r && !E2[r.url]) {
            E2[r.url] = 1;
            r.promise = promiseTemp;
            vm.environment.temp.layersData.push(r);
          }
        });
      });
      let trans = windyPlugin.getTrans(zoom, w);
      let shift = windyPlugin.getShift(trans);
      let lshift = windyPlugin.getLShift(trans);
      let r = (pixelBounds.min.x / trans) % 256;
      let o = (pixelBounds.min.y / trans) % 256;

      let offsetX = windyPlugin.getOffsetX(r, trans);
      let offsetY = windyPlugin.getOffsetY(o, trans);
      vm.environment.data = {
        height: this.documentHeight,
        offset: 2056,
        offsetX: offsetX,
        offsetY: offsetY,
        lshift: lshift,
        shift: shift,
        trans: trans,
        width: this.documentWidth,
      };
      vm.environment.utils = t;
      setTimeout(() => {
        vm.environment.wind.tiles = windyPlugin.sortTiles(
          vm.environment.wind.layerObject,
          vm.environment.wind.locationObject,
          vm.environment.wind.layersData
        );
        vm.environment.temp.tiles = windyPlugin.sortTiles(
          vm.environment.temp.layerObject,
          vm.environment.temp.locationObject,
          vm.environment.temp.layersData
        );
      }, 100);
    },
    async getLayersManage() {
      var vm = this;
      $.getJSON("/data/HoChua.txt", async function (data) {
        if (data && data.data) {
          let obj = JSON.parse(data.data[0].row_to_json);
          if (obj && obj.features) {
            let arrayHoMuc1 = obj.features.filter(
              (x) => x.properties.muccanhbao === 1
            );

            vm.hochua.level1.items = arrayHoMuc1 ? arrayHoMuc1 : [];
            vm.hochua.level1.style = new Style({
              image: new Icon({
                src: "/img/hcxanh.png",
                size: [16, 24],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                anchorOrigin: "top-left",
                scale: 8 / 16,
                anchor: [8, 12],
              }),
            });

            if (arrayHoMuc1 && arrayHoMuc1.length > 0) {
              vm.createLayerHoChua(arrayHoMuc1, vm.hochua.level1.style, 1);
            }

            let arrayHoMuc2 = obj.features.filter(
              (x) => x.properties.muccanhbao === 2
            );

            vm.hochua.level2.items = arrayHoMuc2 ? arrayHoMuc2 : [];
            vm.hochua.level2.style = new Style({
              image: new Icon({
                src: "/img/hcvang.png",
                size: [16, 24],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                anchorOrigin: "top-left",
                scale: 10 / 16,
                anchor: [8, 12],
              }),
            });
            if (arrayHoMuc2 && arrayHoMuc2.length > 0) {
              await vm.createLayerHoChua(
                arrayHoMuc2,
                vm.hochua.level2.style,
                2
              );
            }

            let arrayHoMuc3 = obj.features.filter(
              (x) => x.properties.muccanhbao === 3
            );
            vm.hochua.level3.items = arrayHoMuc3 ? arrayHoMuc3 : [];
            vm.hochua.level3.style = new Style({
              image: new Icon({
                src: "/img/hcdo.png",
                size: [16, 24],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                anchorOrigin: "top-left",
                scale: 10 / 16,
                anchor: [8, 12],
              }),
            });
            if (arrayHoMuc3 && arrayHoMuc3.length > 0) {
              await vm.createLayerHoChua(
                arrayHoMuc3,
                vm.hochua.level3.style,
                3
              );
            }
          }
        }
      });

      vm.hochua.level2.interval = setInterval(vm.flashLayerHoChua, 500);
    },

    flashLayerHoChua() {
      if (
        this.layerHoChuaLevel &&
        Object.keys(this.layerHoChuaLevel).length > 0
      ) {
        let features = this.layerHoChuaLevel.getSource().getFeatures();
        if (features && features.length > 0) {
          for (let i = 0; i < features.length; i++) {
            let properties = features[i].get("properties");
            if (!properties.muccanhbao || properties.muccanhbao === 1) continue;
            if (this.hochua.level2.counter) {
              features[i].setStyle(
                properties.muccanhbao === 2
                  ? this.hochua.level2.style
                  : this.hochua.level3.style
              );
            } else {
              try {
                features[i].setStyle([
                  new Style({
                    image: new Icon({
                      src: "/img/hcnocolor.png",
                      size: [16, 24],
                      anchorXUnits: "pixels",
                      anchorYUnits: "pixels",
                      anchorOrigin: "top-left",
                      scale: 10 / 16,
                    }),
                  }),
                ]);
              } catch (error) {}
            }
          }
        }
        this.hochua.level2.counter = this.hochua.level2.counter ? 0 : 1;
      }
    },
    createLayerHoChua(arrayItem, style) {
      var vm = this;
      var source;
      if (Object.keys(vm.layerHoChuaLevel).length === 0) {
        source = new VectorSource({
          wrapX: false,
          id: "sourceHoChua",
        });
        vm.layerHoChuaLevel = new VectorLayer({
          source: source,
        });
        vm.layerHoChuaLevel.set("id", "layerHoChua");
        //vm.layers.push(vm.layerHoChuaLevel);
        vm.layerHoChuaLevel.setZIndex(112);
        vm.map.addLayer(vm.layerHoChuaLevel);
      } else {
        source = vm.layerHoChuaLevel.getSource();
      }
      for (let i = 0; i < arrayItem.length; i++) {
        let feature = new Feature({
          type: "icon",
          geometry: new Point(arrayItem[i].geometry.coordinates),
        });
        feature.id_ = "hc_" + arrayItem[i].properties.hc_id;
        feature.setStyle([style]);
        feature.set("properties", arrayItem[i].properties);
        source.addFeature(feature);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mapWidth() {
      return this.documentWidth;
      let elementMap = document.getElementById("openlayerMap");
      if (elementMap && Object.keys(elementMap).length > 0) {
        return elementMap.offsetWidth;
      } else {
        return this.documentWidth - 360;
      }
    },
    mapHeight() {
      return this.documentHeight;
      let elementMap = document.getElementById("openlayerMap");
      if (elementMap && Object.keys(elementMap).length > 0) {
        return elementMap.offsetHeight;
      } else {
        return this.documentHeight;
      }
    },
  },
};
</script>
<style lang="scss">
.map-view {
  position: relative;
  height: 100vh;
}
.monitoring-map {
  position: absolute;
  height: 100%;
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
    cursor: default;
    user-select: none;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: #ffcc33;
  }
}
.openlayer__popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  border-radius: 0.75rem;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 300px;
  &.hochua-popup {
    min-width: 32rem;
  }
}
.openlayer__popup:after,
.openlayer__popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.openlayer__popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.openlayer__popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.openlayer__popup-closer {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  &:hover {
    text-decoration: none !important;
  }
}
.openlayer__popup-closer:after {
  content: "✖";
}
#openlayer__hochua-popup-closer {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
#openlayer__hochua-popup-closer:after {
  content: "✖";
  color: white;
}
</style>
