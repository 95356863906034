<template>
  <div class="spinner">
    <v-progress-circular
      :size="50"
      color="#005ce0"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
  will-change: opacity;
}
</style>