const SET_MAP_POSITION = "SET_MAP_POSITION"
export default {
    state: {
        position:{
            center: null,
            zoom: null
        }
    },    
    getters: {
        getMapPosition:state=>{
            return state.position
        }
    },    
    mutations: {        
        [SET_MAP_POSITION](state,payload) {
            state.position = payload;
        }
    },    
    actions: {
        setMapPosition({ commit }, payload) {
            commit(SET_MAP_POSITION, payload);
        },
    },
}